import React, { Component } from 'react';
import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/pagination/pagination';
import { request } from '../../../constants/constants';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loader from '../../../components/loader/loader';
import history from '../../../history';

class FulllShipments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipments: [],
            pageSize: 10,
            currentPage: 1,
            statusOptions: [
                {
                    label: 'Pending Payment',
                    value: 'pending_payment',
                },
                {
                    label: 'Processing',
                    value: 'processing',
                }, 
                {
                    label: 'On Hold',
                    value: 'on_hold',
                },
                {
                    label: 'Completed',
                    value: 'completed',
                }, 
                {
                    label: 'Shipped',
                    value: 'shipped',
                },
                {
                    label: 'Cancelled',
                    value: 'cancelled',
                },
            ]
        }
    }

    handleTrProps(row) {
        return {
            onClick: (e) => {
                console.log(e);
                e.preventDefault();
                e.stopPropagation();
                history.push(`/shipments/${row.original.id}/detail`);
            },
        }
    }
    

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    getShipments() {
        return request('GET', '/hq/shipments', {}, true);
    }

    viewOrder = id => e => {
        e.stopPropagation();
        history.push(`/orders/${id}/detail`);
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const shipments = this.state.shipments;
                const product = shipments[index];
                product.isLoading = true;
                this.setState({
                    shipments: shipments
                })
                request('DELETE', `/hq/shipments/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    product.isLoading = false;
                    this.setState({
                        shipments: shipments
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    product.isLoading = false;
                    this.setState({
                        shipments: shipments
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
   
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getShipments().then(res => {
            console.log(res);
            this.setState({
                shipments: res.data.data,
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        const data = this.state.shipments.sort((a, b) => b.order.order_number - a.order.order_number);;
		const columns = [
            {
                Header: 'Order',
                Cell: row => {
                    return (
                    <div className="match">
                        <button onClick={this.viewOrder(row.original.order.id)} className="btn btn-link text-dark">Order #{row.original.order.order_number}</button>
                        <div className="actions">
                            <ul className="list-inline">
                                {/* <li>
                                    <Link to={`/shipments/${row.original.id}/detail`}><button className="btn-link btn font-primary p-0 pr-2">Edit</button></Link>
                                </li>
                                <li><span className="seprator">|</span></li> */}
                                <li>
                                    <Link to={`/shipments/${row.original.id}/detail`}><button className="btn-link btn font-primary p-0 pl-2">View</button></Link>
                                </li>
                                {/* <li><span className="seprator">|</span></li> */}
                                {/* <li>
                                    <button onClick={() => this.handleDelete(row.original.id)} className="link">Delete</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    )
                }
            },
            {
                Header: 'Type',
                Cell: row => (
                <span>{row.original.shipment_type === 'full' ? 'Full' : 'Partial'} </span>
                ),
            },
            {
                Header: 'Tracking',
                Cell: row => {
                    return <Link to="/">{row.original.tracking_number}</Link>
                }
            },
            {
                Header: 'Service',
                Cell: row => (
                <span>{row.original.ship_provider} {row.original.ship_provider_type}</span>
                ),
            },

            {
                Header: 'Recepient',
                Cell: row => (
                <span>{row.original.order.first_name} {row.original.order.last_name}</span>
                ),
            },
            {
                Header: 'Ship Date',
                Cell: row => (
                    <span>{moment(row.original.ship_date).format('MMMM DD, YYYY')}</span>
                ),
            },
         
            // {
            //     Header: 'Action',
            //     Cell: row => (
            //         <React.Fragment>
            //         <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
            //         {
            //             row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
            //         }
            //         </button>
            //         </React.Fragment>
            //     ),
            // },
        ];
        return (
           <div className="page-body">
                {
                    this.state.isLoading && <Loader />
                }
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Shipments
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                        <li className="breadcrumb-item active">Shipments</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    {/* <div className="card-header">
                        <h5>Manage Shipments</h5>
                    </div> */}
                    <div className="card-body order-datatable">
                        
                        {/* <div className="btn-popup pull-right">
                          <Link to="/shipments/new">  <button type="button" className="btn btn-secondary">Add Product</button> </Link>
                        </div> */}

                       <div className="table-responsive">
                       <ReactTable
                            PaginationComponent={Pagination}
                            data={data}
                            minRows={5}
                            columns={columns}
                            noDataText={this.state.isLoading ? '' : "No Orders Found."}
                            defaultPageSize={this.state.pageSize}
                            pageSizeOptions={[10, 25, 50, 100]}
                            // resizable={true}
                            getTrProps={(state, rowInfo, column, instance) => this.handleTrProps(rowInfo)}
                            onPageChange={(index) => this.handlePageChange(index)}
                            onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                            getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                        />
                       </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}

export default FulllShipments;