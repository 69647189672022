import React, { Component } from 'react';
import { request } from '../../constants/constants';
import Loader from '../../components/loader/loader';
import Pagination from '../../components/pagination/pagination';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';


class GeneratePO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            outstandingItems: [],
            pages: [1],
            pageSize: 10,
            currentPage: 1,
            selectedOrders: [],
			currentSelectedOrders: [],
            stores: [],
            selected: {},
			selectedAll: false,
            indeterminateSign: false,
            filter: {
                decoration_supplier: 'all',
                item_supplier: 'all',
                store: 'all'
            },
            customItemsModal: false
        }
    }

    oncustomItemsModalCloseModal = () => {
        this.setState({
            customItemsModal: false
        })
    }

    onBundleItemsModalCloseModal = () => {
        this.setState({
            bundleItemsModal: false
        })
    }
    
    toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
	
		if (this.state.selectedAll === false || this.state.selectedAll === null || this.state.indeterminateSign === true) {
			// newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.outstandingItems.forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			this.setState({
				selected: newObj,
				selectedAll: true,
				indeterminateSign: false,
				currentSelectedOrders: [...this.state.outstandingItems]
			});
		} else {
			this.state.outstandingItems.forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);

			this.setState({
				selected: newObj,
				selectedAll: false,
				currentSelectedOrders: []
			});
		}
	}
	toggleRow(order) {
		const orderId = order.id;
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[orderId] = !this.state.selected[orderId];

		const index = this.state.currentSelectedOrders.findIndex(element => element.id == order.id);
		if(index < 0) {
			this.state.currentSelectedOrders.push(order);
		} else {
			this.state.currentSelectedOrders.splice(index, 1);
		}

		this.setState({
			currentSelectedOrders: this.state.currentSelectedOrders,
			selected: newSelected
		});
	}

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

    handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.outstandingItems.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.outstandingItems.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					// this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
        }
    }

    generatePurchaseOrders = e => {
        this.setState({
            isLoading: true
        })

        const outstandingItems = [];        

        for (let key in this.state.selected) {
            if (this.state.selected[key]) {
                outstandingItems.push(key);
            }
        }
        
        if(outstandingItems.length <= 0) {
            this.setState({
                isLoading: false
            })
            
            return Swal.fire(
                'Error',
                'Please select item[s]',
                'error'
            );
        }

        const data = {
            items: outstandingItems
        }

        request('POST', '/hq/purchase-orders', data, true).then(res => {
            console.log(res);
            this.setState({
                isLoading: false,
                selected: {},
                currentSelectedOrders: []
            })            
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            );
            this.init();
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            );
        })
    }

    onFilterChange = e => {
        this.setState({
            filter: {
                ...this.state.filter,
                [e.target.name]: e.target.value
            }
        }, () => {
            const items = this.state.originalOutstandingItmes.filter(item => {
                if( item.item_supplier_id !== this.state.filter.item_supplier && this.state.filter.item_supplier !== 'all') {
                    return false;
                }
                if( item.decoration_supplier_id !== this.state.filter.decoration_supplier && this.state.filter.decoration_supplier !== 'all') {
                    return false;
                }
                if( item.store_id !== this.state.filter.store && this.state.filter.store !== 'all') {
                    return false;
                }
                return true;
            })
            this.setState({
                outstandingItems: items
            })
        })
       
    }

    

    getAllItemSuppliers() {
        return request('GET', `/hq/item-suppliers`, {}, true);
    }

    getAllDecorationSuppliers() {
        return request('GET', `/hq/decoration-suppliers`, {}, true);
    }

    getAllOutstandings(lastEvaluatedKey) {
        return request('GET', `/hq/orders/outstandings${lastEvaluatedKey ? `?lastEvaluatedKey=${lastEvaluatedKey}` : ''}`, {}, true);
    }

    getAllStores() {
        return request('GET', `/hq/stores`, {}, true);
    }

    loadMore = e => {
        if(this.state.lastEvaluatedKey) {
            this.setState({
                loadMoreIsLoading: true
            })

            this.getAllOutstandings(this.state.lastEvaluatedKey.id.S).then(res => {
                console.log(res);
                const newOutstandings = [...this.state.outstandingItems, ...res.data.data];
                this.setState({
                    outstandingItems: newOutstandings,
                    originalOutstandingItmes: [...newOutstandings],
                    lastEvaluatedKey: res.data.lastEvaluatedKey,
                    loadMoreIsLoading: false

                })
            }).catch(err => {
                this.setState({
                    loadMoreIsLoading: false
                })
                console.error(err);
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })
        }
    }

    handleNextClick = e => {
        const currentPage = this.state.currentPage + 1;

       if(this.state.lastEvaluatedKey && this.state.pages.indexOf(currentPage) <= -1) {
            this.setState({
                isLoading: true
            })
            const pages = this.state.pages;
            console.log('currentPage: ', currentPage);
            if(this.state.pages.indexOf(currentPage) <= -1) {
                pages.push(currentPage);
            }
            this.setState({
                currentPage,
                pages,
            })
            this.getAllOutstandings(this.state.lastEvaluatedKey.id.S).then(res => {
                console.log(res);
                const newOutstandings = [...this.state.outstandingItems, ...res.data.data];
                const endOffset = parseInt(currentPage + '0');
                const newItemOffset = endOffset - 10
                this.setState({
                    currentPage,
                    pages,
                    lastEvaluatedKey: res.data.lastEvaluatedKey,
                    isLoading: false,
                    outstandingItems: newOutstandings,
                    endOffset,
                    itemOffset: newItemOffset
                })
            }).catch(err => {
                console.error(err);
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })
           
       } else {
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                endOffset,
                itemOffset: newItemOffset
            })
       }
    }

    handlePreviousClick = e => {
        if(this.state.currentPage !==1 ) {
            const currentPage = this.state.currentPage - 1;
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                currentPage,
                itemOffset: newItemOffset,
                endOffset: endOffset
            })
        }
    }

    handlePageClick = page => e => {
        const endOffset = parseInt(page + '0');
        const newItemOffset = endOffset - 10

        this.setState({
            currentPage: page,
            itemOffset: newItemOffset,
            endOffset
        })
    }

    showCustomItemsModal = items => e => {
        this.setState({
            customItems: items,
            customItemsModal: true
        })
    }

    showBundlesModal = items => e => {
        this.setState({
            bundles: items,
            bundleItemsModal: true
        })
    }

    getBundlesTotal(bundles) {
        let price = 0;
        if(bundles) {
            for (const item of bundles) {
                price += (this.getBundleItemsCost(item.products) + this.getBundleItemsCustomFieldsTotalPrice(item.products)) * item.quantity
            }
        }

        return price;
    }

    getBundleItemsCost(bundleItems) {
        let price = 0;
        if(bundleItems) {
            for (const item of bundleItems) {
                price += item.cost_price
            }
        }

        return price;
    }

    getCustomFieldsTotalPrice(customFields) {
        let price = 0 ;
        if(customFields) {
            for (let customField of customFields) {
                console.log('customField: ', customField);
                if(customField.additionalPriceChecked && customField.value && customField.value !== '') {
                    console.log('customField.price: ', customField.price);
                    price += customField.price
                }
            }
        }
        return price;
    }
    
    getBundleItemsCustomFieldsTotalPrice(bundleItems){
        let price = 0;
        if(bundleItems) {
            for (const item of bundleItems) {
                price += this.getCustomFieldsTotalPrice(item.custom_fields)
            }
        }
        console.log('price:', price);
        return price;
    }

    async init() {
        try {
            this.getAllOutstandings().then(res => {
                this.setState({
                    outstandingItems: res.data.data,
                    originalOutstandingItmes: [...res.data.data],
                    lastEvaluatedKey: res.data.lastEvaluatedKey
                })
            });

            this.getAllItemSuppliers().then(res => {
                this.setState({
                    itemSuppliers: res.data.data,
                })
            })

            this.getAllDecorationSuppliers().then(res => {
                this.setState({
                    decorationSuppliers: res.data.data
                })
            })

            this.getAllStores().then(res => {
                this.setState({
                    stores: res.data.data
                })
            })

        } catch (error) {
            this.setState({
                isLoading: false
            })
            console.error(error);
        }
    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        const data = this.state.outstandingItems;
	    // const data = this.state.outstandingItems && this.state.outstandingItems.sort((a, b) => b.createdAt - a.createdAt);

        const columns = [
            	{
				Header: (header) => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 16 }}>
						<input
							type="checkbox"
							className="custom-control-input"
							id="selectAllCheckBox"
							checked={this.state.selectedAll || false}
							onChange={() => this.toggleSelectAll()}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.indeterminateSign;
								}
							}}
						/>
						<label className="custom-control-label" htmlFor="selectAllCheckBox"></label>
					</div>
				),
				width: 52,
				Cell: row => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input type="checkbox" className="custom-control-input" id={`item-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original)} />
						<label className="custom-control-label" htmlFor={`item-id-${row.original.id}`}></label>
					</div>
				),
				className: 'header-checkbox-toggle',
				headerStyle: { padding: '1.07143em 0' },
				style: { padding: '0.71429em 0' }
				
			},
            {
                Header: 'Item',
                Cell: row => {
                    return (
                         <React.Fragment>
                            <img className="mr-2" width="50" height="50" src={row.original.product.item ? row.original.product.item.item_image : row.original.product.image[0]} />
                            <div>
                                {row.original.product.product_name}
                                {row.original.size && <p className="m-0">Size: {row.original.size}</p>}
                                {row.original.colour && <p className="m-0">Colour: {row.original.colour.name}</p>}
                                {
                                    row.original.custom_items && (
                                        <button onClick={this.showCustomItemsModal(row.original.custom_items)} type="button" className="btn btn-info btn-sm">
                                            Custom <span class="badge badge-light">{row.original.custom_items.length}</span>
                                        </button>
                                    )
                                }

                                {
                                    row.original.type === 'bundle' && (
                                        <button onClick={this.showBundlesModal(row.original.product_items)} type="button" className="btn btn-info btn-sm">
                                            Show Items <span class="badge badge-light">{row.original.product_items.length}</span>
                                        </button>
                                    )
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            },
            {
                Header: 'Cost',
                Cell: row => {
                    return <React.Fragment>
                    $ {
                        row.original.product.product_type == 'bundle' ? (
                           ''
                        ) : (
                            (row.original.product.sale_type == 'amount' ? row.original.product.online_price - row.original.product.sale
                            : row.original.product.sale_type == 'percent_off' ? row.original.product.online_price - ((row.original.product.sale / 100) * row.original.product.online_price)
                            : row.original.product.online_price) + this.getCustomFieldsTotalPrice(row.original.custom_fields)
                        )
                    }
                    </React.Fragment>
                     
                    
                }
            },
            {
                Header: 'Qty',
                Cell: row => (
                    <span>x{row.original.item_quantity}</span>
                ),
            },
            {
                Header: 'Supplier',
                Cell: row => <React.Fragment>
                    <span>{row.original.item_supplier && row.original.item_supplier.supplier_name} </span>
                </React.Fragment>
            },
            {
                Header: 'Decoration supplier',
                Cell: row => <React.Fragment>
                    <span>{row.original.decoration_supplier && row.original.decoration_supplier.decoration_name} </span>
                </React.Fragment>
            },
            {
                Header: 'Store',
                Cell: row => <React.Fragment>
                    <span>{row.original.store && row.original.store.store_name} </span>
                </React.Fragment>
            },
            {
                Header: 'Total',
                Cell: row => (
                    row.original.product.product_type == 'bundle' ? this.getBundlesTotal(row.original.product_items) : 
                   (
                    <span>${row.original.item_quantity * (row.original.product.sale_type == 'amount' ? (
                        row.original.product.online_price - row.original.product.sale
                    ) : row.original.product.sale_type == 'percent_off' ? (
                        row.original.product.online_price - ((row.original.product.sale / 100) * row.original.product.online_price)
                    ) : (
                        row.original.product.online_price
                    ))}</span>
                   )
                )
            },
            // {
            //     Header: 'Action',
            //     Cell: row => (
            //         <React.Fragment>
            //         <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
            //         {
            //             row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
            //         }
            //         </button>
            //         </React.Fragment>
            //     ),
            // },
        ];
        return (
            <div className="page-body">
            <Modal
                   open={this.state.bundleItemsModal} 
                   onClose={this.onBundleItemsModalCloseModal}
                   center={true}
                   classNames={{
                    overlay: 'custom-overlay',
                    modal: 'custom-modal'
                   }}
                >
                        <div className="">
                        <div className="modal-header">
                            <h5 className="modal-title f-w-600" id="exampleModalLabel">Bundles</h5>
                        </div>
                        <div className="modal-dialog modal-lg">
                        <div className="modal-body">
                            {
                                this.state.bundles && this.state.bundles.map((item, i) => (
                                    <div key={i} className="row align-items-center">
                               
                                    <div className="col">
                                        {/* Title */}
                                        {/* <p className="mb-0 font-size-sm font-weight-bold">
                                        <span className="text-body">{item.product && item.product.product_name}</span> <br />
                                        <span className="text-muted">$
                                        {
                                            item.product.product_type == 'bundle' ? (
                                                (item.product.sale_type == 'amount' ? item.product.online_price - item.product.sale
                                                : item.product.sale_type == 'percent_off' ? item.product.online_price - ((item.product.sale / 100) * item.product.online_price)
                                                : item.product.online_price) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                                            ) : (
                                                (item.product.sale_type == 'amount' ? item.product.online_price - item.product.sale
                                                : item.product.sale_type == 'percent_off' ? item.product.online_price - ((item.product.sale / 100) * item.product.online_price)
                                                : item.product.online_price) + this.getCustomFieldsTotalPrice(item.custom_fields)
                                            )
                                        }
                                        </span>
                                        </p> */}
                                    
                                        {/* Text */}
                                        <div className="font-size-sm text-muted">
                                        <p className="mb-0">
                                            Item #{i+1}
                                        </p>

                                        <p className="mb-0">
                                            Quantity: {item.quantity}
                                        </p>

                                        <p className="mb-0">Cost: 
                                            $ 
                                            { 
                                               this.getBundleItemsCost(item.products) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                                            }
                                        </p>
                                        
                                        <p className="font-size-sm font-weight-bold">Items: </p>

                                        {
                                            item.products && item.products.map(product => (
                                                <div className="mb-4 border-bottom pl-5 pb-5">
                                                    {
                                                        product.image && (
                                                            <div >
                                                                    {/* Image */}
                                                                   {/* <img src={product.image[0]} style={{width: 70}} alt="..." className="img-fluid" /> */}
                                                                   <p className="mb-0 font-size-sm font-weight-bold">{product.product_name}</p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        product.selectedSize && <p className="mb-0"> Size: {product.selectedSize} </p>
                                                    }
                                                    {
                                                        product.selectedColour && <React.Fragment>
                                                            <p className="mb-0 d-inline mr-2"> Colour:</p>
                                                            <div className="d-inline-block" style={{width: 20, height: 20, backgroundColor: product.selectedColour.value}}>

                                                            </div>
                                                        </React.Fragment>
                                                    }

                                                    {
                                                        product.custom_fields && product.custom_fields.map((customField, i) => (
                                                            <p className="mb-0" key={i}>{customField.name}: {customField.value}</p>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }

                                        </div>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                        </div>
                      
                        <div className="modal-footer">
                            <button onClick={this.onBundleItemsModalCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                        </div>
                        </div>
            </Modal>

            <Modal
                   open={this.state.customItemsModal} 
                   onClose={this.oncustomItemsModalCloseModal}
                   center={true}
                   classNames={{
                    overlay: 'custom-overlay',
                    modal: 'custom-modal'
                   }}
                >
                        <div className="">
                        <div className="modal-header">
                            <h5 className="modal-title f-w-600" id="exampleModalLabel">Custom Items</h5>
                        </div>
                        <div className="modal-body">
                           <table className="table">
                               <thead>
                                   <tr>
                                       <th>Item</th>
                                       <th>Qty</th>
                                       {/* <th>Total</th> */}
                                   </tr>
                               </thead>
                               <tbody>
                                  {
                                      this.state.customItems && this.state.customItems.map(customItem => (
                                          <tr>
                                              <td>
                                                  {
                                                    customItem.custom_fields.map(customField => (
                                                        <p className="m-0">{customField.name} :  {customField.value}</p>
                                                    ))
                                                  }
                                              </td>
                                              <td>
                                                  <p>{customItem.item_quantity}</p>
                                              </td>
                                          </tr>
                                      ))
                                  }
                               </tbody>
                           </table>
                        </div>
                        <div className="modal-footer">
                            <button onClick={this.oncustomItemsModalCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                        </div>
                        </div>
            </Modal>

            <div className="container-fluid">
                {
                    (!this.state.outstandingItems ) && <Loader />
                }
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Outstandings
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                        <li className="breadcrumb-item active">Outstandings</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Genereate Purchase Order</h5>
                    </div>
                    <div className="card-body order-datatable">
                       <div className="d-flex justify-content-between mb-4">
                        <div className="d-flex">
                            <select name="item_supplier" onChange={this.onFilterChange} className="custom-select d-inline mr-2">
                                <option value='all'>All</option>
                                {
                                    this.state.itemSuppliers && this.state.itemSuppliers.map((supplier, i) => (
                                        <option key={i} value={supplier.id}>{supplier.supplier_name}</option>
                                    ))
                                }
                            </select>
                            <select onChange={this.onFilterChange} name="decoration_supplier" className="custom-select d-inline mr-2">
                                <option value='all'>All</option>
                                {
                                    this.state.decorationSuppliers && this.state.decorationSuppliers.map((supplier, i) => (
                                        <option key={i} value={supplier.id}>{supplier.decoration_name}</option>
                                    ))
                                }
                            </select>
                            <select onChange={this.onFilterChange} name="store" className="custom-select d-inline">
                                <option value='all'>All</option>
                                {
                                    this.state.stores && this.state.stores.map((store, i) => (
                                        <option key={i} value={store.id}>{store.store_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                         <button disabled={this.state.isLoading} onClick={this.generatePurchaseOrders} type="button" className="btn btn-secondary">
                            {
                                this.state.isLoading ? (
                                    <div>
                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                        Loading...
                                    </div>
                                ) : 'Generate'
                            }
                         </button>
                        </div>
                       </div>


                       <div className="table-responsive">
                       <ReactTable
                            // PaginationComponent={Pagination}
                            showPagination={false}
                            data={data}
                            minRows={5}
                            columns={columns}
                            noDataText={(!this.state.outstandingItems) ? '' : "No Outstanding Found."}
                            pageSize={this.state.outstandingItems.length}
                            // defaultPageSize={undefined}
                            // pageSizeOptions={[10, 25, 50, 100]}
                            // resizable={true}
                            onPageChange={(index) => this.handlePageChange(index)}
                            onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                            getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                        />
                       </div>

                        {
                            this.state.lastEvaluatedKey && (
                                <div className="d-flex justify-content-center mt-2">
                                    <button disabled={this.state.loadMoreIsLoading} onClick={this.loadMore} className="btn btn-link">
                                    {
                                        this.state.loadMoreIsLoading ? (
                                            <div>
                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                Loading...
                                            </div>
                                        ) : 'Load more'
                                    }
                                    </button>
                                </div>
                            )
                        }

                    {/* 
                       <ul className="mt-3">
                            <li role="button" onClick={this.handlePreviousClick} className={`cursor-pointer page-item ${this.state.currentPage == 1 ? 'disabled' : ''}`}><span className="page-link " tabIndex={-1} role="button" aria-disabled="true" aria-label="Previous page" rel="prev">Previous</span></li>
                            {
                                this.state.pages && this.state.pages.map((page, i) => (
                                    <li onClick={this.handlePageClick(page)} key={i} className={`cursor-pointer page-item ${this.state.currentPage === page ? 'active' : ''}`}><span rel="canonical" role="button" className="page-link" aria-current="page">{page}</span></li>
                                ))
                            }
                            {
                                this.state.lastEvaluatedKey && (
                                    <li className="cursor-pointer page-item"><span className="page-link" role="button" >...</span></li>
                                )
                            }
                            <li onClick={this.handleNextClick} className={`cursor-pointer page-item ${!this.state.lastEvaluatedKey ? 'disabled' : ''}`}><span className="page-link" tabIndex={0} role="button" aria-disabled="false" aria-label="Next page" rel="next">Next</span></li>
                        </ul> */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

            
          
        );
    }
}

export default GeneratePO;