import React, { Component } from 'react';
import LogoImg from '../../public/images/athleadhq-logo-white.png';
import emailSvg from '../../public/svg/email.svg';
import passwordSvg from '../../public/svg/password.svg';
import './confirms.scss';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import history from '../../history';    

class Confirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            code: ''
        }
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        const params = queryString.parse(this.props.location.search)
        console.log(params)

        this.setState({
            isLoading: true
        })
        const data = {
            code: this.state.code,
            token: params.token,
            password: this.state.password,
            confirm_password: this.state.password,
        }
        request('POST', '/auth/admin/confirm', data, false).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            ).then(() => {
                history.push('/login');
            })
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            console.error(err.response);
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }
    
    resendCode = e => {
        const params = queryString.parse(this.props.location.search)
        console.log(params)

        this.setState({
            isLoading: true
        })
        const data = {
            token: params.token
        }
        request('POST', '/auth/admin/code', data, false).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            console.error(err.response);
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }

    init() {
        const params = queryString.parse(this.props.location.search)
        if(!params.token) {
            Swal.fire(
                'Error',
                'Invalid request',
                'error'
            ).then(() => {
                history.push('/account/login')
            })
        }
    }

    componentDidMount() {
        this.init()
    }

    render() {
        return ( 
            
            <div className="login-container text-center">
                <div className="logo-container">
                    <img src={LogoImg} />
                </div>
                <div className="form-container p-5">
                    <h4 className="mb-4">Confirm Account</h4>
                    <form onSubmit={this.handleSubmit}>
                        {/* <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-email"><img src={emailSvg} className="imgsvg" alt="username" /></span>
                                    </span>
                                </div>
                                <input onChange={this.handleChange} name="username" type="text" className="form-control" placeholder="Username" />
                            </div>
                        </div> */}
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-pass">
                                            <img src={passwordSvg} className="imgsvg" alt="code" />
                                        </span>
                                    </span>
                                </div>
                                <input required={true} onChange={this.handleChange} name="code"  type="text" className="form-control" placeholder="Verification Code" />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-pass">
                                            <img src={passwordSvg} className="imgsvg" alt="password" />
                                        </span>
                                    </span>
                                </div>
                                <input required={true} onChange={this.handleChange} type="password" name="password" className="form-control" placeholder="Password" />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-pass">
                                            <img src={passwordSvg} className="imgsvg" alt="password" />
                                        </span>
                                    </span>
                                </div>
                                <input required={true} onChange={this.handleChange} type="password" name="confirm_password" className="form-control" placeholder="Confirm Password" />
                            </div>
                        </div>

                        <div className="forgot-keepme">
                            <button onClick={this.resendCode} type="button" className="btn text-dark btn-link">
                                {
                                    this.state.isLoading ? (
                                        <div>
                                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                            Loading...
                                        </div>
                                    ) : 'Resend verification code'
                                }
                            </button>
                        </div>
                        <div className="action">
                            <button disabled={this.state.isLoading} type="submit" name="signin" className="btn btn-block btn-primary">
                                        {
                                            this.state.isLoading ? (
                                                <div>
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                    Loading...
                                                </div>
                                            ) : 'Confirm'
                                        }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Confirm;