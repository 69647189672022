import Axios from "axios";
import Cookies from 'js-cookie';

export const API_URL = "https://api.athlead.app";
// export const API_URL = "https://gqrigb5fh9.execute-api.ap-southeast-2.amazonaws.com/prod";
// export const API_URL = "https://d2tzfaaxe8.execute-api.ap-southeast-2.amazonaws.com/dev";

// export const PROFILE_IMAGE_BASE_URL = "http://127.0.0.1:8000/storage/profileimages";

export const request = (method, url, data = null, isNeedAuthorization = false) => {
    return Axios.request({
        method: method,
        url: API_URL + url,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            // 'X-Requested-With': 'XMLHttpRequest',
            'Authorization': isNeedAuthorization ?  `Bearer ${getToken()}` : null
        },
        params: method === 'GET' ? data : null
    })
}

export const getToken = () => {
    const user = getUser(); 
    console.log(user)
    return user && user.access_token;
}

export const getUser = () => {
    return Cookies.getJSON('user');
}
