import React, { useState, useEffect } from 'react';
import Routes from './routes/Routes';
import { Router, HashRouter } from 'react-router-dom';
import './App.scss';
import 'bootstrap/dist/js/bootstrap';
import history from './history';
import Header from './components/shared/header';
import Footer from './components/shared/footer';
import LeftSidebar from './components/shared/left-sidebar';
import { UserProvider } from './contexts/user';
import Cookies from 'js-cookie';
import Axios from 'axios';
import Swal from 'sweetalert2'
// import LeftSidebar from './components/shared/left-sidebar';
// import Header from './components/shared/header';
// import Intercom from 'react-intercom';


Axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  console.error(error.response);
  if(!error.response) {
      Swal.fire(
        'Error',
        'Something went wrong',
        'error'
      )
  } else {
    if (401 === error.response.status) {
      Cookies.remove('user');
      window.location.href = '/'
    } 
  }
  return Promise.reject(error);
})

function App() {
  const userOnCookie = Cookies.getJSON('user');
  console.log(userOnCookie);
  const [user, setUser] = useState(userOnCookie);
  useEffect(() => {
    setUser(userOnCookie)
  })
  // let userIntercom = {
  //   user_id: '',
  //   email: '',
  //   name: ''
  // };

  // if (user) {
  //   userIntercom = {
  //       user_id: user.user.intercom_id,
  //       email: user.user.email,
  //       name: user.user.name
  //     }
  // }
  return (
    // <Router history={history}>
    //   <UserProvider value={{ user, setUser }}>
    //     <div className="App">
    //       <div className="wrapper">
    //         <div className="wrapper-inner">
    //           { user && <LeftSidebar /> }
    //           <div className={'main-panel ' + ( !user && 'auth' )}>
    //             { user && <Header /> }
    //             <div className="main-content">
    //               <div className="container-fluid h-100">
    //                 <Routes />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         {/* { user && <Intercom appID="wt440dsi" {...userIntercom} /> } */}
    //       </div>
    //     </div>
    //   </UserProvider>
    // </Router>

    <Router history={history}>
      <UserProvider value={{ user, setUser }}>
        <div className="page-wrapper">
          { user &&  <Header /> }
          <div className="page-body-wrapper">
            { user && <LeftSidebar /> }
            <Routes />
          </div>
          {/* <Footer /> */}
        </div>
      </UserProvider>
    </Router>
  );
}

export default App;
