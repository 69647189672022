import React, { Component } from 'react';
import { request } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Loader from '../../../../components/loader/loader';
import Pagination from '../../../../components/pagination/pagination';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';

class StoreCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            category_name: '',
            slug: '',
            modal: false
        }
    }

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }

    openModal = () => {
        this.setState({
            modal: true
        })
    }
    
    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const categories = this.state.categories;
                const category = categories[index];
                category.isLoading = true;
                this.setState({
                    categories: categories
                })
                request('DELETE', `/hq/stores/${this.props.match.params.storeId}/categories/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    this.props.refresh();
                    category.isLoading = false;
                    this.setState({
                        categories: categories
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    category.isLoading = false;
                    this.setState({
                        categories: categories
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
    }

    handleAddCategory = e => {
        this.onCloseModal();
        this.setState({
            isLoading: true
        })
        const data = {
            category_name: this.state.category_name,
            slug: this.state.slug
        }
        request('POST', `/hq/stores/${this.props.match.params.storeId}/categories`, data, true).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success!',
                res.data.message,
                'success'
            ).then(() => {
                this.init();
                this.props.refresh();
            })
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            console.error(err);
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            )
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getStoreCategories() {
        return request('GET', `/hq/stores/${this.props.match.params.storeId}/categories`, {}, true);
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getStoreCategories().then(res => {
            console.log(res);
            this.setState({
                categories: res.data.data,
                isLoading: false
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
        })
    }
    
    componentDidMount(){
        this.init()
    }

    render() {
        const data = this.state.categories;
		const columns = [
            {
                Header: 'Name',
                accessor: 'category_name'
            },
            {
                Header: 'Slug',
                accessor: 'slug'
            },
            {
                Header: 'Action',
                Cell: row => (
                    <React.Fragment>
                   
                    <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
                    {
                        row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
                    }
                    </button>
                    </React.Fragment>
                ),
            },
        ];
        return (
            <div>
                <Modal
                        open={this.state.modal} 
                        onClose={this.onCloseModal}
                        center={true}
                        classNames={{
                        overlay: 'custom-overlay',
                        modal: 'custom-modal'
                    }}
                >
                        <div className="">
                        <div className="modal-header">
                            <h5 className="modal-title f-w-600" id="exampleModalLabel">Add Category</h5>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="mb-1">Category Name :</label>
                                <input onChange={this.handleChange} name="category_name" value={this.state.category_name} type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="mb-1">Slug :</label>
                                <input onChange={this.handleChange} name="slug" value={this.state.slug} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button onClick={this.handleAddCategory} className="btn btn-secondary" type="button">SAVE</button>
                            <button onClick={this.onCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                </Modal>
                <div className="container-fluid">
                <div className="page-header pt-0">
                    <div className="row justify-content-between">
                    <div className="col-lg-6 col-md-">
                        <div className="page-header-left">
                        <h4> Categories</h4>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <button onClick={this.openModal} className="btn btn-secondary pull-right">Add Category</button>
                    </div>
                    </div>
                </div>
                </div>

                <div className="table-responsive">
                           {
                               this.state.isLoading ? <Loader /> : (
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Stores Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    // resizable={true}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                 />
                               )
                           }
                </div>
            </div>
        );
    }
}

export default StoreCategories;