import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { request } from '../../../../constants/constants';
import Swal from 'sweetalert2';
import { ChromePicker } from 'react-color';
import Modal from 'react-responsive-modal';


class General extends Component {
    constructor(props) {
        super(props);
        this.state = {
            store_name: '',
            store_code: '',
            slug: '',
            description: '',
            password: '',
            store_logo: '',
            theme_color: '#C80C0F',
            modal: false
        }
       
    }

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }

    openModal = () => {
        this.setState({
            modal: true
        })
    }

    handleChangeComplete = (color) => {
        this.setState({ theme_color: color.hex });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        e.target.classList.add('was-validated');
        if(e.target.checkValidity) {
            this.setState({
                isLoading: true
            })
            const data = {
                store_name: this.state.store_name,
                store_code: this.state.store_code,
                theme_color: this.state.theme_color,
                slug: this.state.slug,
                description: this.state.description,
                // password: this.state.password
            }
            if(this.state.store_logo.indexOf('base64')) {
                data.store_logo = this.state.store_logo
            }
            request('PUT', `/hq/stores/${this.props.match.params.storeId}`, data, true).then(res => {
                this.setState({
                    isLoading: false
                })
                console.log(res);
                this.props.refresh();
                Swal.fire(
                    'Success!',
                    res.data.message,
                    'success'
                );
            }).catch(err => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }


    onDrop = files => {
        if(files.length <= 0) {
			Swal.fire(
				'Error',
				'Please upload an image file',
				'error'
			)
		} else {
			this.setState({files})
			const file = files[0]; 
            const extension = file.name.split('.')[1];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.setState({
                    store_logo: reader.result
                })
            }
            reader.onerror = error => {
                console.error(error);
            };
        }
    }
    

    componentDidMount() {
        this.setState({
            ...this.props.store
        })
    }
    render() {
        return (
            <div className="row product-adding">
                
            <Modal
                open={this.state.modal} 
                onClose={this.onCloseModal}
                center={true}
                classNames={{
                overlay: 'custom-overlay',
                modal: 'custom-modal'
                }}
            >
                    <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="exampleModalLabel">Choose Color</h5>
                    </div>
                    <div className="modal-body">
                        <ChromePicker  
                            color={this.state.theme_color}
                            onChangeComplete={this.handleChangeComplete}
                            width='100%'
                        />

                    </div>
                    {/* <div className="modal-footer">
                        <button onClick={this.handleAdd} className="btn btn-secondary" type="button">ADD</button>
                        <button onClick={this.onCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                    </div> */}
                    </div>
            </Modal>
            
            <div className="col-xl-5">
                <div className="add-product">
                <div className="row">
                    <div className="col-xl-9 xl-50 col-sm-6 col-9">
                    <Dropzone 
                            className="drop-area d-flex align-items-center"
                            multiple={false}
                            accept="image/*"
                            onDrop={this.onDrop}
                        >
                                {({getRootProps, getInputProps}) => (
                                <section >
                                    {this.state.store_logo && <img className="img-fluid" src={this.state.store_logo} />}
                                    <div className="dropzone">
                                    <div className={"inner-text " + (this.state.store_logo && "has-image")}>
                                        <p>
                                            <span className="icon icon-upload">
                                                <svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                    <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
                                                </svg>
                                            </span>
                                        </p>
                                        <p>Drop files here or click to upload.</p>
                                    </div>
                                    </div>
                                    <aside>
                                    {/* <h4>Files</h4> */}
                                    </aside>
                                </section>
                                )}
                        </Dropzone>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-xl-7">
                <form onSubmit={this.handleSubmit} className="needs-validation add-product-form" noValidate>
                <div className="form">
                    <div className="form-group mb-3 row">
                        <label htmlFor="validationCustom01" className="col-xl-3 col-sm-4 mb-0">Name :</label>
                        <input value={this.state.store_name} name="store_name"  onChange={this.handleChange}  className="form-control col-xl-8 col-sm-7" id="validationCustom01" type="text" required />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    {/* <div className="form-group mb-3 row">
                            <label htmlFor="validationCustom01" className="col-xl-3 col-sm-4 mb-0">Slug :</label>
                            <input name="slug"  onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom01" type="text" required />
                            <div className="valid-feedback">Looks good!</div>
                        </div> */}
                    <div className="form-group mb-3 row">
                        <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Code :</label>
                        <input maxLength={3} value={this.state.store_code} name="store_code" onChange={this.handleChange}  className="form-control col-xl-8 col-sm-7" id="validationCustom02" type="text" required />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback offset-sm-4 offset-xl-3">Please choose Valid Code.</div>
                    </div>

                    <div className="form-group mb-3 row">
                        <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Description :</label>
                        <textarea  value={this.state.description} name="description" onChange={this.handleChange}  className="form-control col-xl-8 col-sm-7" id="validationCustom02" type="text" />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback offset-sm-4 offset-xl-3">Please choose Valid Code.</div>
                    </div>

                    <div className="form-group mb-3 row">
                        <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Theme Color :</label>
                        <div style={{backgroundColor: this.state.theme_color, display: 'inline-block', height: 30, width: 30}}>
                        </div>
                        <button onClick={this.openModal} type="button" className="btn btn-link">Change color</button>
                    </div>

                    <div className="form-group mb-3 row">
                        {/* <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Password :</label> */}
                        {/* <input value={this.state.password}  name="password" onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom02" type="text" required /> */}
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback offset-sm-4 offset-xl-3">Please choose Password.</div>
                    </div>
                </div>
                <div className="offset-xl-3 offset-sm-4">
                    <button type="submit" className="btn btn-primary">
                        {this.state.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
                    </button>
                    {/* <button type="button" className="btn btn-light">Discard</button> */}
                </div>
                </form>
            </div>
            </div>
        );
    }
}

export default General;