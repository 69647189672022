import React, { Component } from 'react';

class Dashboard extends Component {
    render() {
        return (
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="page-header-left">
                            <h3>Dashboard
                            {/* <small>Multikart Admin panel</small> */}
                            </h3>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        {/* <ol className="breadcrumb pull-right">
                            <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                            <li className="breadcrumb-item active">Designs</li>
                        </ol> */}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;