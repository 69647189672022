import React, { Component } from 'react';
import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/pagination/pagination';
import { request } from '../../../constants/constants';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loader from '../../../components/loader/loader';
import Axios from 'axios';
import './awaitingShipment.scss';
import Modal from "react-responsive-modal";
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import history from '../../../history';

class AwaitingShipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            pages: [1],
            items: [],
            pageSize: 10,
            currentPage: 1,
            selectedAll: {},
			selected: {},
			indeterminateSign: false,
            modal: false,
            statusOptions: [
                {
                    label: 'Pending Payment',
                    value: 'pending_payment',
                },
                {
                    label: 'Processing',
                    value: 'processing',
                }, 
                {
                    label: 'On Hold',
                    value: 'on_hold',
                },
                {
                    label: 'Partial Shipped',
                    value: 'partial_shipped',
                },
                {
                    label: 'Shipped',
                    value: 'shipped',
                },
                {
                    label: 'Completed',
                    value: 'completed',
                }, 
                {
                    label: 'Cancelled',
                    value: 'cancelled',
                },
            ],
            ship_date: moment(),
            weight: 0,
            provider: 'fast_way',
            type: 'satchel',
            size: 'A5',
            provider_type: 'standard',
            length: 0,
            width: 0,
            height: 0
        }
    }

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }

    openModal = order => e => {
        const items = order.order_items.filter(element => !element.shipment_id);
        this.setState({
            modal: true,
            items: items,
            orderId: order.id
        });
    }

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.items.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.items.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.items.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
		
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false,
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.state.items.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedGames = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });

			// this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				currentSelecteditems: []
			});
		}
	}
	toggleRow(item) {
		const itemId = item.id;
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[itemId] = !this.state.selected[itemId];
		this.setState({
			selected: newSelected
		}, () => {
            let totalWeightItems = 0;

            for (const key in this.state.selected) {
                if (this.state.selected[key]) {
                    const selectedItemIndex = this.state.items.findIndex(item => item.id === key);
                    if(selectedItemIndex > -1) {
                        totalWeightItems += this.state.items[selectedItemIndex].product.item.item_weight; 
                    }
                }
            }
            this.setState({
            weight: totalWeightItems,
            })
        });
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

    getAwatingShipments(lastEvaluatedKey, filters) {
        return request('GET', `/hq/orders/awaiting-shipments${lastEvaluatedKey ? `?lastEvaluatedKey=${lastEvaluatedKey}` : ''}`, {}, true);
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const orders = this.state.orders;
                const product = orders[index];
                product.isLoading = true;
                this.setState({
                    orders: orders
                })
                request('DELETE', `/hq/orders/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    product.isLoading = false;
                    this.setState({
                        orders: orders
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    product.isLoading = false;
                    this.setState({
                        orders: orders
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
   
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleShipDateChange = date => {
        this.setState({
            ship_date: date
        })
    }

    getProductNames(items = []) {
        let productNames = '';
        items.forEach(item => productNames += `${item.product.product_name},`);
        return productNames.substring(0, productNames.length-1);
    }

    handleCreateLabel = e => {
        this.setState({
            isLoading: true
        })
        const selectedItems = [];
        let totalWeightItems;

        for (const key in this.state.selected) {
            if (this.state.selected[key]) {
                selectedItems.push(key);
                const selectedItemIndex = this.state.items.findIndex(item => item.id == key);
                if(selectedItemIndex > -1) {
                    totalWeightItems += this.state.items[selectedItemIndex].product.item.item_weight; 
                }
            }
        }

        if(selectedItems.length <= 0) {
            this.setState({
                isLoading: false
            })
            return Swal.fire(
                'Error',
                'Please select item',
                'error'
            )
        }

        const data = {
            ship_provider: this.state.provider,
            ship_provider_type: this.state.provider_type,
            ship_date: this.state.ship_date.format('YYYY-MM-DD'),
            // shipment_type: Object.keys(this.state.selected).length == this.state.items.length ? 'full' : 'partial',
            length: this.state.length,
            width: this.state.width,
            height: this.state.height,
            weight: this.state.weight,
            items: selectedItems,
            type: this.state.type,
            size: this.state.size
        }
        console.log(data);
        request('POST', `/hq/orders/${this.state.orderId}/shipments`, data, true)
        .then(res => {
            this.setState({
                isLoading: false
            })
            console.log(res);
            // this.init();
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            ).then(() => {
                history.push(`/shipments/${res.data.data.id}/detail`)
            })
        }).catch(err => {
            console.error(err.response)
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }

    getTotalWeight() {
        let totalWeightItems = 0;

        for (const key in this.state.selected) {
            if (this.state.selected[key]) {
                const selectedItemIndex = this.state.items.findIndex(item => item.id === key);
                if(selectedItemIndex > -1) {
                    totalWeightItems += this.state.items[selectedItemIndex].product.item.item_weight; 
                }
            }
        }

        return totalWeightItems;

    }

    handleNextClick = e => {
        const currentPage = this.state.currentPage + 1;

       if(this.state.lastEvaluatedKey && this.state.pages.indexOf(currentPage) <= -1) {
            this.setState({
                isLoading: true
            })
            const pages = this.state.pages;
            console.log('currentPage: ', currentPage);
            if(this.state.pages.indexOf(currentPage) <= -1) {
                pages.push(currentPage);
            }
            this.setState({
                currentPage,
                pages,
            })
            this.getAwatingShipments(this.state.lastEvaluatedKey.id.S).then(res => {
                console.log(res);
                const newOrders = [...this.state.orders, ...res.data.data];
                const endOffset = parseInt(currentPage + '0');
                const newItemOffset = endOffset - 10
                this.setState({
                    currentPage,
                    pages,
                    lastEvaluatedKey: res.data.lastEvaluatedKey,
                    isLoading: false,
                    orders: newOrders,
                    endOffset,
                    itemOffset: newItemOffset
                })
            }).catch(err => {
                console.error(err);
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })
           
       } else {
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                endOffset,
                itemOffset: newItemOffset
            })
       }
    }

    handlePreviousClick = e => {
        if(this.state.currentPage !==1 ) {
            const currentPage = this.state.currentPage - 1;
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                currentPage,
                itemOffset: newItemOffset,
                endOffset: endOffset
            })
        }
    }

    handlePageClick = page => e => {
        const endOffset = parseInt(page + '0');
        const newItemOffset = endOffset - 10

        this.setState({
            currentPage: page,
            itemOffset: newItemOffset,
            endOffset
        })
    }


    init() {
        this.setState({
            isLoading: true
        })
        this.getAwatingShipments().then(res => {
            console.log(res);
            this.setState({
                orders: res.data.data,
                isLoading: false,
                lastEvaluatedKey: res.data.lastEvaluatedKey
            })
        })
    }

    // test() {
    //     Axios.request({
    //         method: 'GET',
    //         url: "https://api.myfastway.com.au/api/track/label/MS0000917205",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-Requested-With': 'XMLHttpRequest',
    //             'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjdEMjE4MDVBRTc2ODQ3OTAzRDYxQTY3RDQ1NkJDRTJGOEQyMjkwMkYiLCJ0eXAiOiJhdCtqd3QiLCJ4NXQiOiJmU0dBV3Vkb1I1QTlZYVo5Uld2T0w0MGlrQzgifQ.eyJuYmYiOjE1OTQwODY1MzksImV4cCI6MTU5NDA5MDEzOSwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS5mYXN0d2F5Lm9yZyIsImF1ZCI6ImZ3LWZsMi1hcGktYXUiLCJjbGllbnRfaWQiOiJmdy1mbDItQ0JSMDA4MDE0OS1kNzY2NmRjMzMyZTYiLCJjb3VudHJ5aWQiOiIxIiwicGFyZW50aWQiOiIwIiwiY3VzdG9tZXJpZCI6IjM0Mzk2MCIsInNjb3BlIjpbImZ3LWZsMi1hcGktYXUiXX0.QbtjnK6jCWwmG-YArzCWDbbcbXrSfgLyJeKMG4xqYXyZM-6w76lRVCrgluqN7r5s5JDS_2Pvy82jT90iQ3c5V76mo6v0DnLgG9zrN5gxij86OnYv7fZAMFhbwOA9YXDhm2z6RlvZBG1ZZpAuN_X9N2bICkx2SG7f2qkuEE1b8aJdDoBY1hk5R_TL_czse8lAvFSDhinsWmS__r7ktbeoGYdZKX7vsnUgzIST1WOoQQ5oY0PMxTtwsJKbLfXNrF42jexmxRlaHPSBqh7Tk1bf3Rbq9kdP5djSWQupLAGmLBWQGRaeH_0vNHUv-auoMdAcMNvptYwcHs7Ue-dKUOnrd-EzJPaaehyXm_ySVHelb6Qc8MoH1gRPiRuduXD6QnWBtvFpAc7pqmzu_2b-99WwLr5q6FXJGjy10SBtpqijaCigJ1ZgQ-rHxqyqMl4iPGqB431MIbH5S9V9abCdTwtM7RhR0S7jSTKnj07bYA5O7kEnPHpP47O2kyKPYVa38RfMF2sTIMkez5F8PpzbycLqvHvJ-y4HNGWXB89OuWpP3RkIY6u7cws4AeiKa9cJR2M2V4AD7c8NfcAGMHwQprWYsThPCskZ_Ip0y-Vk2F6iMmfWj5JFUivlAPzOq6UEOxlELTbycoF98n92vpd15sswe67xx9XQjfrlOZGCZxUZ2Vo'
    //         }
    //     }).then(res => {
    //         console.log(res)
    //         const blob = new Blob([res.data], {type: "application/pdf"});
    //         var objectUrl = URL.createObjectURL(blob);
    //         window.open(objectUrl);
    //     }).catch(err => console.error(err.response))
    // }

    componentDidMount() {
        this.init();
    }
    
    render() {
        // const data = this.state.orders.sort((a, b) => b.order_number - a.order_number);;
        const data = this.state.orders && this.state.orders.slice(this.state.itemOffset, this.state.endOffset);

		const columns = [
            {
                Header: 'Name',
                Cell: row => {
                    return (
                    <div className="match">
                        <span className="highlight">Order #{row.original.order_number}</span>
                        {
                            row.original.status == 'partial_shipped' && <span className="ml-2 text-white p-2 badge badge-info">{row.original.status}</span>
                        }
                        <div className="actions">
                            <ul className="list-inline">
                                <li>
                                    <button onClick={this.openModal(row.original)} className="btn-link btn font-primary p-0 pr-2">Create Label</button>
                                </li>
                                <li><span className="seprator">|</span></li>
                                <li>
                                    <Link to={`/orders/${row.original.id}/detail`}><button className="btn-link btn font-primary p-0 pl-2">View order</button></Link>
                                </li>
                                {/* <li><span className="seprator">|</span></li> */}
                                {/* <li>
                                    <button onClick={() => this.handleDelete(row.original.id)} className="link">Delete</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    )
                },
                width: 300
            },
            // {
            //     Header: 'Product',
            //     Cell: row => {
            //         return <span>
            //             {this.getProductNames(row.original.items)}
            //         </span>
            //     }
            // },
            {
                Header: 'Customer',
                Cell: row => (
                <span>{row.original.first_name} {row.original.last_name}</span>
                ),
            },
            {
                Header: 'Order Date',
                Cell: row => (
                    <span>{moment(row.original.createdAT).format('MMMM DD, YYYY')}</span>
                ),
            },
            {
                Header: 'Total',
                Cell: row => <span>${row.original.total}</span>
            },
            // {
            //     Header: 'Action',
            //     Cell: row => (
            //         <React.Fragment>
            //         <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
            //         {
            //             row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
            //         }
            //         </button>
            //         </React.Fragment>
            //     ),
            // },
        ];

        const itemColumns = [
            {
				Header: (header) => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						{/* <input
							type="checkbox"
							className="custom-control-input"
							id="selectAllCheckBox"
							checked={this.state.selectedAll[this.state.currentPage] || false}
							onChange={() => this.toggleSelectAll()}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.indeterminateSign;
								}
							}}
						/>
						<label className="custom-control-label" htmlFor="selectAllCheckBox"></label> */}
					</div>
				),
				width: 52,
				Cell: row => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input type="checkbox" className="custom-control-input" id={`item-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original)} />
						<label className="custom-control-label" htmlFor={`item-id-${row.original.id}`}></label>
					</div>
				),
				className: 'header-checkbox-toggle',
				headerStyle: { padding: '1.07143em 0' },
				style: { padding: '0.71429em 0' }
				
			},
            {
                Header: 'Name',
                Cell: row => {
                    return (
                        <div className="d-flex  justify-content-start">
                            <div className="">
                                <img src={row.original.product.image[0]} />
                            </div>
                            <div className="pl-2">
                                <p className="m-0">{row.original.product.product_name}</p>
                                <p className="m-0">SKU: {row.original.product.SKU}</p>
                                <p className="m-0">Size: {row.original.size}</p>
                            </div>
                        </div>
                    )
                },
                width: 230
            },
            {
                Header: 'Qty',
                Cell: row => {
                    return <span>{row.original.item_quantity}</span>
                },
                width: 80
            },
            {
                Header: 'Total',
                Cell: row => {
                    return <span>${row.original.total}</span>
                },
                width: 100
            },
        ];

        const format = 'YYYY-MM-DD';
        const now = moment();

        const calendar = (<Calendar
			style={{ zIndex: 1000 }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={false}
			showClear={true}
		/>);
        return (
           <div className="page-body awaiting-shipments">
                {
                    this.state.isLoading && <Loader />
                }

            <Modal
                    open={this.state.modal} 
                    onClose={this.onCloseModal}
                    center={true}
                    classNames={{
                    overlay: 'custom-overlay',
                    modal: 'custom-modal modal-lg'
                }}
            >
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="createLabel">Create Label</h5>
                </div>
                <div className="modal-body modal-lg">

                    <label className="mb-1">Select Items:</label>

                    <div className="table-responsive mb-4">
                       <ReactTable
                            // PaginationComponent={Pagination}
                            data={this.state.items}
                            minRows={1}
                            columns={itemColumns}
                            showPagination={false}
                            noDataText={this.state.isLoading ? '' : "No Orders Found."}
                            // defaultPageSize={this.state.pageSize}
                            // pageSizeOptions={[10, 25, 50, 100]}
                            // resizable={true}
                            // onPageChange={(index) => this.handlePageChange(index)}
                            // onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                            getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                        />
                    </div>

                    <div className="form-group">
                        <label className="mb-1">Ship Date :</label>
                        <div className="datepicker"  id="datepicker">

                        </div>

                        <DatePicker
                            animation="slide-up"
                            disabled={false}
                            calendar={calendar}
                            value={this.state.ship_date}
                            onChange={this.handleShipDateChange}
                            name="ship_date"
                            getCalendarContainer={() => document.getElementById('datepicker')}
                            className="custom-calendar"
                            showClear={true}
                        >
                            {
                                ({ value }) => {
                                    return (
                                        <div className="input-group">
                                            <input
                                                placeholder="Select Date"
                                                disabled={false}
                                                readOnly
                                                tabIndex="-1"
                                                className="ant-calendar-picker-input ant-input form-control"
                                                value={value == null ? '' : moment(value).format(format)}
                                            />
                                        </div>
                                    );
                                }
                            }
                        </DatePicker>
                    </div>
                    <div className="form-group">
                        <label className="mb-1">Weight (kg) :</label>
                        <input name="weight" readOnly value={this.state.weight} type="number" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label className="mb-1">Service :</label>
                        <select className="custom-select" onChange={this.handleChange} name="provider" value={this.state.provider}>
                            <option value="fast_way">Fast Way</option>
                            {/* <option value="au_post">Australia Post</option> */}
                        </select>
                    </div>

                    <div className="form-group">
                        <label className="mb-1">Type :</label>
                        <select className="custom-select" onChange={this.handleChange} name="type" value={this.state.type}>
                            <option value="parcel">Parcel</option>
                            <option value="satchel">Satchel</option>
                        </select>
                    </div>

                    {
                        this.state.type === 'satchel' && (
                            <div className="form-group">
                                <label className="mb-1">Size :</label>
                                <select className="custom-select" onChange={this.handleChange} name="size" value={this.state.size}>
                                    <option value="A5">A5</option>
                                    <option value="A4">A4</option>
                                    <option value="A3">A3</option>
                                    <option value="A2">A2</option>
                                </select>
                            </div>
                        )
                    }

                    {
                        this.state.type === 'parcel' && (
                            <div className="form-group">
                                <label className="mb-1">Size (cm) :</label>
                                <div className="row">
                                    <div className="col input-group">
                                        <input onChange={this.handleChange} name="length" value={this.state.length} type="number" className="form-control" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">L</span>
                                        </div>
                                    </div>
                                    <div className="col input-group">
                                        <input onChange={this.handleChange} name="width" value={this.state.width} type="number" className="form-control" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">W</span>
                                        </div>
                                    </div>
                                    <div className="col input-group">
                                        <input onChange={this.handleChange} name="height" value={this.state.height} type="number" className="form-control" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">H</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="form-group">
                        <label className="mb-1">Service Type:</label>
                        <select className="custom-select" onChange={this.handleChange} name="provider_type" value={this.state.provider_type}>
                            <option value="standard">Standard</option>
                            {/* <option value="leave_at_door">Leave at door</option> */}
                        </select>
                    </div>
                   
                </div>
                <div className="modal-footer">
                    <button disabled={this.state.isLoading} onClick={this.handleCreateLabel} className="btn btn-secondary" type="button">
                    {
                        this.state.isLoading ? (
                            <div>
                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                Loading...
                            </div>
                        ) : 'Save'
                    }
                    </button>
                    <button onClick={this.onCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                </div>
                </div>
                </div>

            </Modal>
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Awaiting Shipments
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                        <li className="breadcrumb-item active">Awaiting Shipments</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Manage Orders</h5>
                    </div>
                    <div className="card-body order-datatable">
                        
                        {/* <div className="btn-popup pull-right">
                          <Link to="/orders/new">  <button type="button" className="btn btn-secondary">Add Product</button> </Link>
                        </div> */}

                       <div className="table-responsive">
                       <ReactTable
                            // PaginationComponent={Pagination}
                            showPagination={false}
                            data={data}
                            minRows={5}
                            columns={columns}
                            noDataText={this.state.isLoading ? '' : "No Orders Found."}
                            defaultPageSize={this.state.pageSize}
                            pageSizeOptions={[10, 25, 50, 100]}
                            // resizable={true}
                            onPageChange={(index) => this.handlePageChange(index)}
                            onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                            getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                        />
                       </div>

                       
                       <ul className="mt-3">
                            <li role="button" onClick={this.handlePreviousClick} className={`cursor-pointer page-item ${this.state.currentPage == 1 ? 'disabled' : ''}`}><span className="page-link " tabIndex={-1} role="button" aria-disabled="true" aria-label="Previous page" rel="prev">Previous</span></li>
                            {
                                this.state.pages && this.state.pages.map((page, i) => (
                                    <li onClick={this.handlePageClick(page)} key={i} className={`cursor-pointer page-item ${this.state.currentPage === page ? 'active' : ''}`}><span rel="canonical" role="button" className="page-link" aria-current="page">{page}</span></li>
                                ))
                            }
                            {
                                this.state.lastEvaluatedKey && (
                                    <li className="cursor-pointer page-item"><span className="page-link" role="button" >...</span></li>
                                )
                            }
                            <li onClick={this.handleNextClick} className={`cursor-pointer page-item ${!this.state.lastEvaluatedKey ? 'disabled' : ''}`}><span className="page-link" tabIndex={0} role="button" aria-disabled="false" aria-label="Next page" rel="next">Next</span></li>
                        </ul>

                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}

export default AwaitingShipment;