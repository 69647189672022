import React, { Component } from 'react';
import { request } from '../../constants/constants';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Loader from '../../components/loader/loader';
import Pagination from '../../components/pagination/pagination';
import Swal from 'sweetalert2';
import Modal from "react-responsive-modal";
import history from '../../history';

class Catalogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogs: [],
            pageSize: 10,
            currentPage: 1,
            modal: false,
            catalog_name: '',
            currentAction: 'add',
            currentId: undefined
        }
    }

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }
    
    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const catalogs = this.state.catalogs;
                const catalog = catalogs[index];
                catalog.isLoading = true;
                this.setState({
                    catalogs: catalogs
                })
                request('DELETE', `/hq/catalogs/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    catalog.isLoading = false;
                    this.setState({
                        catalogs: catalogs
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    catalog.isLoading = false;
                    this.setState({
                        catalogs: catalogs
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
   
    }

    getAllCatalogs() {
        return request('GET', '/hq/catalogs', {}, true);
    }

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }

    openModal = () => {
        this.setState({
            modal: true
        })
    }

    handleAddCatalog = e => {
        this.onCloseModal();
        this.setState({
            isLoading: true
        })
        const data = {
            catalog_name: this.state.catalog_name
        }
        request('POST', `/hq/catalogs`, data, true).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success!',
                res.data.message,
                'success'
            ).then(() => {
                history.push(`catalogs/${res.data.data.id}`)
            })
        }).catch(err => {
            this.setState({
                isLoading: true
            })
            console.error(err);
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            )
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    openModalAddCatalog = e => {
        this.setState({
            modal: true,
            currentAction: 'add',
            catalog_name: ''
        });
    }


    openModalEditCatalog = catalog => e => {
        this.setState({
            modal: true,
            currentAction: 'edit',
            currentId: catalog.id,
            catalog_name: catalog.catalog_name
        });
    }

    handleUpdateCatalog = e => {
        this.setState({
            isLoading: true,
            modal: false
        })
        const data = {
            catalog_name: this.state.catalog_name
        }
        request('PUT', `/hq/catalogs/${this.state.currentId}`, data, true).then(res => {
            console.log(res);
          
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            );
            this.setState({
                isLoading: false
            })
            this.init();
        }).catch(err => {
            this.setState({
                isLoading: false,
            })
            console.error(err);
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            )
        })
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getAllCatalogs().then(res => {
            console.log(res);
            this.setState({
                isLoading: false,
                catalogs: res.data.data
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
        })
    }

    componentDidMount() {
       this.init();
    }
    
    render() {
        const data = this.state.catalogs;
        const columns = [
            {
                Header: 'Name',
                Cell: row => {
                    return (
                    <div className="match">
                        <h5 className="highlight">{row.original.catalog_name}</h5>
                        <div className="actions">
                            <ul className="list-inline">
                                <li>
                                   <Link to={`/catalogs/${row.original.id}/details`} className="btn-link btn font-primary p-0 pr-2">Edit</Link>
                                </li>
                                <li><span className="seprator">|</span></li>
                                <li>
                                    <Link to={`/catalogs/${row.original.id}`}><button className="btn-link btn font-primary p-0 pl-2">View Items</button></Link>
                                </li>
                                {/* <li><span className="seprator">|</span></li> */}
                                {/* <li> 
                                    <button onClick={() => this.handleDelete(row.original.id)} className="link">Delete</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    )
                }
            },
            {
                Header: 'Supplier',
                // accessor: 'store_code'

                Cell: row => {
                    return row.original.item_supplier ? <span>{row.original.item_supplier.supplier_name}</span> : ''
                },
            },
            {
                Header: 'Action',
                Cell: row => (
                    <React.Fragment>
                    <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
                    {
                        row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
                    }
                    </button>
                    </React.Fragment>
                ),
            },
        ];
        return (
            <div className="page-body">
            <Modal
                open={this.state.modal} 
                onClose={this.onCloseModal}
                center={true}
                classNames={{
                overlay: 'custom-overlay',
                modal: 'custom-modal'
            }}
        >
                <div className="">
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel">{this.state.currentAction == 'add' ? 'Add' : 'Edit'} Catalog</h5>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label className="mb-1">Catalog Name :</label>
                        <input onChange={this.handleChange} name="catalog_name" value={this.state.catalog_name} type="text" className="form-control" />
                    </div>
                </div>
                <div className="modal-footer">
                    {
                        this.state.currentAction  == 'add' ? <button onClick={this.handleAddCatalog} className="btn btn-secondary" type="button">ADD</button> : (
                            <button onClick={this.handleUpdateCatalog} className="btn btn-secondary" type="button">SAVE</button>
                        )
                    }
                    <button onClick={this.onCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                </div>
                </div>
            </Modal>
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Catalogs
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                        <li className="breadcrumb-item active">Catalogs</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Manage Catalogs</h5>
                    </div>
                    <div className="card-body order-datatable">
                        <div className="btn-popup pull-right">
                          {/* <button onClick={this.openModalAddCatalog} type="button" className="btn btn-secondary">Add Catalog</button> */}
                          <Link to="/catalogs/new" className="btn btn-secondary">Add Catalog</Link>
                        </div>
                        <div className="table-responsive">
                           {
                               this.state.isLoading ? <Loader /> : (
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Catalogs Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    // resizable={true}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                 />
                               )
                           }
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}

export default Catalogs;