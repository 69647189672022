import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader/loader';
import { request } from '../../../constants/constants';
import StoreSvg from '../../../public/svg/stores.svg';
import Swal from 'sweetalert2';
import './shipmentDetails.scss';
import moment from 'moment';
import PersonCarryIcon from '../../../public/svg/person-carry';
import DotIcon from '../../../public/svg/dot';

class ShipmentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            shipment: null
        }
    }

    getShipment() {
        const { match: { params } } = this.props; 
        return request('GET', `/hq/shipments/${params.shipmentId}`, {}, true)
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getShipment().then(res => {
            this.setState({
                isLoading: false,
                shipment: res.data.data
            })
            console.log(res);
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }

    componentDidMount() {
        this.init();
    }

    render() {
        return (
            <div className="page-body">
                {this.state.isLoading && <Loader />}
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3>Shipment for Order #{this.state.shipment && this.state.shipment.order.order_number}
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to="/shipments">Shipments</Link></li>
                                    <li className="breadcrumb-item active">#{this.state.shipment && this.state.shipment.id}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5> General </h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Type</label>
                                        <p>{this.state.shipment && this.state.shipment.shipment_type === 'full' ? 'Full Shipment' : 'Partial Shipment'}</p>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Customer</label>
                                        <p className="m-0 p-0">
                                            {this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.to && this.state.shipment.fast_way.to.contactName}
                                        </p>
                                        <p className="m-0 p-0">
                                            {this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.to && (`${this.state.shipment.fast_way.to.address.streetAddress} ${this.state.shipment.fast_way.to.address.locality} ${this.state.shipment.fast_way.to.address.stateOrProvince} ${this.state.shipment.fast_way.to.address.postalCode}`)}
                                        </p>
                                        <p className="m-0 p-0">
                                            Phone: {this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.to && this.state.shipment.fast_way.to.phoneNumber}
                                        </p>
                                        <p className="m-0 p-0">
                                            Email: {this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.to && this.state.shipment.fast_way.to.email}
                                        </p>
                                    </div>

                                    {/* <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Store</label>
                                        <Link to={`/stores/${this.state.order && this.state.order.store.id}/detail`}>
                                            <p>{this.state.order && this.state.order.store.store_name}</p>
                                        </Link>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5> Shipping Provider </h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <p className="mb-0">{this.state.shipment && this.state.shipment.ship_provider  === 'fast_way' ? 'Fast Way' : ''}</p>
                                        <p className="mb-0">{this.state.shipment && this.state.shipment.ship_provider_type === 'standard' ? 'Standard' : ''}</p>
                                    </div>
                                    <div className="form-group mb-4">
                                        <a className='btn btn-primary'  download="Labels.pdf" href={this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.labels_pdf}>View Labels</a>
                                    </div>
                                    <div className="form-group mb-4">
                                        <p className="mb-0">Weight: {this.state.shipment && this.state.shipment.height}kg</p>
                                        <p className="mb-0">Length: {this.state.shipment && this.state.shipment.length}cm</p>
                                        <p className="mb-0">Width: {this.state.shipment && this.state.shipment.height}cm</p>
                                        <p className="mb-0">Height: {this.state.shipment && this.state.shipment.height}cm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5> Summary </h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <p className="mb-0">{this.state.order && this.state.order.first_name} {this.state.order && this.state.order.last_name}</p>
                                        <p className="mb-0">{this.state.order && this.state.order.address_line_1}</p>
                                        <p className="mb-0">{this.state.order && this.state.order.address_line_2} {this.state.order && this.state.order.suburb} {this.state.order && this.state.order.state} {this.state.order && this.state.order.country} {this.state.order && this.state.order.postal_code}</p>
                                    </div>

                                    <div className="form-group mb-4">
                                        {/* <label className="col-form-label pt-0">Summary</label> */}
                                        <p className="mb-0">Price: ${this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.price}</p>
                                        <p className="mb-0">GST: ${this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.tax}</p>
                                        <p className="mb-0 font-weight-bold">Total: ${this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.total}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Order Item(s) </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-borderless border">
                                                <thead className="bg-light text-dark">
                                                    <tr>
                                                        <th colSpan="2" scope="col">Item</th>
                                                        {/* <th scope="col">Cost</th> */}
                                                        <th scope="col">Qty</th>
                                                        {/* <th scope="col">Total</th> */}
                                                        {/* <th scope="col">GST</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {
                                                        this.state.shipment && this.state.shipment.shipment_items.map((item, i) => (
                                                            <tr key={i} className="border-bottom">
                                                                <th scope="row" style={{ width: 50 }}>
                                                                    <img width="50" height="50" src={item.order_item.product.image[0]} />
                                                                </th>
                                                                <td>
                                                                    {item.order_item.product.product_name}
                                                                    {item.order_item.size && <p className="m-0">Size: {item.order_item.size}</p>}
                                                                    {item.order_item.colour && <p>Colour: {item.order_item.colour.name}</p>}
                                                                </td>
                                                                {/* <td>
                                                                    $ {
                                                                        item.order_item.product.sale_type == 'amount' ? (
                                                                            item.order_item.product.online_price - item.order_item.product.sale
                                                                        ) : item.order_item.product.sale_type == 'percent_off' ? (
                                                                            item.order_item.product.online_price - ((item.order_item.product.sale / 100) * item.order_item.product.online_price)
                                                                        ) : (
                                                                            item.order_item.product.online_price
                                                                        )
                                                                    }
                                                                </td> */}
                                                                <td>x {item.order_item.item_quantity}</td>
                                                                {/* <td>${item.order_item.total}</td> */}
                                                                {/* <td>${item.order_item.gst}</td> */}
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>

                                            </table>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Label </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-borderless border">
                                                <thead className="bg-light text-dark">
                                                    <tr>
                                                        <th>Label</th>
                                                        {/* <th scope="col">Cost</th> */}
                                                        <th >Description</th>
                                                        <th>Total</th>
                                                        {/* <th scope="col">GST</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {
                                                        this.state.shipment &&  this.state.shipment.fast_way && this.state.shipment.fast_way.items && this.state.shipment.fast_way.items.map((item, i) => (
                                                            <tr key={i} className="border-bottom">
                                                                <th>
                                                                    {
                                                                        item.label
                                                                    }
                                                                </th>
                                                                <td>
                                                                    {`${item.packageType === 'P' && 'Parcel'} of ${item.weightDead}kg dead, ${item.weightCubic}kg cubic (${item.width}cm x ${item.height}cm x ${item.length}cm)`}
                                                                </td>
                                                                <td>
                                                                    ${item.total}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>

                                            </table>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>                           
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Tracking</h5>
                                    </div>
                                    <div className="card-body py-0">
                                        <div id="tracking-pre" />
                                        <div id="tracking">
                                            <div className="tracking-list">

                                                {this.state.shipment && this.state.shipment.fast_way && this.state.shipment.fast_way.tracking && this.state.shipment.fast_way.tracking.sort((a, b) => a.scannedDateTime - b.scannedDateTime).map((trackingItem, i) => {
                                                    return (
                                                        <div key={i} className="tracking-item">
                                                            <div className="tracking-icon status-intransit bg-primary">
                                                                {
                                                                    <DotIcon/>
                                                                }
                                                                {/* <svg className="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                                                                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
                                                                </svg> */}
                                                                {/* <i class="fas fa-circle"></i> */}
                                                            </div>
                                                            <div className="tracking-date">
                                                                {moment(trackingItem.scannedDateTime).format('MMM DD, YYYY')}
                                                                <span>{moment(trackingItem.scannedDateTime).format("hh:mm a")}</span>
                                                            </div>
                                                            <div className="tracking-content">{trackingItem.description}
                                                                <span>{trackingItem.franchiseName}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default ShipmentDetails;