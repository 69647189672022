import React, { Component } from 'react';
import Loader from '../../../components/loader/loader';
import { request } from '../../../constants/constants';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import history from '../../../history';
import Modal from 'react-responsive-modal';
import moment from 'moment';


class PurchaseOrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addNoteModal: false,
            statusSelected: null,
            tracking_name: '',
            tracking_number: '',
            tracking_link: '',
            purchase_order_items: [],
            statusOptions: [
                {
                    label: 'Pending',
                    value: 'pending',
                },
                {
                    label: 'Ordered',
                    value: 'ordered',
                }, 
                {
                    label: 'Confirmed',
                    value: 'on_hold',
                },
                {
                    label: 'In Transit',
                    value: 'completed',
                }, 
                {
                    label: 'In Production',
                    value: 'shipped',
                },
                {
                    label: 'Partially Received',
                    value: 'partially_received',
                },
                {
                    label: 'Received',
                    value: 'received',
                },
            ]
        }
    }

    handleSave = e => {
        this.setState({
            isLoading: true
        })
        const data = {
            status: this.state.statusSelected.value,
            tracking_name: this.state.tracking_name,
            tracking_number: this.state.tracking_number,
            tracking_link: this.state.tracking_link,
        }
        request('PUT', `/hq/purchase-orders/${this.props.match.params.purchaseOrderId}`, data, true)
        .then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
            history.push('/purchase-orders');
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleStatusChange = status => {
        this.setState({
            statusSelected: status
        })
    }

    handleChangeCheckbox = i => e => {
        const purchaseOrderItems = this.state.purchase_order_items;
        const purchaseOrderItem = purchaseOrderItems[i];
        purchaseOrderItem.isChecked = e.target.checked;
        this.setState({
            purchase_order_items: purchaseOrderItems
        })
    }

    handleQuantityChange = i => e => {
        const purchaseOrderItems = this.state.purchase_order_items;
        const purchaseOrderItem = purchaseOrderItems[i];
        purchaseOrderItem.item_quantity_received = e.target.value;
        this.setState({
            purchase_order_items: purchaseOrderItems
        })
    }

    handleReceiveGoods = e => {
        this.setState({
            receiveGoodsIsLoading: true,
        })
        const selectedItems = this.state.purchase_order_items.filter(item => item.isChecked)
        .map(item => {
            return {
                id: item.id,
                item_quantity_received: item.item_quantity_received
            }
        })

        if (selectedItems.length <= 0) {
            this.setState({
                receiveGoodsIsLoading: false
            })
            return Swal.fire(
                'Error',
                'Please select item(s)',
                'error'
            );
        }

        const data = {
            items: selectedItems
        }

        request('PUT', `/hq/purchase-orders/${this.props.match.params.purchaseOrderId}`, data, true)
        .then(res => {
            console.log(res);
            this.setState({
                receiveGoodsIsLoading: false
            })
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
            this.init();
        }).catch(err => {
            console.error(err);
            this.setState({
                receiveGoodsIsLoading: false
            })
            Swal.fire(
                'Error',
                'There was something wrong',
                'error'
            );
        })
    
    }

    onCloseaddNoteModal = e => {
        this.setState({
            addNoteModal: false
        })
    }

    handleAddNote = e => {
        this.setState({
            addNoteModal: true
        })
    }

    

    addNote = e => {
        const data = {
            notes: this.state.notes,
            title: this.state.title
        }

        this.setState({
            isAddNoteLoading: true
        })

       request('POST', `/hq/purchase-orders/${this.props.match.params.purchaseOrderId}/notes`, data, true)
       .then(res => {
            console.log(res);
            this.setState({
                isAddNoteLoading: false,
                addNoteModal: false
            })
            this.init();
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            );
       }).catch(err => {
           console.error(err);
           this.setState({
                isAddNoteLoading: false,
                addNoteModal: false
            })
           Swal.fire(
               'Error',
               err.response.data.message,
                'error'
           )
       })

    }

    getProductPriceBySizeQuantity(sizes, size, quantity) {
        const index = sizes.findIndex(element => element.size == size);
        size = sizes[index];

        for (let pricing of size.pricings) {
            if (pricing.startQuantity <= quantity && pricing.endQuantity >= quantity) {
                return pricing.cost;
            }
        }
    }

    getDesignPriceByQuantity(pricings, quantity) {

        for (let pricing of pricings) {
            if (pricing.startQuantity <= quantity && pricing.endQuantity >= quantity) {
                return pricing.cost;
            }
        }
    }

    formatNumber(number) {
        return Number(number).toFixed(2);
    }

    getPurchaseOrderDetails(lineItemsOffset) {
        return request('GET', `/hq/purchase-orders/${this.props.match.params.purchaseOrderId}${lineItemsOffset ? `?line_items_offset=${lineItemsOffset}` : ''}`, {}, true);
    }

    showCustomItemsModal = items => e => {
        this.setState({
            customItems: items,
            customItemsModal: true
        })
    }

    oncustomItemsModalCloseModal = () => {
        this.setState({
            customItemsModal: false
        })
    }
    getCustomFieldsTotalPrice(customFields){
        let price = 0 ;
        if(customFields) {
            for (let customField of customFields) {
                console.log('customField: ', customField);
                if(customField.additionalPriceChecked && customField.value && customField.value !== '') {
                    console.log('customField.price: ', customField.price);
                    price += customField.price
                }
            }
        }
        return price;
    }

    getCustomItemsCustomFieldsTotalPrice(customItems) {
        let price = 0;
        if(customItems) {
            for (let customItem of customItems) {
                price += this.getCustomFieldsTotalPrice(customItem.custom_fields)
            }
        }

        return price;
    }

    getCustomItemsTotalQuantity(customItems) {
        let qty = 0
        if(customItems) {
            for (let customItem of customItems) {
                qty += customItem.item_quantity;
            }
        }
        return qty;
    }

    handleLoadMoreLineItems = e => {
        this.setState({
            isLoading: true
        })
        const lineItemsOffset = this.state.purchase_order_items.length;
        
        this.getPurchaseOrderDetails(lineItemsOffset).then(res => {
            console.log(res);
            const purchaseOrder = res.data.data;
            this.setState({
                isLoading: false,
                purchase_order_items: [...this.state.purchase_order_items, ...purchaseOrder.purchase_order_items],
                has_more_purchase_order_items: purchaseOrder.has_more_purchase_order_items
            
            })
        }).catch(err => {
            console.error(err);
            console.error(err.name);
            this.setState({
                isLoading: false,
            })
        })
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getPurchaseOrderDetails().then(res => {
            console.log(res);
            const purchaseOrder = res.data.data;
            const index = this.state.statusOptions.findIndex(status => status.value == purchaseOrder.status);

            this.setState({
                isLoading: false,
                ...purchaseOrder,
                statusSelected: {
                    label: this.state.statusOptions[index].label,
                    value: purchaseOrder.status
                },
            })
        }).catch(err => {
            console.error(err);
            console.error(err.name);
            this.setState({
                isLoading: false,
            })
        })
    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        return (
          <div className="page-body">
            {
                this.state.isLoading && <Loader />
            }

            
                <Modal
                   open={this.state.customItemsModal} 
                   onClose={this.oncustomItemsModalCloseModal}
                   center={true}
                   classNames={{
                    overlay: 'custom-overlay',
                    modal: 'custom-modal'
                   }}
                >
                        <div className="">
                        <div className="modal-header">
                            <h5 className="modal-title f-w-600" id="exampleModalLabel">Custom Items</h5>
                        </div>
                        <div className="modal-body">
                           <table className="table">
                               <thead>
                                   <tr>
                                       <th>Item</th>
                                       <th>Cost</th>
                                       <th>Qty</th>
                                       <th>Toal</th>
                                       {/* <th>Total</th> */}
                                   </tr>
                               </thead>
                               <tbody>
                                  {
                                      this.state.customItems && this.state.customItems.map(customItem => (
                                          <tr>
                                              <td>
                                                  {
                                                    customItem.custom_fields.map(customField => (
                                                        <p className="m-0">{customField.name} :  {customField.value}</p>
                                                    ))
                                                  }
                                              </td>
                                              
                                              <td>
                                                  <p>{this.formatNumber(this.getCustomFieldsTotalPrice(customItem.custom_fields))}</p>
                                              </td>

                                              <td>
                                                  <p>{customItem.item_quantity}</p>
                                              </td>

                                              <td>
                                                  <p>{this.formatNumber(this.getCustomFieldsTotalPrice(customItem.custom_fields) * customItem.item_quantity)}</p>
                                              </td>
                                          </tr>
                                      ))
                                  }
                               </tbody>
                           </table>
                        </div>
                        <div className="modal-footer">
                            <button onClick={this.oncustomItemsModalCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                        </div>
                        </div>
            </Modal>

            <Modal
                    open={this.state.addNoteModal} 
                    onClose={this.onCloseaddNoteModal}
                    center={true}
                    classNames={{
                        overlay: 'custom-overlay',
                        modal: 'custom-modal'
                    }}
                    >
                    <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="exampleModalLabel">Add Notes</h5>
                    </div>
                    <div className="modal-body">
                        <div>
                            <input onChange={this.handleChange} name="title" value={this.state.value} placeholder="Title" type="text" className="form-control mb-2" />
                            <textarea onChange={this.handleChange} name="notes" value={this.state.notes} placeholder="Enter your notes here" className="form-control" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={this.addNote} disabled={this.state.isAddNoteLoading} className="btn btn-secondary" type="button">
                        {
                            this.state.isAddNoteLoading ? (
                                <div>
                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                    Loading...
                                </div>
                            ) : 'ADD'
                        }
                        </button>
                        <button onClick={this.onCloseaddNoteModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                    </div>
                    </div>
            </Modal>
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="page-header-left">
                                <h3> Purchase Order #{this.state.purchase_order_number}
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ol className="breadcrumb pull-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item"><Link to="/purchase-orders">Purchase Orders</Link></li>
                                <li className="breadcrumb-item active">Purchase Order Details</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card">
                        <div className="card-header">
                            <h5> {this.state.order_item && this.state.order_item.product.product_name} {this.state.order_item && this.state.order_item.size && " - " + this.state.order_item.size} </h5>
                        </div>
                        <div className="card-body">
                           <div className="row justify-content-between">
                            <div className="col-xl-4">
                                    <div className="form-group mb-4 row">
                                    <label  className="col-4 pt-0 d-inline">Status: </label>
                                    <div className="col">
                                    <Select
                                        value={this.state.statusSelected}
                                        onChange={this.handleStatusChange}
                                        options={this.state.statusOptions}
                                    />
                                    </div>
                                    </div>
                                   
                                   {/* {
                                       this.state.item_supplier && (
                                        <React.Fragment>
                                            <div className="form-group row mb-4">
                                            <label  className="col-4 pt-0 d-inline">Item: </label>
                                            <p className="col">
                                                {this.state.order_item && this.state.order_item.product.product_name}
                                            </p>
                                            </div>
                                            {
                                                this.state.order_item && this.state.order_item.size && (
                                                    <div className="form-group row mb-4">
                                                    <label  className="col-4 pt-0 d-inline">Size: </label>
                                                    <p className="col">
                                                        {this.state.order_item.size}
                                                    </p>
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                       )
                                   } */}

{/* 
                                   {
                                       this.state.decoration_supplier && (
                                        <React.Fragment>
                                            <div className="form-group row mb-4">
                                            <label  className="col-4 pt-0 d-inline">Design: </label>
                                            <p className="col">
                                                {this.state.design && this.state.design.design_name}
                                            </p>
                                            </div>
                                        </React.Fragment>
                                       )

                                   } */}

                                    <div className="form-group row mb-4">
                                        <label  className="col-4 pt-0 d-inline">Item Supplier: </label>
                                        <p className="col">
                                            {this.state.item_supplier && this.state.item_supplier.supplier_name}
                                        </p>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <label  className="col-4 pt-0 d-inline">Decoration Supplier: </label>
                                        <p className="col">
                                            {this.state.decoration_supplier && this.state.decoration_supplier.decoration_name}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-group row mb-4">
                                        <label  className="col-4 pt-0 d-inline">Provider Name: </label>
                                        <div className="col">
                                            <input name="tracking_name" value={this.state.tracking_name || ''} onChange={this.handleChange} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label  className="col-4 pt-0 d-inline">Tracking Number: </label>
                                        <div className="col">
                                            <input name="tracking_number" value={this.state.tracking_number || ''} onChange={this.handleChange} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label  className="col-4 pt-0 d-inline">Tracking Link: </label>
                                        <div className="col">
                                            <input name="tracking_link" value={this.state.tracking_link || ''} onChange={this.handleChange} type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                           </div>
                        </div>
                        </div>

                            <div className="card">
                                <div className="card-header">
                                    <h5>Items</h5>
                                </div>
                                <div className="card-body">
                                   <div className="actions mb-4">
                                        <button disabled={this.state.receiveGoodsIsLoading} onClick={this.handleReceiveGoods} className="btn btn-primary">
                                            {
                                                this.state.receiveGoodsIsLoading ? (
                                                    <div>
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                        Loading...
                                                    </div>
                                                ) : 'Receive Goods'
                                            }
                                        </button>
                                   </div>


                                    <table className="table table-borderless border">
                                        <thead className="bg-light text-dark">
                                            <tr>
                                                <th colSpan="3" scope="col">Item</th>
                                                <th scope="col">Received</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Cost</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {
                                                this.state.purchase_order_items && this.state.purchase_order_items.map((item, i) => (
                                                        item.design_id ? (
                                                            <tr key={i} className="border-bottom">
                                                                <td style={{width: 20}}>
                                                                    <input checked={item.isChecked} onChange={this.handleChangeCheckbox(i)} type="checkbox" aria-label="Checkbox for following text input" />
                                                                </td> 
                                                                <td colSpan="2">
                                                                    <img width="50" height="50" src={item.product.item.item_image} />
                                                                    {`${item.product.product_name} - ${item.product.design.design_name}`}
                                                                </td>
                                                                <td>
                                                                    <input type="number" onChange={this.handleQuantityChange(i)} value={item.item_quantity_received} style={{width: 100}} className="form-control" />
                                                                </td>
                                                                <td>
                                                                    <span className={`badge badge-${item.status == 'received' ? 'success' : 'info'}`}>{item.status}</span>
                                                                </td>
                                                                <td>${this.formatNumber(this.getDesignPriceByQuantity(item.product.design.pricings, item.item_quantity))}</td>
                                                                <td>x {item.item_quantity}</td>
                                                                <td>${this.formatNumber(item.item_quantity * this.getDesignPriceByQuantity(item.product.design.pricings, item.item_quantity))}</td>
                                                            </tr>
                                                        ) : (
                                                            <tr key={i} className="border-bottom">
                                                                <td style={{width: 20}}>
                                                                    <input checked={item.isChecked} onChange={this.handleChangeCheckbox(i)} type="checkbox" aria-label="Checkbox for following text input" />
                                                                </td>    
                                                                <th scope="row" style={{ width: 50 }}>
                                                                    <img width="50" height="50" src={item.product.item.item_image} />
                                                                </th>
                                                                <td>
                                                                    {item.product.product_name} { item.type === 'custom_field' && '- Custom Data'}
                                                                    {item.size && <p className="m-0">Size: {item.size}</p>}
                                                                    {item.colour && <p className="m-0">Colour: {item.colour.name}</p>}
                                                                    {
                                                                        item.custom_fields && item.custom_fields.map((customField, i) => (
                                                                        <p className="mb-0" key={i}>{customField.name}: {customField.value}</p>
                                                                        ))
                                                                    }
                                                                    {
                                                                        item.custom_items && (
                                                                            <button onClick={this.showCustomItemsModal(item.custom_items)} type="button" className="btn btn-info btn-sm">
                                                                              Show custom items <span class="badge badge-light">{item.custom_items.length}</span>
                                                                            </button>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <input type="number" onChange={this.handleQuantityChange(i)} value={item.item_quantity_received} style={{width: 100}} className="form-control" />
                                                                </td>
                                                                <td>
                                                                    <span className={`badge badge-${item.status == 'received' ? 'success' : 'info'}`}>{item.status}</span>
                                                                </td>
                                                                <td>
                                                                    
                                                                    
                                                                    {
                                                                        item.type === 'custom_field' ? (
                                                                            <button onClick={this.showCustomItemsModal(item.custom_items)} type="button" className="btn btn-info btn-sm">
                                                                              Show custom items <span class="badge badge-light">{item.custom_items.length}</span>
                                                                            </button>
                                                                        ) : (
                                                                           '$' + item.product.item.item_sizes && item.product.item.item_sizes.length !== 0 && this.formatNumber(this.getProductPriceBySizeQuantity(item.product.item.item_sizes, item.size, item.item_quantity))
                                                                        )
                                                                    }
                                                     
                                                                </td>
                                                                <td>
                                                                    x {
                                                                        item.type === 'custom_field' ? (
                                                                            this.getCustomItemsTotalQuantity(item.custom_items)
                                                                        ) : (
                                                                            item.item_quantity
                                                                        )
                                                                    }
                                                                </td>
                                                                <td>
                                                                    $
                                                                    {
                                                                        item.type === 'custom_field' ? (
                                                                            this.formatNumber(this.getCustomItemsCustomFieldsTotalPrice(item.custom_items))
                                                                        ): (
                                                                            item.product.item.item_sizes && item.product.item.item_sizes.length !== 0 && this.formatNumber(item.item_quantity * this.getProductPriceBySizeQuantity(item.product.item.item_sizes, item.size, item.item_quantity))
                                                                        )
                                                                    }
                                                                    
                                                                </td>
                                                            </tr>
                                                        )
                                                ))
                                            }

                                            {/* {
                                               this.state.decoration_supplier && this.state.purchase_order_items && this.state.purchase_order_items.map((item, i) => (
                                                    <tr key={i} className="border-bottom">
                                                        <td style={{width: 20}}>
                                                            <input checked={item.isChecked} onChange={this.handleChangeCheckbox(i)} type="checkbox" aria-label="Checkbox for following text input" />
                                                        </td>  
                                                        <td>
                                                            {item.product.design.design_name}
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            <input type="number" onChange={this.handleQuantityChange(i)} value={item.item_quantity_received} style={{width: 100}} className="form-control" />
                                                        </td>
                                                        <td>
                                                            <span className={`badge badge-${item.status == 'received' ? 'success' : 'info'}`}>{item.status}</span>
                                                        </td>
                                                        <td>${this.formatNumber(this.getDesignPriceByQuantity(item.product.design.pricings, item.item_quantity))}</td>
                                                        <td>x {item.item_quantity}</td>
                                                        <td>${this.formatNumber(item.item_quantity * this.getDesignPriceByQuantity(item.product.design.pricings, item.item_quantity))}</td>
                                                    </tr>
                                                ))
                                            } */}

                                        </tbody>

                                    </table>
                                    {
                                        this.state.has_more_purchase_order_items && (
                                            <div className="text-center">
                                                <button disabled={this.state.isLoading} onClick={this.handleLoadMoreLineItems} className="btn btn-link">
                                                {
                                                    this.state.isLoading ? (
                                                        <div>
                                                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                            Loading...
                                                        </div>
                                                    ) : 'Load more'
                                                }
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                        <div className="card">
                            <div className="card-header">
                                <h5>Notes</h5>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-end">
                                    <button onClick={this.handleAddNote} className="btn btn-primary mb-4">Add Notes</button>
                                </div>
                                <div className="list-group">
                                    {
                                        this.state.purchase_order_notes && this.state.purchase_order_notes.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((note, i) => (
                                            <div key={i} className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{note.title}</h5>
                                                    <small>{moment(note.date).fromNow()}</small>
                                                </div>
                                                <p className="mb-1">{note.notes}</p>
                                            </div>
                                        ))
                                    }
                                </div>
   
                            </div>
                        </div>
                        <div className="card">
                                <div className="card-header">
                                    <h5>Publish</h5>
                                </div>
                                <div className="card-body">
                                <div className="form-group mb-0">
                                    <div className="product-buttons">
                                        <button onClick={this.handleSave} disabled={this.state.isLoading}  type="submit" className="btn btn-primary">
                                            {
                                                this.state.isLoading ? (
                                                    <div>
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                        Loading...
                                                    </div>
                                                ) : 'Save'
                                            }
                                        </button>
                                    <Link to="/purchase-orders"> <button type="button" className="btn btn-light">Discard</button></Link>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        );
    }
}

export default PurchaseOrderDetails;