import React, { Component } from 'react';
import Loader from '../../../components/loader/loader';
import { request } from '../../../constants/constants';
import Swal from 'sweetalert2';
import { Link, NavLink, Router, Switch, Route } from 'react-router-dom';
import history from '../../../history';
import StoreCategories from './store-categories/storeCategories';
import './storeDetails.scss';
import Modal from 'react-responsive-modal';
import StoreProducts from './store-products/storeProducts';
import PreviewProducts from './preview-products/previewProducts';
import General from './general/general';
import Shipping from './shipping/shipping';

class StoreDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            store_name: '',
            theme_color: '',
            store_logo: '',
            slug: '',
            products: [],
            categories: [],
        }
    }

    getStore() {
        return request('GET', `/hq/stores/${this.props.match.params.storeId}`, {}, true);
    }

    init = () => {
        this.setState({
            isLoading: true
        })
        this.getStore().then(res => {
            console.log(res);
            const store = res.data.data;
            this.setState({
                isLoading: false,
                store: res.data.data,
                store_name: store.store_name,
                store_logo: store.store_logo,
                store_code: store.store_code,
                theme_color: store.theme_color,
                slug: store.slug,
                products: store.products,
                categories: store.categories,
                flat_rate: store.flat_rate,
                local_pickup: store.local_pickup,
                local_pickup_locations: store.local_pickup_locations
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            ).then(() => {
                history.push('/stores');
            })
        })
    }

    componentDidMount() {
        this.init()
    }

    render() {
        return (
            <div className="page-body page-store-details">
                {this.state.isLoading && <Loader />}

                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3> {this.state.store && this.state.store.store_name}
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to="/stores">Stores</Link></li>
                                    <li className="breadcrumb-item active">{this.state.store && this.state.store.store_name}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="card tab2-card">
                        <div className="card-body">
                            <ul className="nav nav-tabs tab-coupon" id="myTab" role="tablist">
                                <li className="nav-item"><NavLink  to={`/stores/${this.props.match.params.storeId}/detail`} exact activeClassName="active" className="nav-link" >General</NavLink></li>
                                <li className="nav-item"><NavLink  to={`/stores/${this.props.match.params.storeId}/detail/shipping`} activeClassName="active" className="nav-link" >Shipping</NavLink></li>
                                <li className="nav-item"><NavLink  to={`/stores/${this.props.match.params.storeId}/detail/categories`} activeClassName="active" className="nav-link" >Categories</NavLink></li>
                                <li className="nav-item"><NavLink to={`/stores/${this.props.match.params.storeId}/detail/products`} className="nav-link" >Products</NavLink></li>
                            </ul>
                            <div className="row justify-content-center">
                                {/* <div className="col-xl-3 col-lg-4 border-right border-left">
                                    <ul className="nav nav-vertical d-flex justify-content-center flex-column" id="filterNav">
                                        <li className="nav-item text-center mb-3">
                                            <img src={this.state.store && this.state.store.store_logo} style={{ width: '100%' }} />
                                        </li>
                                        <li className="nav-item collapsed mb-2 text-center" data-toggle="collapse" data-target="#stores-submenu" aria-expanded="false">
                                            <NavLink activeClassName="active" to={`/stores/${this.props.match.params.storeId}/detail/categories`}>Categories</NavLink>
                                        </li>
                                        <li className="nav-item text-center">
                                            <NavLink to={`/stores/${this.props.match.params.storeId}/detail/products`}>Products</NavLink>
                                        </li>

                                    </ul>
                                </div> */}
                                <div className="col">
                                    {
                                        !this.state.isLoading && (
                                        <Router history={history} >
                                            <Switch>
                                                <Route exact path={`/stores/:storeId/detail`}  render={ (props) => <General {...props} refresh={this.init} store={this.state} refresh={this.init}/>} />
                                                <Route exact path={`/stores/:storeId/detail/shipping`}  render={ (props) => <Shipping {...props} refresh={this.init} store={this.state} refresh={this.init}/>} />
                                                <Route path={`/stores/:storeId/detail/categories`}  render={ (props) => <StoreCategories {...props} refresh={this.init} />} />
                                                <Route path={`/stores/:storeId/detail/products`}  component={StoreProducts} />
                                            </Switch>
                                        </Router>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card preview">
                        <div className="card-header">
                            <h5>Preview</h5>
                        </div>
                        <div className="card-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-3 border-right">
                                        <form className="mb-10 mb-md-0">
                                            <ul className="nav nav-vertical" id="filterNav">
                                                <li className="nav-item">
                                                    <img style={{ width: 200 }} src={this.state.store_logo} />
                                                </li>

                                                <li className="nav-item">
                                                    <NavLink exact activeClassName="active" style={{color: this.state.theme_color}} to={`${this.props.location.pathname}`} className="nav-link font-size-lg">
                                                        Home
                                                    </NavLink>
                                                </li>

                                                <li className="nav-item">
                                                    <span  className="nav-link font-size-lg">
                                                        Products
                                                    </span>
                                                </li>

                                                <li className="nav-item mt-5">
                                                    <a className="nav-link dropdown-toggle font-size-lg text-reset border-bottom mb-6 collapsed" data-toggle="collapse" href="#categoryCollapse" aria-expanded="false">
                                                        Category
                                                    </a>
                                                    <div className="collapse show" id="categoryCollapse" style={{}}>
                                                        <div className="form-group">
                                                            <ul className="list-styled mb-0" id="productsNav">
                                                                <li className="list-styled-item">
                                                                    <span  className="list-styled-link">
                                                                        All Products
                                                                    </span>
                                                                </li>
                                                                {this.state.categories && this.state.categories.map((category, i) => (
                                                                    <li key={i} className="list-styled-item">
                                                                        <span  className="list-styled-link">
                                                                            {category.category_name}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-9">
                                        
                                        <div className="col-12 col-md mb-4">
                                                {/* Heading */}
                                                <h3 className="mb-1" style={{color: this.state.theme_color}}>{this.state.store_name}</h3>
                                                {/* Breadcrumb */}
                                                <ol className="breadcrumb mb-md-0 font-size-xs">
                                                {/* <li className="breadcrumb-item">
                                                <Link className="text-gray-400" to="/home">Home</Link>
                                                </li> */}
                                                <li className="breadcrumb-item">
                                                <Link className="text-gray-400" to="/">Shop</Link>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    {this.state.store_name}
                                                </li>
                                                </ol>
                                        </div>                              

                                        <div className="row align-items-center mb-7">

                                        </div>

                                        <PreviewProducts products={this.state.store ? this.state.store.products : []} /> 

                                        {/* <Router history={history} >
                                            <Switch>
                                                <Route path={`/stores/:storeId/detail/:subUrl`} exact render={(props) => <PreviewProducts {...props} products={this.state.store ? this.state.store.products : []} /> } />
                                                <Route path={`/stores/:storeId/detail/:subUrl/preview/products`} exact render={(props) => <PreviewProducts {...props} products={this.state.store ? this.state.store.products : []} /> } />
                                                {this.state.categories.map(category =>  <Route key={category.id} exact path={`/stores/:storeId/detail/:subUrl/preview/products/${category.slug}`} render={(props) => <PreviewProducts {...props} products={category.products || []} /> } />)}
                                            </Switch>
                                        </Router> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default StoreDetails;