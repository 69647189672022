import React, { Component } from 'react';
import ReactTable from 'react-table';
import '../../../node_modules/react-table/react-table.css';
import { Link } from 'react-router-dom';
import Pagination from '../../components/pagination/pagination';
import { request } from '../../constants/constants';
import Loader from '../../components/loader/loader';
import Swal from 'sweetalert2';

class Stores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: [
                // { id: 1, name: 'Store 1', code: '123', active: 'Y', total_revenue:50000  },
                // { id: 2, name: 'Store 2', code: '234', total_revenue:50000   },
                // { id: 3, name: 'Store 3', code: 'ABC', total_revenue:50000   },
                // { id: 4, name: 'Store 4', code: '123', total_revenue:50000   },
                // { id: 5, name: 'Store 5', code: 'A21', total_revenue:50000   },
                // { id: 6, name: 'Store 6', code: 'A23', total_revenue:50000   },
                // { id: 1, name: 'Store 1', code: '123', total_revenue:50000   },
                // { id: 2, name: 'Store 2', code: '234', total_revenue:50000   },
                // { id: 3, name: 'Store 3', code: 'ABC', total_revenue:50000   },
                // { id: 4, name: 'Store 4', code: '123', total_revenue:50000   },
                // { id: 5, name: 'Store 5', code: 'A21', total_revenue:50000   },
                // { id: 6, name: 'Store 6', code: 'A23', total_revenue:50000   },
                // { id: 1, name: 'Store 1', code: '123', total_revenue:50000   },
                // { id: 2, name: 'Store 2', code: '234', total_revenue:50000   },
                // { id: 3, name: 'Store 3', code: 'ABC', total_revenue:50000   },
                // { id: 4, name: 'Store 4', code: '123', total_revenue:50000   },
                // { id: 5, name: 'Store 5', code: 'A21', total_revenue:50000   },
                // { id: 6, name: 'Store 6', code: 'A23', total_revenue:50000   },
                // { id: 1, name: 'Store 1', code: '123', total_revenue:50000   },
                // { id: 2, name: 'Store 2', code: '234', total_revenue:50000   },
                // { id: 3, name: 'Store 3', code: 'ABC', total_revenue:50000   },
                // { id: 4, name: 'Store 4', code: '123', total_revenue:50000   },
                // { id: 5, name: 'Store 5', code: 'A21', total_revenue:50000   },
                // { id: 6, name: 'Store 6', code: 'A23', total_revenue:50000   }
            ],
            pageSize: 10,
			currentPage: 1,
        }
    }

    handleActivateStore = (id, index) => e => {
        const stores = this.state.stores;
        const store = stores[index];
        store.activateLoading = true;
        this.setState({
            stores
        })
        request('PUT', `/hq/stores/${id}/activate`, {}, true).then(res => {
            console.log(res);
            store.activateLoading = false;
            this.setState({
                stores
            })
            this.init();
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
        }).catch(err => {
            console.error(err);
            store.activateLoading = false;
            this.setState({
                stores
            })
            this.init();
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }

    handleDeactivateStore = (id, index) => e => {
        const stores = this.state.stores;
        const store = stores[index];
        store.activateLoading = true;
        this.setState({
            stores
        })
        request('PUT', `/hq/stores/${id}/deactivate`, {}, true).then(res => {
            console.log(res);
            store.activateLoading = false;
            this.setState({
                stores
            })
            this.init();
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
        }).catch(err => {
            console.error(err);
            store.activateLoading = false;
            this.setState({
                stores
            })
            this.init();
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    getAllStores() {
        return request('GET', '/hq/stores', {}, true);
    }

    handleDelete = (id, index) => e => {
        console.log(id);
        const stores = this.state.stores;
        const store = stores[index];
        store.isLoading = true;
        this.setState({
            stores
        })
        request('DELETE', `/hq/stores/${id}`, {}, true).then(res => {
            store.isLoading = false;
            this.setState({
                stores
            })
            console.log(res);
            this.init();
        }).catch(err => {
            console.error(err);
            store.isLoading = false;
            this.setState({
                stores
            })
        })
    }
    
    
    init() {
        this.setState({
            isLoading: true
        })
        this.getAllStores().then(res => {
            console.log(res);
            this.setState({
                stores: res.data.data,
                isLoading: false
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init()
    }   
    
    render() {
        const data = this.state.stores;
		const columns = [
            {
                Header: 'Name',
                accessor: 'store_name',
                Cell: row => {
                    return (
                    <div className="match">
                        <h5 className="highlight">{row.original.store_name}</h5>
                        <div className="actions">
                            <ul className="list-inline">
                                <li>
                                    <Link to={`/stores/${row.original.id}/detail`}><button className="btn-link btn font-primary p-0 pr-2">Edit</button></Link>
                                </li>
                                <li><span className="seprator">|</span></li>
                                <li>
                                    <Link to={`/stores/${row.original.id}/detail`}><button className="btn-link btn font-primary p-0 pl-2">View</button></Link>
                                </li>
                        
                            </ul>
                        </div>
                    </div>
                    )
                }
            },
             {
                Header: 'Logo',
                accessor: 'store_name',
                Cell: row => (
                    <img style={{width:100}} src={row.original.store_logo} />
                ),
            },
            {
                Header: 'Code',
                accessor: 'store_code'

                // Cell: row => (
                //     <span>{row.original.code}</span>
                // ),
            },
            {
                Header: 'Total Revenue',
                accessor: 'total_revenue',
                Cell: row => (
                    <span>${row.original.total_revenue}</span>
                )
            },
            {
                Header: 'Action',
                accessor: 'store_name',
                Cell: row => (
                    <React.Fragment>
                    { 
                    row.original.active ? (
                        <button onClick={this.handleDeactivateStore(row.original.id, row.index)} disabled={row.original.activateLoading} className="btn btn-warning mr-2">
                        {
                            row.original.activateLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Deactivate'
                        }
                        </button>
                    ) : <button onClick={this.handleActivateStore(row.original.id, row.index)} disabled={row.original.activateLoading} className="btn btn-success mr-2">
                            {
                                row.original.activateLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Activate'
                            }
                        </button> 
                    }
                    {/* <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
                    {
                        row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
                    }
                    </button> */}
                    </React.Fragment>
                ),
                width: 250
            },
        ];
        return (
           <div className="page-body">
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Stores
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><a href="index.html"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></a></li>
                        <li className="breadcrumb-item active">Stores</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Manage Stores</h5>
                    </div>
                    <div className="card-body order-datatable">
                        <div className="btn-popup pull-right">
                           <Link to="/stores/new"> <button type="button" className="btn btn-secondary">Add Store</button>  </Link>
                        </div>
                        <div className="table-responsive">
                           {
                               this.state.isLoading ? <Loader /> : (
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Stores Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    // resizable={true}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                 />
                               )
                           }
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}

export default Stores;