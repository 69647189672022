import React, { Component } from 'react';
import ReactTable from 'react-table';
import '../../../node_modules/react-table/react-table.css';
import { Link } from 'react-router-dom';
import Pagination from '../../components/pagination/pagination';
import { request } from '../../constants/constants';
import Swal from 'sweetalert2';
import moment from 'moment';
import history from '../../history';
import Loader from '../../components/loader/loader';

class PurchaseOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: [1],
            purchaseOrders: [],
            pageSize: 10,
            currentPage: 1,
            statusOptions: [
                {
                    label: 'Pending Payment',
                    value: 'pending_payment',
                },
                {
                    label: 'Processing',
                    value: 'processing',
                }, 
                {
                    label: 'On Hold',
                    value: 'on_hold',
                },
                {
                    label: 'Completed',
                    value: 'completed',
                }, 
                {
                    label: 'Shipped',
                    value: 'shipped',
                },
                {
                    label: 'Cancelled',
                    value: 'cancelled',
                },
            ]
        }
    }

    handleTrProps(row) {
        return {
            onClick: (e, t) => {
                history.push(`/purchase-orders/${row.original.id}/detail`);
            },
        }
    }

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    getPurchaseOrders(lastEvaluatedKey) {
        return request('GET', `/hq/purchase-orders${lastEvaluatedKey ? `?lastEvaluatedKey=${lastEvaluatedKey}` : ''}`, {}, true);
    }


    handleNextClick = e => {
        const currentPage = this.state.currentPage + 1;

       if(this.state.lastEvaluatedKey && this.state.pages.indexOf(currentPage) <= -1) {
            this.setState({
                isLoading: true
            })
            const pages = this.state.pages;
            console.log('currentPage: ', currentPage);
            if(this.state.pages.indexOf(currentPage) <= -1) {
                pages.push(currentPage);
            }
            this.setState({
                currentPage,
                pages,
            })
            this.getPurchaseOrders(this.state.lastEvaluatedKey.id.S).then(res => {
                console.log(res);
                const newPurchaseOrders= [...this.state.purchaseOrders, ...res.data.data];
                const endOffset = parseInt(currentPage + '0');
                const newItemOffset = endOffset - 10
                this.setState({
                    currentPage,
                    pages,
                    lastEvaluatedKey: res.data.lastEvaluatedKey,
                    isLoading: false,
                    purchaseOrders: newPurchaseOrders,
                    endOffset,
                    itemOffset: newItemOffset
                })
            }).catch(err => {
                console.error(err);
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })
           
       } else {
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                endOffset,
                itemOffset: newItemOffset
            })
       }
    }

    handlePreviousClick = e => {
        if(this.state.currentPage !==1 ) {
            const currentPage = this.state.currentPage - 1;
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                currentPage,
                itemOffset: newItemOffset,
                endOffset: endOffset
            })
        }
    }

    handlePageClick = page => e => {
        const endOffset = parseInt(page + '0');
        const newItemOffset = endOffset - 10

        this.setState({
            currentPage: page,
            itemOffset: newItemOffset,
            endOffset
        })
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const purchaseOrders = this.state.purchaseOrders;
                const product = purchaseOrders[index];
                product.isLoading = true;
                this.setState({
                    purchaseOrders: purchaseOrders
                })
                request('DELETE', `/hq/purchaseOrders/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    product.isLoading = false;
                    this.setState({
                        purchaseOrders: purchaseOrders
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    product.isLoading = false;
                    this.setState({
                        purchaseOrders: purchaseOrders
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
   
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getPurchaseOrders().then(res => {
            console.log(res);
            this.setState({
                purchaseOrders: res.data.data,
                isLoading: false,
                lastEvaluatedKey: res.data.lastEvaluatedKey
            })
        })
    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        // const data = this.state.purchaseOrders.sort((a, b) => b.purchase_order_number - a.purchase_order_number);
		const data = this.state.purchaseOrders && this.state.purchaseOrders.slice(this.state.itemOffset, this.state.endOffset);
        const columns = [
            {
                Header: 'Item Name',
                Cell: row => {
                    return (
                    <div className="match">
                        <span className="highlight">PO #{row.original.purchase_order_number}</span>
                    </div>
                    )
                } 
            },
            // {
            //     Header: 'Store Name',
            //     Cell: row => {
            //         return <span>{row.original.store.store_name}</span>
            //     }
            // },
            {
                Header: 'Status',
                Cell: row => (
                <span className={`text-white p-2 badge badge-${ row.original.status == 'pending' ? 'warning' : row.original.status == 'ordered' ? 'info' : row.original.status == 'confirmed' ? 'info' : row.original.status == 'received' ? 'success' :'info' }` }>{row.original.status}</span>
                ),
                width: 110
            }, 
            {
                Header: 'Order Date',
                Cell: row => (
                    <span>{moment(row.original.createdAt).format('MMMM DD, YYYY')}</span>
                ),
            },
            // {
            //     Header: 'QTY',
            //     Cell: row => <span>{row.original.quantity}</span>,
            //     width: 80,
            // },
            {
                Header: 'Supplier',
                Cell: row => <span>{row.original.item_supplier && row.original.item_supplier.supplier_name}</span>
            },
            {
                Header: 'Decoration Supplier',
                Cell: row => <span>{row.original.decoration_supplier && row.original.decoration_supplier.decoration_name}</span>
            },
            {
                Header: 'Tracking',
                Cell: row => <a target="_blank" href={row.original.tracking_link}>{row.original.tracking_number}</a>
            },
           
        ];
        return (
           <div className="page-body">
            {
                this.state.isLoading && <Loader />
            }
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Purchase Orders
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                        <li className="breadcrumb-item active">Purchase Orders</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Manage PO</h5>
                    </div>
                    <div className="card-body order-datatable">
                        
                        {/* <div className="btn-popup pull-right">
                          <Link to="/purchaseOrders/new">  <button type="button" className="btn btn-secondary">Add Product</button> </Link>
                        </div> */}

                       <div className="table-responsive">
                       <ReactTable
                            // PaginationComponent={Pagination}
                            showPagination={false}
                            data={data}
                            minRows={5}
                            columns={columns}
                            noDataText={this.state.isLoading ? '' : "No Orders Found."}
                            defaultPageSize={this.state.pageSize}
                            pageSizeOptions={[10, 25, 50, 100]}
                            // resizable={true}
                            getTrProps={(state, rowInfo, column, instance) => this.handleTrProps(rowInfo)}
                            onPageChange={(index) => this.handlePageChange(index)}
                            onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                            getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                        />
                       </div>

                      

                       <ul className="mt-3">
                            <li role="button" onClick={this.handlePreviousClick} className={`cursor-pointer page-item ${this.state.currentPage == 1 ? 'disabled' : ''}`}><span className="page-link " tabIndex={-1} role="button" aria-disabled="true" aria-label="Previous page" rel="prev">Previous</span></li>
                            {
                                this.state.pages && this.state.pages.map((page, i) => (
                                    <li onClick={this.handlePageClick(page)} key={i} className={`cursor-pointer page-item ${this.state.currentPage === page ? 'active' : ''}`}><span rel="canonical" role="button" className="page-link" aria-current="page">{page}</span></li>
                                ))
                            }
                            {
                                this.state.lastEvaluatedKey && (
                                    <li className="cursor-pointer page-item"><span className="page-link" role="button" >...</span></li>
                                )
                            }
                            <li onClick={this.handleNextClick} className={`cursor-pointer page-item ${!this.state.lastEvaluatedKey ? 'disabled' : ''}`}><span className="page-link" tabIndex={0} role="button" aria-disabled="false" aria-label="Next page" rel="next">Next</span></li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}

export default PurchaseOrders;