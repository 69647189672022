import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader/loader';
import { request } from '../../../constants/constants';
import Swal from 'sweetalert2';
import history from '../../../history';


class AddCatalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item_suppliers: [],
            item_supplier_id: undefined,
            catalog_name: '',
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getAllItemSuppliers() {
        return request('GET', '/hq/item-suppliers', {}, true);
    }

    handleSubmit = e => {
        e.preventDefault();
        e.target.classList.add('was-validated');
        console.log(e.target.checkValidity());
        if(e.target.checkValidity()) {
            this.setState({
                isLoading: true
            })
            const data = {
                catalog_name: this.state.catalog_name,
                item_supplier_id: this.state.item_supplier_id
            }
            request('POST', '/hq/catalogs', data, true).then(res => {
                console.log(res);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Success!',
                    res.data.message,
                    'success'
                );
                history.push('/catalogs');
            }).catch(err => {
                console.error(err);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Error!',
                    err.response.data.message,
                    'error'
                );
            })
        }
    }

    init() {
        this.setState({
            isLoading: true
        });
        this.getAllItemSuppliers().then(res => {
            console.log(res);
            this.setState({
                isLoading: false,
                item_suppliers: res.data.data
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        return (
            <div className="page-body">
                {this.state.isLoading && <Loader />}

                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3>Add Catalog
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to="/catalogs">Catalogs</Link></li>
                                    <li className="breadcrumb-item active">Add Catalog</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                        <div className="row product-adding add-product-form ">

                            <div className="col-xl-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Catalog Details</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="digital-add needs-validation">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Name</label>
                                                <input onChange={this.handleChange} name="catalog_name" value={this.state.catalog_name} className="form-control" id="validationCustom01" type="text" required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Item Supplier</label>
                                                <select onChange={this.handleChange} name="item_supplier_id" value={this.state.item_supplier_id} className="form-control">
                                                    <option style={{ display: 'none' }} value="">Select Item Supplier</option>
                                                    {
                                                        this.state.item_suppliers.map((item_supplier, i) => <option key={i} value={item_supplier.id}>{item_supplier.supplier_name}</option>)
                                                    }
                                                </select>

                                            </div>
                                            <div className="form-group mt-5">
                                                {/* <div className="product-buttons text-center"> */}
                                                    <button disabled={this.state.isLoading} type="submit" className="btn btn-primary">
                                                        {
                                                            this.state.isLoading ? (
                                                                <div>
                                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                            Loading...
                                                                </div>
                                                            ) : 'ADD'
                                                        }
                                                    </button>
                                                    <Link to="/catalogs" className="btn btn-light">Discard</Link>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-xl-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Supplier</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="digital-add needs-validation">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Name</label>
                                                <select onChange={this.handleChange} name="catalog_id" value={this.state.item_supplier_id} className="form-control">
                                                    <option style={{ display: 'none' }} value="">Select Item Supplier</option>
                                                    {
                                                        this.state.item_suppliers.map((item_supplier, i) => <option key={i} value={item_supplier.id}>{item_supplier.supplier_name}</option>)
                                                    }
                                                </select>

                                            </div>
                                            <div className="form-group mt-5">
                                                <div className="product-buttons text-center">
                                                    <button disabled={this.state.isLoading} type="submit" className="btn btn-primary">
                                                        {
                                                            this.state.isLoading ? (
                                                                <div>
                                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                            Loading...
                                                                </div>
                                                            ) : 'ADD'
                                                        }
                                                    </button>
                                                    <button type="button" className="btn btn-light">Discard</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default AddCatalog;