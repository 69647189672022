import React, { Component } from 'react';
import './left-sidebar.scss';
import { NavLink, Link } from 'react-router-dom';
import LogoImage from '../../public/images/athleadhq-logo.png';
import StoreSvg  from '../../public/svg/stores.svg';
import ReportsSvg  from '../../public/svg/reports.svg';
import UsersSvg  from '../../public/svg/users.svg';
// import Logos from '../../public/images/small-logo.png';
// import dashboardSvg from '../../public/svg/dashboard.svg';
// // import clockSvg from '../../public/svg/clock.svg';
// import gamesSvg from '../../public/svg/games.svg';
// import uploadSvg from '../../public/svg/upload.svg';
// import downloadSvg from '../../public/svg/download.svg';
// // import officialSvg from '../../public/svg/official.svg';
// import settingsSvg from '../../public/svg/settings.svg';
// import organisationSvg from '../../public/svg/organisation.svg';
// // import reportSvg from '../../public/svg/report.svg';
// // import communicationSvg from '../../public/svg/communication.svg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { NavLink } from 'react-router-dom';
// import '../../public/styles/leftsidebar.scss';
// import $ from 'jquery';
// // import { getPermission } from '../../constants';
// import ReactResizeDetector from 'react-resize-detector';
// import Logo from '../logo/Logo';
// // import classnames from 'classnames';
// import UserContext from '../../contexts/user';

export default class LeftSidebar extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
	}
	

	componentDidMount() {
	}
	render() {
		return (
			<div className="page-sidebar">
				<div className="main-header-left d-none d-lg-block">
					<div className="logo-wrapper"><Link to="/"><img className="blur-up lazyloaded" src={LogoImage} /></Link></div>
				</div>
				<div className="sidebar custom-scrollbar">
					<ul className="sidebar-menu">
						
						<li data-toggle="collapse" data-target="#production-submenu" className="">
							<span  className="sidebar-header">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" />
								</svg>
								<span>Production</span>
								<i className="fa fa-angle-right pull-right" />
							</span>
							<ul  id="production-submenu" className="sidebar-submenu collapse">
								<li>
									<NavLink exact activeClassName="active" exact to="/purchase-orders/new"><i className="fa fa-circle" />
										<span>Generate Purchase Order</span>
									</NavLink>
								</li>
								<li>
									<NavLink exact activeClassName="active" exact to="/purchase-orders"><i className="fa fa-circle" />
										<span>Purchase Orders</span>
									</NavLink>
								</li>
								<li>
									<NavLink exact activeClassName="active" exact to="/inventory"><i className="fa fa-circle" />
										<span>Inventory</span>
									</NavLink>
								</li>
							</ul>
						</li>

						<li data-toggle="collapse" data-target="#orders-submenu" >					
							<span className="sidebar-header" >
								<img src={StoreSvg} />
								<span>Orders</span>
								<i className="fa fa-angle-right pull-right" />
							</span>

							<ul  id="orders-submenu" className="sidebar-submenu collapse">
								<li>
									<NavLink exact to="/orders"><i className="fa fa-circle" />
										<span>All Orders</span>
									</NavLink>
								</li>

								<li>
									<NavLink exact to="/orders/new"><i className="fa fa-circle" />
										<span>New Order</span>
									</NavLink>
								</li>
							</ul>
						</li>
						
						<li data-toggle="collapse" data-target="#shipping-submenu" >					
							<span className="sidebar-header" >
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chrome"><circle cx={12} cy={12} r={10} /><circle cx={12} cy={12} r={4} /><line x1="21.17" y1={8} x2={12} y2={8} /><line x1="3.95" y1="6.06" x2="8.54" y2={14} /><line x1="10.88" y1="21.94" x2="15.46" y2={14} /></svg>
								<span>Shipping</span>
								<i className="fa fa-angle-right pull-right" />
							</span>

							<ul  id="shipping-submenu" className="sidebar-submenu collapse">
								<li>
									<NavLink  to="/awaiting-shipments"><i className="fa fa-circle" />
										<span>Awaiting Shipment</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/shipments"><i className="fa fa-circle" />
										<span>Shipments</span>
									</NavLink>
								</li>
							</ul>
						</li>

						<li data-toggle="collapse" data-target="#product-submenu" >					
							<span className="sidebar-header" >
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-box"><path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z" /><polyline points="2.32 6.16 12 11 21.68 6.16" /><line x1={12} y1="22.76" x2={12} y2={11} /></svg> 
								<span>Products</span>
								<i className="fa fa-angle-right pull-right" />
							</span>

							<ul  id="product-submenu" className="sidebar-submenu collapse">
								<li>
									<NavLink  to="/products/new"><i className="fa fa-circle" />
										<span>Add Product</span>
									</NavLink>
								</li>
								<li>
									<NavLink exact to="/products"><i className="fa fa-circle" />
										<span>View Products</span> 
									</NavLink>
								</li>
								<li>
									<NavLink exact to="/suppliers"><i className="fa fa-circle" />
										<span>Suppliers</span> 
									</NavLink>
								</li>
								<li>
									<NavLink exact to="/decoration-suppliers"><i className="fa fa-circle" />
										<span>Decoration Suppliers</span> 
									</NavLink>
								</li>
								<li>
									<NavLink  to="/catalogs"><i className="fa fa-circle" />
										<span>Catalogs</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/designs"><i className="fa fa-circle" />
										<span>Designs</span>
									</NavLink>
								</li>
								{/* <li>
									<NavLink  to="/products/inventory"><i className="fa fa-circle" />
										<span>Inventory</span>
									</NavLink>
								</li> */}
							</ul>
						</li>

						<li data-toggle="collapse" data-target="#stores-submenu" >					
							<span className="sidebar-header" >
								<img src={StoreSvg} />
								<span>Stores</span>
								<i className="fa fa-angle-right pull-right" />
							</span>

							<ul  id="stores-submenu" className="sidebar-submenu collapse">
								<li>
									<NavLink exact to="/stores"><i className="fa fa-circle" />
										<span>All Stores</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/new"><i className="fa fa-circle" />
										<span>New Store</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Discounts</span>
									</NavLink>
								</li>
							</ul>
						</li>


						<li data-toggle="collapse" data-target="#users-submenu" >					
							<span className="sidebar-header" >
								<img src={UsersSvg} />
								<span>Users</span>
								<i className="fa fa-angle-right pull-right" />
							</span>

							<ul  id="users-submenu" className="sidebar-submenu collapse">
								<li>
									<NavLink exact to="/users"><i className="fa fa-circle" />
										<span>All Users</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/user/invite"><i className="fa fa-circle" />
										<span>Invite User</span>
									</NavLink>
								</li>
							</ul>
						</li>

						<li data-toggle="collapse" data-target="#reports-submenu" >					
							<span className="sidebar-header" >
								<img src={ReportsSvg} />
								<span>Reports</span>
								<i className="fa fa-angle-right pull-right" />
							</span>

							<ul  id="reports-submenu" className="sidebar-submenu collapse">
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Sales Report</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>COG Report</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Shipping Report</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Store Report</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Lead Time</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Order Status</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Supplier Report</span>
									</NavLink>
								</li>
								<li>
									<NavLink  to="/stores/discounts"><i className="fa fa-circle" />
										<span>Customer Report</span>
									</NavLink>
								</li>
							</ul>
						</li>

					</ul>
				</div>
			</div>

		);
	}
}