import React, { Component } from 'react';
import Loader from '../../../components/loader/loader';
import { Link } from 'react-router-dom';
import { request } from '../../../constants/constants';
import Swal from 'sweetalert2';
import history from '../../../history';

class DesignDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            design_name: '',
            cost: '',
            decoration_supplier_id: '',
            lead_time: '',
            pricings: [],
            decoration_suppliers: []
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
   
        e.preventDefault();
        e.target.classList.add('was-validated');
        console.log(e.target.checkValidity());
        if(e.target.checkValidity()) {
            this.setState({
                isLoading: true
            })
            const data = {
                design_name: this.state.design_name,
                cost: this.state.cost,
                lead_time: this.state.lead_time,
                decoration_supplier_id: this.state.decoration_supplier_id,
                pricings: this.state.pricings
            }
            request('PUT', `/hq/designs/${this.props.match.params.designId}`, data, true).then(res => {
                console.log(res);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Success!',
                    res.data.message,
                    'success'
                );
                history.push('/designs');
            }).catch(err => {
                console.error(err);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Error!',
                    err.response.data.message,
                    'error'
                );
            })
        }
    }

    getDesign() {
        return request('GET', `/hq/designs/${this.props.match.params.designId}`, {}, true)
    }

    getDecorationSuppliers() {
        return request('GET', '/hq/decoration-suppliers', {}, true);
    }

    handleChangeOnSizePricing = i => e => {
        const pricings = this.state.pricings;
        const pricing = pricings[i];
        pricing[e.target.name] = e.target.value;

        this.setState({
            pricings: [...pricings]
        })
    }

    handleAddPricing = e => {
        let pricings = [];

        if(!this.state.pricings) {
            const pricing = {
                startQuantity: 1,
                endQuantity: 10,
                cost: undefined,
            }
            pricings.unshift(pricing);
            return this.setState({
                pricings: [...pricings]
            })
        }

        pricings = [...this.state.pricings];
        const lastPricing = pricings[this.state.pricings.length - 1];
        const pricing = {
            startQuantity: parseInt(lastPricing.endQuantity) + 1,
            endQuantity: parseInt(lastPricing.endQuantity) + 10,
            cost: parseInt(lastPricing.cost),
        }
        pricings.unshift(pricing);
        this.setState({
            pricings: [...pricings]
        })
        
    }

    handleRemoveSizePricing = i => e => {
        const pricings = this.state.pricings;
        const pricing = pricings[i];
        pricings.splice(i, 1);
        this.setState({
            pricings
        })
    }

    async init() {
        try {
            
            this.setState({
                isLoading: true
            })
            let design = await this.getDesign()
            let decorationSuppliers = await this.getDecorationSuppliers();
            decorationSuppliers = decorationSuppliers.data.data;

            console.log(design);
            design = design.data.data;
            this.setState({
                design_name: design.design_name,
                cost: design.cost,
                lead_time: design.lead_time,
                decoration_supplier_id: design.decoration_supplier_id,
                decoration_suppliers: decorationSuppliers,
                pricings: design.pricings,
                isLoading: false
            })
        } catch (err) {
            console.error(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            ).then(() => {
                history.push('/designs')
            })
        }

    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        return (
            <div className="page-body">
            {this.state.isLoading && <Loader />}
         
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="page-header-left">
                                <h3>{this.state.design_name}
          {/* <small>Multikart Admin panel</small> */}
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ol className="breadcrumb pull-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item"><Link to="/designs">Designs</Link></li>
                                {/* <li className="breadcrumb-item"><Link to={`/designs/${this.props.match.params.supplierId}`}>{this.state.catalog && this.state.catalog.catalog_name}</Link></li> */}
                                <li className="breadcrumb-item active">{this.state.design_name}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                    <div className="row product-adding add-product-form ">
                    
                    <div className="col">
                        <div className="card">
                            {/* <div className="card-header">
                                <h5>General</h5>
                            </div> */}
                            <div className="card-body">
                                <div className="digital-add needs-validation row">
                                    <div className="col-xl-6">
                                    <div className="form-group">
                                        <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Name</label>
                                        <input onChange={this.handleChange} name="design_name" value={this.state.design_name} className="form-control" id="validationCustom01" type="text" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span className="text-primary">*</span> Cost</label>
                                        <input   onChange={this.handleChange} name="cost" value={this.state.cost} className="form-control" id="validationCustomtitle" type="number" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Lead Time (no. days)</label>
                                        <input  onChange={this.handleChange} name="lead_time" value={this.state.lead_time} className="form-control" id="validationCustom02" type="number" required />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Decoration Supplier</label>
                                        <select onChange={this.handleChange} name="decoration_supplier_id" value={this.state.decoration_supplier_id} className="form-control">
                                            <option style={{ display: 'none' }} value="">Select Decoration Supplier</option>
                                            {
                                                this.state.decoration_suppliers.map((decoration_supplier, i) => <option key={i} value={decoration_supplier.id}>{decoration_supplier.decoration_name}</option>)
                                            }
                                        </select>
                                    </div>
{/* 
                                        <div className="form-group mt-5">
                                            <div className="product-buttons">
                                                <button disabled={this.state.isLoading} type="submit" className="btn btn-primary">
                                                    {
                                                        this.state.isLoading ? (
                                                            <div>
                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                                Loading...
                                                            </div>
                                                        ) : 'SAVE'
                                                    }
                                                </button>
                                                <Link to="/designs"><button type="button" className="btn btn-light">Discard</button></Link>
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between">
                                <h5>Pricings</h5>
                                <button onClick={this.handleAddPricing} type="button" className="btn btn-primary">Add Pricing</button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div>
                                {
                                    this.state.pricings && this.state.pricings.sort((a, b) => a.startQuantity - b.startQuantity).map((pricing, pricingIndex) => (
                                        <React.Fragment key={pricingIndex}>
                                            <div className="d-flex justify-content-between">
                                                <p className="">Range: 
                                                    <input name="startQuantity" value={pricing.startQuantity} onChange={this.handleChangeOnSizePricing(pricingIndex)} style={{width:100}} className="form-control d-inline" /> 
                                                    <strong>-</strong> 
                                                    <input name="endQuantity" onChange={this.handleChangeOnSizePricing(pricingIndex)} value={pricing.endQuantity} style={{width:100}} className="form-control d-inline" /> 
                                                    qty
                                                    <span className="ml-2">Cost: <input name="cost" value={pricing.cost} onChange={this.handleChangeOnSizePricing(pricingIndex)} style={{width:100}} className="form-control d-inline"></input></span> 
                                                </p>
                                                <button onClick={this.handleRemoveSizePricing(pricingIndex)} type="button" className="btn btn-link text-danger">Remove</button>
                                            </div>
                              
                                        </React.Fragment>
                                        ))
                                }
                            </div>

                            <div className="product-buttons mt-5">
                                <button disabled={this.state.isLoading} type="submit" className="btn btn-primary">
                                    {
                                        this.state.isLoading ? (
                                            <div>
                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                Loading...
                                            </div>
                                        ) : 'SAVE'
                                    }
                                </button>
                                <Link to="/designs"><button type="button" className="btn btn-light">Discard</button></Link>
                            </div>
                        </div>
                    </div>
                    
                
                </form>
            </div>
        </div>
        );
    }
}

export default DesignDetails;