import React, { Component } from 'react';
import { request } from '../../../constants/constants';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Loader from '../../../components/loader/loader';
import Pagination from '../../../components/pagination/pagination';
import Swal from 'sweetalert2';
import history from '../../../history';

class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            pageSize: 10,
            currentPage: 1,
        }
    }

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const items = this.state.items;
                const item = items[index];
                item.isLoading = true;
                this.setState({
                    items: items
                })
                request('DELETE', `/hq/catalogs/${this.props.match.params.catalogId}/items/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    item.isLoading = false;
                    this.setState({
                        items: items
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    item.isLoading = false;
                    this.setState({
                        items: items
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
   
    }

    handleTrProps(row) {
        return {
            onClick: (e, t) => {
                history.push(`${this.props.match.params.catalogId}/items/${row.original.id}`);
            },
        }
    }

    getAllItems() {
        return request('GET', `/hq/catalogs/${this.props.match.params.catalogId}/items`, {}, true);
    }

    getCatalog() {
        return request('GET', `/hq/catalogs/${this.props.match.params.catalogId}`, {}, true);
    }


    async init() {
        this.setState({
            isLoading: true
        })

        try {
           const itemsData = await this.getAllItems();
           const catalogData = await this.getCatalog();
           console.log(itemsData)
           this.setState({
                isLoading: false,
                items: itemsData.data.data,
                catalog: catalogData.data.data
            })
        } catch (error) {
            console.error(error);
            this.setState({
                isLoading: false
            })
        }
    }

    componentDidMount() {
       this.init();
    }

    render() {
        const data = this.state.items;
        const columns = [
            {
                Header: 'Image',
                Cell: row => <img style={{height:50,width:50}} src={row.original.item_image} />,
            },
            {
                Header: 'Name',
                accessor: 'item_name'
            },
            {
                Header: 'Cost',
                Cell: row => <span>${row.original.cost}</span>
            },
            {
                Header: 'Action',
                Cell: row => (
                    <React.Fragment>
                    <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
                    {
                        row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
                    }
                    </button>
                    </React.Fragment>
                ),
            },
        ];
        return (
            <div className="page-body">
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>
                        {this.state.catalog && this.state.catalog.catalog_name}
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item">
                            <Link to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                            </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/catalogs">Catalogs</Link></li>
                        <li className="breadcrumb-item active"> {this.state.catalog && this.state.catalog.catalog_name}</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Manage Items</h5>
                    </div>
                    <div className="card-body order-datatable">
                        <div className="btn-popup pull-right">
                           <Link to={`${this.props.match.params.catalogId}/items/new`}> <button type="button" className="btn btn-secondary">Add Item</button>  </Link>
                        </div>
                        <div className="table-responsive">
                           {
                               this.state.isLoading ? <Loader /> : (
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Items Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    // resizable={true}
                                    getTrProps={(state, rowInfo, column, instance) => this.handleTrProps(rowInfo)}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                 />
                               )
                           }
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}

export default Items;