import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2';
import './editProduct.scss';
import ReactTable from 'react-table';
import Pagination from '../../../components/pagination/pagination';
import { request } from '../../../constants/constants';
import Select, { components } from 'react-select';
import Loader from '../../../components/loader/loader';
import Flickity from 'react-flickity-component';
import '../../../../node_modules/flickity/css/flickity.css';
import { Link } from 'react-router-dom';
import history from '../../../history';
import Modal from 'react-responsive-modal';


class EditProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stocks: 1,
            pageSize: 10,
            currentPage: 1,
            selectedStores: [],
			currentSelectedStores: [],
			modal: false,
			selected: {},
			selectedAll: {},
            indeterminateSign: false,
            suppliers: [],
            decorationSuppliers: [],
            designs: [],
            design: null,
            catalogs: [],
            catalog: null,
            stores: [],
            products: [],
            items: [],
            categories: [],
            supplier: null,
            decorationSupplier: null,
            description: '',
            item: null,
            color: null,
            costPrice: 0,
            productImages: [],
            selectSize: false,
            sizes: [],
            selectColour: false,
            customField: {
                name: '',
                type: 'text',
                additionalPriceChecked: false,
                requiredChecked: false,
                price: undefined,
                supplier_id: '',
                supplier_type: ''
            },
            customFields: [],
            currentCustomFieldIndexEditing: undefined,
            product_type: 'single',
            currentCustomFieldAction: 'add'
        }
    }

    toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.stores.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedLeagues = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false,
				currentSelectedStores: [...this.state.stores]
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.state.stores.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedGames = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });

			// this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				currentSelectedStores: []
			});
		}
	}
	toggleRow(team) {
		const teamId = team.id;
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[teamId] = !this.state.selected[teamId];

		const index = this.state.currentSelectedStores.findIndex(element => element.id == team.id);
		if(index < 0) {
			this.state.currentSelectedStores.push(team);
		} else {
			this.state.currentSelectedStores.splice(index, 1);
		}

		this.setState({
			currentSelectedStores: this.state.currentSelectedStores,
			selected: newSelected
		});
	}

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.stores.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.stores.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					// this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
        }
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    decrementStock = e => {
        const stocks = this.state.stocks-=1;
        this.setState({
            stocks: stocks
        })
    }

    incrementStock = e => {
        const stocks = this.state.stocks+=1;
        this.setState({
            stocks: stocks
        })
    }

    onDrop = (type) => files => {
		if(files.length <= 0) {
			Swal.fire(
				'Error',
				'Please upload an image file',
				'error'
			)
		} else {
			this.setState({files})
			const file = files[0]; 
            const extension = file.name.split('.')[1];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.setState({
                    [type]: reader.result
                })
            }
            reader.onerror = error => {
                console.error(error);
            };
        }
    
    }
    onDropProductImage = files => {
        if(files.length <= 0) {
			Swal.fire(
				'Error',
				'Please upload an image file',
				'error'
			)
		} else {
			this.setState({files})
			const file = files[0]; 
            const extension = file.name.split('.')[1];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const productImages = this.state.productImages;
                productImages.push(reader.result);
                this.setState({
                    productImages: productImages
                })
            }
            reader.onerror = error => {
                console.error(error);
            };
        }
    }

    getSuppliers() {
        return request('GET', '/hq/item-suppliers', {}, true);
    }

    getDecorationSuppliers() {
        return request('GET', '/hq/decoration-suppliers', {}, true);
    }

    getStores() {
        return request('GET', '/hq/stores', {}, true);
    }

    getProducts() {
        return request('GET', '/hq/products', {}, true);
    }

    getCatalogItems(catalogId) {
        return request('GET', `/hq/catalogs/${catalogId}/items`, {}, true);
    }

    handleSupplierChange = data => {
        console.log(data)

        const index = this.state.suppliers.findIndex(supplier => supplier.id == data.value);
        const supplier = this.state.suppliers[index];
        const catalogs = supplier.catalogs;

        this.setState({
            supplier_id: data.id,
            // isLoading: true,
            supplier: data,
            catalogs: catalogs
        })

        // const index = this.state.suppliers.findIndex(supplier => supplier.id == value);
        // const supplier = this.state.suppliers[index];
        const catalogId = data.catalog.id;
        // this.getCatalogItems(catalogId).then(res => {
        //     console.log(res);
        //     this.setState({
        //         isLoading: false,
        //         items: res.data.data
        //     })
        // }).catch(err => {
        //     console.error(err);
        //     this.setState({
        //         isLoading: false
        //     })
        //     Swal.fire(
		// 		'Error',
		// 		err.response.data.message,
		// 		'error'
		// 	)
        // })
    }

    getdesigns(decorationId) {
        // return request('GET', `/hq/designs/${}/items`, {}, true);
    }

    handleDecorationSupplierChange = data => {
        const index = this.state.decorationSuppliers.findIndex(supplier => supplier.id == data.value);
        const supplier = this.state.decorationSuppliers[index];
        const designs = supplier.designs;
        console.log('designs', designs)
        this.setState({
            decoration_id: data.id,
            decorationSupplier: data,
            designs: designs
        })
        // const index = this.state.suppliers.findIndex(supplier => supplier.id == value);
        // const supplier = this.state.suppliers[index];
        // const designId = decorationSupplier.design.id;

        
    }

    handleCategoriesChange = i => categories => {
        console.log(i,categories)
        const stores = this.state.stores;
        const store = stores[i];
        store.selectedCategories = categories;
        this.setState({
            stores: stores
        })
    }

    handleCatalogChange = catalog => {
        console.log(catalog)
        this.setState({
            catalog: catalog,
            isLoading: true
        })
        this.getCatalogItems(catalog.value).then(res => {
            console.log(res);
            this.setState({
                isLoading: false,
                items: res.data.data
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
				'Error',
				err.response.data.message,
				'error'
			)
        })
       
    }

    handleDesignChange = design => {
        console.log(design)
        this.setState({
            design: design,
            estimated_delivery: design.lead_time + (this.state.item ? this.state.item.item_lead_time : 0)
        })
    }

    handleItemChange = item => {
        console.log(item)
        this.setState({
            item: item && {
                ...item,
                item_sizes: this.formatItemSizes(null,item.item_sizes),
                item_colours: this.formatItemColours(null, item.item_colours),
            },
            size: item.item_size_guide,
            estimated_delivery: item.item_lead_time + (this.state.design ? this.state.design.lead_time : 0)
        })
    }

    handleItemColorChange = color => {
        this.setState({
            color: color
        })
    }

    onSaleTypeChange = e => {
        console.log(e.target.checked);
        if(e.target.checked) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    formatItemColourToOptions(data) {
       const colors =  JSON.parse(data);
       return colors.map(color => {
           return {
               label: color.name,
               value: color.value
           }
       }) 
    }

    formatItemSizes(selectedSizes, data) {
        // if(typeof data === 'string') {
        //     const sizes = data.split(',').map(element => {
        //         return {checked: !selectedSizes ? true : selectedSizes.indexOf(element) >-1 ? true : false, value: element}
        //     })
        //     return sizes;
        // } else {
        //     return data
        // }

        const sizes = data.map(element => {
            return {checked: !selectedSizes ? true : selectedSizes.findIndex(selectedSize => selectedSize.size == element.size) >-1 ? true : false, value: element.size, ...element}
        })
        return sizes;
       
    }

    formatItemColours(selectedColours, data) {
        // if(typeof data === 'string') {
        //     const colours = JSON.parse(data).map(element => {
        //         return {...element, checked: !selectedColours ? true : selectedColours.findIndex(selectedColour => selectedColour.value == element.value ) >-1 ? true : false}
        //     })
        //     return colours;
        // } else {
        //     return data
        // }

        console.log('data', data)

        const colours = data.map(element => {
            return {checked: !selectedColours ? true : selectedColours.findIndex(selectedColour => selectedColour.name == element.name) >-1 ? true : false, value: element.name, ...element}
        })
        return colours;
       
    }


    handleSubmit = e => {
        e.preventDefault();
        e.target.classList.add('was-validated');
        console.log( e.target.checkValidity());
        if( e.target.checkValidity()) {
            this.setState({
                isLoading: true
            })
        
            let stores = [];
            let categories = [];
          
            const keys = Object.keys(this.state.selected);
            keys.forEach(key => {
                if(this.state.selected[key]) stores.push(key)
                const index = this.state.stores.findIndex(store => store.id === key);
                console.log(index)
                if(this.state.stores[index].selectedCategories) {
                    this.state.stores[index].selectedCategories.forEach(category => {
                        categories.push(category.id)
                    })
                }
            })
 
            const data = {
                product_name: this.state.product_name,
                product_type: this.state.product_type,
                // SKU: this.state.SKU,
                // description: this.state.description,
                image: this.state.productImages,
                // size_guide: this.state.size,
                stocks: parseInt(this.state.stocks),
                online_price: parseFloat(this.state.online_price),
                sell_price: parseFloat(this.state.sell_price),
                // cost_price: parseFloat(costPrice),
                // sale_type: this.state.sale_type,
                // tag: 'sample tag',
                inventory_count: parseInt(this.state.stocks),
                estimated_delivery: parseFloat(this.state.estimated_delivery),
                stores: stores,
               
                categories: categories,
                custom_fields: this.state.customFields

            }

            if(this.state.description) {
                data.description = this.state.description;
            }

            if(this.state.sale) {
                data.sale = this.state.sale
            }

            if(this.state.sale_type) {
                data.sale_type = this.state.sale_type
            }

           
            if(this.state.sizeGuide || this.state.sizeGuide === 'none') {
                data.item_size_guide = this.state.sizeGuide
            }

            if(this.state.product_type === 'bundle') {
                if(!this.state.products) {
                    return Swal.fire(
                        'Error',
                        'Products are required for bundle',
                        'error'
                    );
                }

                if(this.state.products.length == 0) {
                    return Swal.fire(
                        'Error',
                        'Please select products for bundle',
                        'error'
                    );
                }
                data.products = this.state.selectedProducts
                data.cost_price = this.state.selectedProductsTotalCost
            } else {
                const sizes = this.state.item.item_sizes.filter(size => size.checked);
                const colours = this.state.item.item_colours.filter(colour => colour.checked);

                data.item_supplier_id = this.state.supplier.id
                data.catalog_id = this.state.catalog.id
                data.item_id = this.state.item.id
                data.colours = colours
                data.sizes = sizes.length > 0 ? sizes : undefined

                if(this.state.decorationSupplier) {
                    data.decoration_supplier_id = this.state.decorationSupplier.id;
                    if(!this.state.design) {
                        return Swal.fire(
                            'Error',
                            'Please select design',
                            'error'
                        );
                    }
                    data.design_id = this.state.design.id;
                }
                
                let costPrice = this.state.item.cost;
                if(this.state.decorationSupplier && this.state.design) {
                    costPrice+=this.state.design.cost
                }

                data.cost_price = costPrice;
    
            }
            
            console.log(data);
            request('PUT', `/hq/products/${this.props.match.params.productId}`, data, true).then(res => {
                console.log(res);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Success!',
                    res.data.message,
                    'success'
                ).then(() => {
                    history.push('/products');
                })
            }).catch(err => {
                console.error(err);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Error!',
                    err.response.data.message,
                    'error'
                )
            })
        }
       
    }

    selectedSizeChange = (sizeIndex) => e => {
        const item = this.state.item;
        const sizes = item.item_sizes;
        const size = sizes[sizeIndex];
        size.checked = e.target.checked;
        this.setState({
            item: item
        })
    }

    selectedColorChange = (sizeIndex) => e => {
        const item = this.state.item;
        const colours = item.item_colours;
        const colour = colours[sizeIndex];
        colour.checked = e.target.checked;
        this.setState({
            colours: colours
        })
    }

    selectSizeOnChange = e => {
        this.setState({
            selectSize: e.target.checked
        })
    }

    selectColourOnChange = e => {
        this.setState({
            selectColour: e.target.checked
        })
    }

    getProduct() {
       return request('GET', `/hq/products/${this.props.match.params.productId}`, {}, true);
    }

    async init() {
        this.setState({
            isLoading: true
        })
        try {
            const productData = await this.getProduct();
            const product = productData.data.data;
            const suppliersData = await this.getSuppliers();
            const decorationSuppliersData = await this.getDecorationSuppliers();
            const storesData = await this.getStores();

            this.getProducts().then(res => {
                console.log('products: ', res);
                this.setState({
                    products: res.data.data
                })
            })

            // console.log('alldata', productData, supplierItemsData, suppliersData, decorationSuppliersData, storesData);
            let selected = {};
            product.stores.forEach(store => {
                selected[store.id] = true;
            })
            const stores = storesData.data.data.map(store => {
                return  {
                    selectedCategories: product.categories.filter(category => category.store_id == store.id)
                    .map(category => { 
                        return {
                            label: category.category_name,
                            value: category.id,
                            ...category
                        }
                    }),
                    ...store
                }
            })

            const suppliers = suppliersData.data.data.map(supplier => ({...supplier, type: 'item'}))
            const decorationSuppliers = decorationSuppliersData.data.data.map(supplier => ({...supplier, type: 'decoration'}))

            let state = {
                product: product,
                suppliers: suppliers,
                decorationSuppliers: decorationSuppliers,
                stores: stores,
                sale: product.sale,
                sale_type: product.sale_type,
                sell_price: product.sell_price,
                online_price: product.online_price,
                product_name: product.product_name,
                SKU: product.SKU,
                estimated_delivery: product.estimated_delivery,
                stocks: product.inventory_count,
                description: product.description,
                stocks: product.inventory_count,
                currentSelectedStores: product.stores,
                selected: selected,
                selectedAll: {},
                supplier: product.item_supplier,
                decorationSupplier: product.decoration_supplier,
                costPrice: 0,
                productImages: product.image,
                size: product.size_guide,
                customFields: product.custom_fields || []
            }

            if(product.product_type === 'bundle') {
                state.product_type = 'bundle';
                let totalCost = 0;

                state.selectedProducts = product.products.map(element => {
                    totalCost += element.cost_price;
                    return {
                        ...element,
                        label: element.product_name,
                        value: element.id,
                    }
                });

                state.selectedProductsTotalCost = totalCost;
            } else {
                const supplierItemsData = await this.getCatalogItems(product.catalog_id);
                let item = product.item;
                const productSelectedSizes = product.sizes;
                const productSelectedColours = product.colours;
                item.value = item.id;
                item.label = item.item_name;
                item.item_sizes = this.formatItemSizes(productSelectedSizes, item.item_sizes)
                item.item_colours = this.formatItemColours(productSelectedColours, item.item_colours)
    
                const supplier = product.item_supplier;
                supplier.value = supplier.id;
                supplier.label = supplier.supplier_name
    
                const index = suppliersData.data.data.findIndex(element => element.id == supplier.id);
                const catalogs = suppliersData.data.data[index].catalogs;
                const catalogIndex = catalogs.findIndex(element => element.id == product.catalog_id);
                const catalog = catalogs[catalogIndex];
                catalog.value = catalog.id;
                catalog.label = catalog.catalog_name;
    
                const decorationSupplier = product.decoration_supplier;
                let design;
                let designs;
                if(decorationSupplier) {
                    decorationSupplier.value = decorationSupplier.id;
                    decorationSupplier.label = decorationSupplier.decoration_name;
                    designs = decorationSupplier.designs;
                    console.log('designs: ', designs);
                    if(product.design_id) {
                        const decorationDesignIndex = designs.findIndex(element => element.id === product.design_id);
                        console.log('decorationDesignIndex: ', decorationDesignIndex);
                        if(decorationDesignIndex > -1) {
                            design = designs[decorationDesignIndex];
                            design.value = design.id;
                            design.label = design.design_name;
                        }
                    }
                }

                state = {
                    ...state,
                    designs: designs,
                    design: design,
                    catalog: catalog,
                    catalogs: catalogs,
                    product_type: 'single',
                    items: supplierItemsData.data.data,
                    item: item,
                    selectSize: productSelectedSizes ? true : false,
                    selectColour: productSelectedColours ? true : false,
                }
            }
            

            // const item_colour = JSON.parse(product.colour);
            // const color = {
            //     value: item_colour.value,
            //     label: item_colour.label
            // };

            this.setState(state);
        } catch (error) {
            console.error(error)
            Swal.fire(
				'Error',
				error.response.data.message,
				'error'
			).then(() => {
                history.push('/products');
            })
        }
        this.setState({
            isLoading: false
        })
    }

    hanldeClearSizeGuide = e => {
        this.setState({
            sizeGuide: 'none',
        })
    }

    handleRemoveImage = i => e => {
        const images = this.state.productImages;
        images.splice(i, 1);
        this.setState({
            productImages: images
        })
    }


    onAddCustomFieldCloseModal = () => {
        this.setState({
            addCustomFieldModal: false
        })
    }

    handleClickAddCustomField = () => {
        this.setState({
            addCustomFieldModal: true
        })
    }

    onCustomFieldSectionChange = e => {
        if(e.target.name === 'supplier_id') {
            const allSuppliers = [...this.state.suppliers, ...this.state.decorationSuppliers];
            const index = allSuppliers.findIndex(supplier => supplier.id === e.target.value);
            if (index > -1) {
                const supplier = allSuppliers[index];
                if(supplier) {
                    return this.setState({
                        customField: {
                            ...this.state.customField,
                            [e.target.name]: e.target.value,
                            supplier_type: supplier.type
                        }
                    })
                }
            }
        }
        if(e.target.name === 'additionalPriceChecked' || e.target.name === 'requiredChecked') {
            return this.setState({
                customField: {
                    ...this.state.customField,
                    [e.target.name]: e.target.checked
                }
            })
        }

        this.setState({
            customField: {
                ...this.state.customField,
                [e.target.name]: e.target.value
            }
        })
    }

    handleAddCustomField = e => {
        const customFields = this.state.customFields;
        customFields.push(this.state.customField);
        this.setState({
            customFields: customFields,
            customField: {
                name: '',
                type: 'text',
                additionalPriceChecked: false,
                requiredChecked: false,
                price: undefined,
                supplier_id: undefined,
            },
            currentCustomFieldAction: 'add',
            addCustomFieldModal: false
        })
    }

    handleEditCustomField = (customField, customFieldIndex) => e => {
        this.setState({
            customField: customField,
            addCustomFieldModal: true,
            currentCustomFieldIndexEditing: customFieldIndex,
            currentCustomFieldAction: 'edit'
        })
    }

    handleUpdateCustomField = e => {
        const customFields = this.state.customFields;
        customFields[this.state.currentCustomFieldIndexEditing] = this.state.customField;
        this.setState({
            customFields: customFields,
            customField: {
                name: '',
                type: 'text',
                additionalPriceChecked: false,
                requiredChecked: false,
                price: undefined,
                supplier_id: undefined,
            },
            currentCustomFieldAction: 'add',
            addCustomFieldModal: false
        })
    }

    handleDeleteCustomField = customFieldIndex => e => {
        const customFields = this.state.customFields;
        const customField = customFields[customFieldIndex];

        if(!customField) {
            return Swal.fire(
                'Error',
                "Custom field can't delete, custom field not found",
                'error'
            )
        }

        customFields.splice(customFieldIndex, 1);
        this.setState({
            customFields: customFields
        })

    }

    handleProductsChange = products => {
        console.log(products);
        let totalCost = 0;
        const productImages = [];
        if(products) {
            for (const product of products) {
                productImages.push(product.image[0]);
                totalCost += product.cost_price;
            }
        }
        this.setState({
            selectedProductsTotalCost: totalCost,
            selectedProducts: products,
            productImages
        })
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const customOption = ({ children, ...props }) => {
            return (
              <components.Option {...props}>
                <div className="colours-item" style={{backgroundColor: props.data.value}}>{props.data.label}</div>
              </components.Option>
            );
        };

        let costPrice = 0;

        if(this.state.product_type === 'single') {
            
            costPrice = this.state.item && this.state.item.cost;
            if(this.state.decorationSupplier && this.state.design) {
                costPrice+=this.state.design.cost
            }
        } else {
            costPrice = this.state.selectedProductsTotalCost;
        }


        let data = this.state.stores;

        const productOptions = this.state.products && this.state.products.map( product => {
            return {
                label: product.stores.map(storeId => { 
                    return this.state.stores && this.state.stores.filter(store => store.id === storeId)[0] && this.state.stores.filter(store => store.id === storeId)[0].store_code; 
                }).join('/') + " - " + product.product_name,
                value: product.id,
                ...product
            }
        })

        const supplierOptions = this.state.suppliers.map( supplier => {
            return {
                label: supplier.supplier_name,
                value: supplier.id,
                ...supplier
            }
        })
        const decorationSupplierOptions = this.state.decorationSuppliers.map( decorationSupplier => {
            return {
                label: decorationSupplier.decoration_name,
                value: decorationSupplier.id,
                ...decorationSupplier
            }
        })
        const itemOptions = this.state.items.map( item => {
            return {
                label: item.item_name,
                value: item.id,
                ...item
            }
        })

        const catalogOptions = this.state.catalogs.map( item => {
            return {
                label: item.catalog_name,
                value: item.id,
                ...item
            }
        })

        const designOptions = this.state.designs && this.state.designs.map( item => {
            return {
                label: item.design_name,
                value: item.id,
                ...item
            }
        })
  
		// if (this.state.search) {
		// 	data = data.filter(row => {
		// 		return row.team_name.toLowerCase().includes(this.state.search.toLowerCase());
		// 	})
		// }
		const columns = [
			{
				Header: (header) => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 16 }}>
						<input
							type="checkbox"
							className="custom-control-input"
							id="selectAllCheckBox"
							checked={this.state.selectedAll[this.state.currentPage] || false}
							onChange={() => this.toggleSelectAll()}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.indeterminateSign;
								}
							}}
						/>
						<label className="custom-control-label" htmlFor="selectAllCheckBox"></label>
					</div>
				),
				width: 52,
				Cell: row => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input type="checkbox" className="custom-control-input" id={`game-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original)} />
						<label className="custom-control-label" htmlFor={`game-id-${row.original.id}`}></label>
					</div>
				),
				className: 'header-checkbox-toggle',
				headerStyle: { padding: '1.07143em 0' },
				style: { padding: '0.71429em 0' }
				
			},
			{
				Header: 'Name',
				accessor: 'store_name'
			},
			// {
			// 	Header: 'Code',
			// 	accessor: 'store_code'
            // },
            {
                Header: 'Categories',
                Cell: row =>  {
                    const storeOptions = row.original.categories && row.original.categories.map(category => {
                        return {
                            label: category.category_name,
                            value: category.id,
                            ...category
                        }
                    })
                    return   (
                        <Select
                            classNamePrefix="categories"
                            value={row.original.selectedCategories || []}
                            onChange={this.handleCategoriesChange(row.index)}
                            options={storeOptions}
                            isMulti={true}
                            className="select-categories"
                        />
                    )

                }
              
			},
	
		]
        return (
            <div className="page-body">

                <Modal
                   open={this.state.addCustomFieldModal} 
                   onClose={this.onAddCustomFieldCloseModal}
                   center={true}
                   classNames={{
                    overlay: 'custom-overlay',
                    modal: 'custom-modal'
                   }}
                >
                        <div className="">
                        <div className="modal-header">
                            <h5 className="modal-title f-w-600" id="exampleModalLabel">Add Custom field</h5>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Name:</label>
                                <input onChange={this.onCustomFieldSectionChange} value={this.state.customField.name} name="name" className="form-control" type="text" />
                            </div>

                            <div className="form-group">
                                <label>Type:</label>
                                <select onChange={this.onCustomFieldSectionChange} value={this.state.customField.type} name="type" className="custom-select">
                                    <option value="text">Text</option>
                                    <option value="number">Number</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Supplier:</label>
                                <select onChange={this.onCustomFieldSectionChange} value={this.state.customField.supplier_id} name="supplier_id" className="custom-select">
                                    <option style={{display: 'none'}} value=''>Select supplier</option>
                                   {
                                       [...this.state.suppliers, ...this.state.decorationSuppliers].filter(supplier => supplier.custom_data_checked).map((supplier, i) => (
                                            <option key={i} value={supplier.id}>{supplier.supplier_name || supplier.decoration_name}</option>
                                       ))
                                   }
                                </select>
                            </div>

                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input onChange={this.onCustomFieldSectionChange} checked={this.state.customField.requiredChecked} name="requiredChecked" type="checkbox" className="custom-control-input" id="customFieldRequired" />
                                    <label className="custom-control-label" htmlFor="customFieldRequired">Required ?</label>
                                </div>
                            </div>

                            <div className="mt-2">
                                <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                        <input onChange={this.onCustomFieldSectionChange} checked={this.state.customField.additionalPriceChecked} name="additionalPriceChecked" type="checkbox" className="custom-control-input" id="additionalPriceCheckBox" />
                                        <label className="custom-control-label" htmlFor="additionalPriceCheckBox">Additional price ?</label>
                                    </div>
                                </div>

                                {
                                    this.state.customField.additionalPriceChecked && (
                                        <div className="form-group">
                                            <div className="form-froup">
                                                <label>Price:</label>
                                                <input onChange={this.onCustomFieldSectionChange} value={this.state.customField.price} name="price" className="form-control" type="number" />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>



                        </div>
                        <div className="modal-footer">
                            {
                                this.state.currentCustomFieldAction === 'add' ? (
                                    <button onClick={this.handleAddCustomField} className="btn btn-secondary" type="button">ADD</button>
                                ) : <button onClick={this.handleUpdateCustomField} className="btn btn-secondary" type="button">UPDATE</button>
                            }
                            <button onClick={this.onAddCustomFieldCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                </Modal>


                <form onSubmit={this.handleSubmit} noValidate>
                {
                    this.state.isLoading && <Loader />
                }
                {/* Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3> {this.state.product && this.state.product.product_name}
              {/* <small>Multikart Admin panel</small> */}
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item"><a href="index.html"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></a></li>
                                    <li className="breadcrumb-item"><Link to="/products">Products</Link></li>
                                    <li className="breadcrumb-item active">{this.state.product && this.state.product.product_name}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Container-fluid Ends*/}
                {/* Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row product-adding add-product-form ">
                    
                    <div className="col-xl-6">
                    <div className="card">
                                <div className="card-header">
                                    <h5>Product Type</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group mb-4">
                                            <label><span className="text-primary">*</span>Type:</label>
                                            <select onChange={this.handleChange} value={this.state.product_type} name="product_type" className="custom-select">
                                                <option value="single">Single</option>
                                                <option value="bundle">Bundle</option>
                                            </select>
                                        </div>

                                        <div className="form-group mb-4">
                                            {
                                                this.state.product_type === 'bundle' && (
                                                    <React.Fragment>
                                                        <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span>Products</label>
                                                        <Select
                                                            classNamePrefix="products"
                                                            value={this.state.selectedProducts || []}
                                                            onChange={this.handleProductsChange}
                                                            options={productOptions}
                                                            isMulti={true}
                                                            className="select-products"
                                                        />
                                                    </React.Fragment>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.product_type === 'single' && (
                                    <React.Fragment>
                                        <div className="card">
                                                <div className="card-header">
                                                    <h5>Supplier</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="digital-add needs-validation">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span>Name</label>
                                                            <Select
                                                                value={this.state.supplier}
                                                                onChange={this.handleSupplierChange}
                                                                options={supplierOptions}
                                                            />
                                                        </div>
                                                        { this.state.supplier && (
                                                            <React.Fragment>
                                                            {
                                                                this.state.catalogs && (
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span>Select Catalog</label>
                                                                        <Select
                                                                            value={this.state.catalog}
                                                                            onChange={this.handleCatalogChange}
                                                                            isMulti={false}
                                                                            options={catalogOptions}
                                                                            noDataText={'No catalogs found'}
                                                                            className="mb-4"
                                                                        />
                                                                    </div> 
                                                                )
                                                                
                                                            }      

                                                            <div className="form-group mb-4">
                                                                {this.state.isLoading && <Loader />}
                                                                <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span>Select Item</label>
                                                                <Select
                                                                    value={this.state.item}
                                                                    onChange={this.handleItemChange}
                                                                    isMulti={false}
                                                                    options={itemOptions}
                                                                    noDataText={'No items found'}
                                                                    className="mb-4"
                                                                />
                                                                
                                                                { 
                                                                    this.state.item && (
                                                                    // this.state.item.map( (element, i) => (
                                                                    <React.Fragment>
                                                                        <div className="form-group mb-4 colours">  
                                                                        
                                                                            {
                                                                                this.state.item.item_colours && this.state.item.item_colours.length > 0 && (
                                                                                    <React.Fragment>
                                                                                        <div className="custom-control custom-checkbox mb-2">
                                                                                            <input checked={this.state.selectColour} onChange={this.selectColourOnChange} type="checkbox" className="custom-control-input" id="selectColour" />
                                                                                            <label className="custom-control-label" htmlFor="selectColour" >
                                                                                                Select colour?
                                                                                            </label>
                                                                                        </div>
                                                                                        {/* <Select
                                                                                            value={this.state.color}
                                                                                            onChange={this.handleItemColorChange}
                                                                                            options={this.formatItemColourToOptions(element.item_colours)}
                                                                                            components={{Option: customOption}}
                                                                                            classNamePrefix="colours"
                                                                                        /> */}
                                                                                        {
                                                                                            this.state.selectColour && this.state.item.item_colours.map( (colour, colourIndex) => (
                                                                                                    <div key={colourIndex} className="item-container pl-3">
                                                                                                        <div className="custom-control custom-checkbox mb-2">
                                                                                                            <input checked={colour.checked} onChange={this.selectedColorChange(colourIndex)} type="checkbox" className="custom-control-input" id={`size-${this.state.item.id + colour.name}`} />
                                                                                                            <label className="custom-control-label" htmlFor={`size-${this.state.item.id + colour.name}`} >
                                                                                                                {colour.name}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        
                                                                                                    </div>
                                                                                            ))
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )
                                                                            }          
                                                                            
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <div className="custom-control custom-checkbox mb-2">
                                                                            <input checked={this.state.selectSize} onChange={this.selectSizeOnChange} type="checkbox" className="custom-control-input" id="selectSize" />
                                                                            <label className="custom-control-label" htmlFor="selectSize" >
                                                                                Select size?
                                                                            </label>
                                                                            </div>

                                                                            {
                                                                                this.state.selectSize && this.state.item.item_sizes.map( (size,sizeIndex) => (
                                                                                    <div key={sizeIndex} className="p-0 custom-control custom-control-inline custom-control-size mb-2">
                                                                                        <input onChange={this.selectedSizeChange(sizeIndex)} checked={size.checked} type="checkbox" className="custom-control-input" id={`size-${this.state.item.id + size.value}`} value={size.value} />
                                                                                        <label className="custom-control-label" htmlFor={`size-${this.state.item.id + size.value}`}>{size.value}</label>
                                                                                    </div>
                                                                                ))
                                                                            }

                                                                        </div>
                                                                    </React.Fragment>
                                                                    // ))
                    
                                                                )}
                                                            </div>

                                                            </React.Fragment>
                                                        ) }
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="card">
                                            <div className="card-header">
                                                <h5>Decoration Supplier</h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="digital-add needs-validation">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="validationCustom01" className="col-form-label pt-0">Name</label>
                                                        <Select
                                                            value={this.state.decorationSupplier}
                                                            onChange={this.handleDecorationSupplierChange}
                                                            options={decorationSupplierOptions}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.decorationSupplier && this.state.designs && (
                                                            <div className="form-group mb-4">
                                                                <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span>Select Design</label>
                                                                <Select
                                                                    value={this.state.design}
                                                                    onChange={this.handleDesignChange}
                                                                    isMulti={false}
                                                                    options={designOptions}
                                                                    noDataText={'No designs found'}
                                                                    className="mb-4"
                                                                />
                                                            </div> 
                                                        )
                                                    }  
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }


                            <div className="card">
                                <div className="card-header">
                                    <h5>Costing and Pricing</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group pt-0">
                                            <label htmlFor="validationCustom02" className="col-form-label"> Cost Price (Item cost + Decoration cost)</label>
                                            <input readOnly value={costPrice} className="form-control" id="validationCustom02" type="number" />
                                        </div>
                                        <div className="form-group pt-0">
                                            <label htmlFor="validationCustom02" className="col-form-label"><span>*</span> Sell Price</label>
                                            <input value={this.state.sell_price || ''} name="sell_price" onChange={this.handleChange} className="form-control" id="validationCustom02" type="number" required />
                                        </div>
                                        <div className="form-group pt-0">
                                            <label htmlFor="validationCustom02" className="col-form-label"><span>*</span> Online Price</label>
                                            <input value={this.state.online_price || ''} name="online_price" onChange={this.handleChange} className="form-control" id="validationCustom02" type="number" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label"> Sale</label>
                                            <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block" htmlFor="edo-ani">
                                                <input checked={this.state.sale_type == 'amount'} onChange={this.onSaleTypeChange} name="sale_type" value="amount" className="radio_animated" id="edo-ani" type="radio" />
                                                Amount
                                                </label>
                                                <label className="d-block" htmlFor="edo-ani1">
                                                <input checked={this.state.sale_type == 'percent_off'} onChange={this.onSaleTypeChange} name="sale_type" value="percent_off" className="radio_animated" id="edo-ani1" type="radio"/>
                                                % Off
                                                </label>
                                                <label className="d-block" htmlFor="edo-ani1">
                                                <input checked={this.state.sale_type == 'none'} onChange={this.onSaleTypeChange} name="sale_type" value="none" className="radio_animated" id="edo-ani1" type="radio"/>
                                                None
                                                </label>
                                            </div>
                                            <input value={this.state.sale || ''} name="sale" onChange={this.handleChange} className="form-control" id="validationCustom02" type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                         
                            <div className="card">
                                <div className="card-header">
                                    <h5>Image</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        {/* <div className="form-group">
                                            <label htmlFor="validationCustom05" className="col-form-label pt-0"> Meta Title</label>
                                            <input className="form-control" id="validationCustom05" type="text" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Meta Description</label>
                                            <textarea rows={4} cols={12} defaultValue={""} />
                                        </div>
                                        <div className="form-group mb-0">
                                            <div className="product-buttons text-center">
                                                <button type="button" className="btn btn-primary">Add</button>
                                                <button type="button" className="btn btn-light">Discard</button>
                                            </div>
                                        </div> */}
                                           <Dropzone 
											   	className="drop-area d-flex align-items-center mb-4"
												   multiple={false}
												   accept="image/*"
												   onDrop={this.onDropProductImage}
											   >
													{({getRootProps, getInputProps}) => (
													<section >
														<div className="dropzone">
														<div className="inner-text">
                                                            <p>
                                                                <span className="icon icon-upload">
                                                                    <svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                                        <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
                                                                    </svg>
                                                                </span>
                                                            </p>
                                                            <p>Drop files here or click to upload.</p>
														</div>
														</div>
														<aside>
														{/* <h4>Files</h4> */}
														</aside>
													</section>
													)}
                                            </Dropzone>

                                         {
                                                this.state.productImages.length >= 1 && (
                                                <div className="border border-primary">

                                                    <Flickity
                                                        className={'carousel'} // default ''
                                                        // elementType={'div'} // default 'div'
                                                        options={{
                                                            initialIndex: this.state.productImages.length-1,
                                                            // pageDots: false
                                                        }} // takes flickity options {}
                                                        disableImagesLoaded={true} // default false
                                                        reloadOnUpdate={true} // default false
                                                        static={true} // default false
                                                        >
                                                    {
                                                        this.state.productImages.map( (productImage, i)=> (
                                                          <div className="h-100 border mr-2 d-flex" key={i}>
                                                            <div className="position-absolute">
                                                                <button onClick={this.handleRemoveImage(i)} type="button" className="btn btn-link"><i className="fa fa-trash" /></button>
                                                            </div>
                                                            <img alt="" className="" style={{width:300}} src={`${productImage}${(productImage.includes('base64') ? '' : '?cache=none')}`} />
                                                          </div>
                                                        ))
                                                    }
                                                    </Flickity>
                                                </div>
                                                )
                                            }
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between">
                                        <h5>Custom fields</h5>
                                        <button type="button" onClick={this.handleClickAddCustomField} className="btn btn-primary">Add</button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="custom-fields">
                                        <div className="row border-bottom mb-4">
                                            <div className="col">
                                                Name
                                            </div>
                                            <div className="col">
                                                Type
                                            </div>
                                            <div className="col">
                                                Required
                                            </div>
                                            <div className="col">
                                                Additional price
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                        {
                                            this.state.customFields && this.state.customFields.map((customField, i) => (
                                                <div key={i} className="row border-bottom mb-2">
                                                    <div className="col">
                                                        {customField.name}
                                                    </div>
                                                    <div className="col">
                                                        {customField.type}
                                                    </div>
                                                    <div className="col">
                                                        {customField.requiredChecked ? 'Yes' : 'No'}
                                                    </div>
                                                    <div className="col">
                                                       {
                                                           customField.additionalPriceChecked ? customField.price : 'Free'
                                                       }
                                                    </div>
                                                    <div className="col">
                                                        <button type="button" onClick={this.handleEditCustomField(customField, i)} className="btn btn-link"><i class="fa fa-edit"></i></button>
                                                        <button type="button" onClick={this.handleDeleteCustomField(i)} className="btn btn-link"><i class="fa fa-trash"></i></button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>General</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group">
                                            <label htmlFor="validationCustom01" className="col-form-label pt-0"><span>*</span> Name</label>
                                            <input name="product_name" onChange={this.handleChange} value={this.state.product_name || ''} className="form-control" id="validationCustom01" type="text" required />
                                        </div>
                                        {/* <div className="form-group">
                                            <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span>*</span> SKU</label>
                                            <input name="SKU" onChange={this.handleChange} value={this.state.SKU || ''} className="form-control" id="validationCustomtitle" type="text" required />
                                        </div> */}
                                        <div className="form-group">
                                            <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span>*</span> Lead Time (No. of days)</label>
                                            <input name="estimated_delivery" onChange={this.handleChange} value={this.state.estimated_delivery || ''} className="form-control" id="validationCustomtitle" type="number" required />
                                        </div>
                                        {/* <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Categories</label>
                                            <select className="custom-select" required>
                                                <option value>--Select--</option>
                                                <option value={1}>eBooks</option>
                                                <option value={2}>Graphic Design</option>
                                                <option value={3}>3D Impact</option>
                                                <option value={4}>Application</option>
                                                <option value={5}>Websites</option>
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label className="col-form-label">Sort Summary</label>
                                            <textarea rows={5} cols={12} defaultValue={""} />
                                        </div> */}

                                        <div className="form-group qty-box">
                                            <label className="col-form-label"><span>*</span> Stocks</label>
                                            <div className="input-group bootstrap-touchspin">
                                                <div className="input-group-prepend">
                                                    <button disabled={this.state.stocks <= 0} onClick={this.decrementStock} className="btn btn-primary btn-square bootstrap-touchspin-down" type="button"><i className="fa fa-minus" /></button>
                                                </div>
                                                <div className="input-group-prepend"><span className="input-group-text bootstrap-touchspin-prefix" style={{display: 'none'}} />
                                                </div>
                                                    <input className="touchspin form-control" type="text" name="stocks" onChange={this.handleChange} value={this.state.stocks}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text bootstrap-touchspin-postfix" style={{display: 'none'}} />
                                                </div>
                                                <div className="input-group-append ml-0">
                                                    <button onClick={this.incrementStock} className="btn btn-primary btn-square bootstrap-touchspin-up" type="button">
                                                        <i className="fa fa-plus" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                    <label className="col-form-label">Size Guide</label>
                                                    <button onClick={this.hanldeClearSizeGuide} className="btn btn-link text-danger">Clear</button>
                                            </div>
                                            <Dropzone 
                                                className="drop-area d-flex align-items-center"
                                                multiple={false}
                                                accept="image/*"
                                                onDrop={this.onDrop('size')}
                                            >
													{({getRootProps, getInputProps}) => (
                                                    <section >
                                                        {this.state.size && this.state.sizeGuide !== 'none' && <img className="img-fluid" src={this.state.size} />}
                                                        <div className="dropzone">
                                                        <div className={"inner-text " + (this.state.size && "has-image")}>
                                                            <p>
                                                                <span className="icon icon-upload">
                                                                    <svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                                        <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
                                                                    </svg>
                                                                </span>
                                                            </p>
                                                            <p>Drop files here or click to upload.</p>
                                                        </div>
                                                        </div>
                                                        <aside>
                                                        {/* <h4>Files</h4> */}
                                                        </aside>
                                                    </section>
													)}
                                            </Dropzone>
                                        </div>
                                        {/* <label className="col-form-label pt-0"> Product Upload</label>
                                        <Dropzone 
											   	className="drop-area d-flex align-items-center"
												   multiple={false}
												   accept="image/*"
												   onDrop={this.onDrop}
											   >
													{({getRootProps, getInputProps}) => (
													<section >
														<div className="dropzone">
														<div className="inner-text">
                                                            <p>
                                                                <span className="icon icon-upload">
                                                                    <svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                                        <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
                                                                    </svg>
                                                                </span>
                                                            </p>
                                                            <p>Drop files here or click to upload.</p>
														</div>
														</div>
														<aside>
														</aside>
													</section>
													)}
                                            </Dropzone> */}
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h5>Add Description</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group mb-0">
                                            <textarea value={this.state.description || ''} name="description" onChange={this.handleChange}>
                                                
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h5>Stores</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group mb-0">
                                            <div className="table-responsive">
                                                <ReactTable
                                                    PaginationComponent={Pagination}
                                                    data={data}
                                                    minRows={5}
                                                    columns={columns}
                                                    noDataText={this.state.isLoading ? '' : "No Stores Found."}
                                                    defaultPageSize={this.state.pageSize}
                                                    pageSizeOptions={[10, 25, 50, 100]}
                                                    // resizable={true}
                                                    onPageChange={(index) => this.handlePageChange(index)}
                                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                                />
                                            </div>            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h5>Publish</h5>
                                </div>
                                <div className="card-body">
                                <div className="form-group mb-0">
                                <div className="product-buttons text-center">
                                    <button disabled={this.state.isLoading}  type="submit" className="btn btn-primary">
                                        {
                                            this.state.isLoading ? (
                                                <div>
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                    Loading...
                                                </div>
                                            ) : 'Save'
                                        }
                                    </button>
                                   <Link to="/products"> <button type="button" className="btn btn-light">Discard</button></Link>
                                </div>
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                </form>
            </div>

        );
    }
}

export default EditProduct;