import React, { Component } from 'react';

class PersonCarryIcon extends Component {
    render() {
        return (
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="person-carry" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-person-carry fa-w-12 fa-5x"><path fill="currentColor" d="M368 96H208c-8.8 0-16 7.2-16 16v76.1l-52.7-52.7c-2.4-2.4-5.1-4.2-7.7-6.3 28.1-21.5 31.4-53.5 26.3-74.4C150 22.5 121.2 0 88 0 41.7 0 6.8 43.5 18.1 89.2c3.4 13.8 10.7 25.6 20.4 34.8C15.6 138.1 0 163.4 0 192v272c0 26.5 21.5 48 48 48s48-21.5 48-48v-66l3.6 3.1 13.1 71.5c4.4 23.8 27.8 43.8 56 38.5 25.7-4.7 43.3-29.5 38.4-55.8L193 378.7c-3.4-18.3-13-35.1-27.2-47.1L128 299.1v-39.2l28.1 28.1H368c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16zM88 32c18 0 34.3 12.2 38.8 30.5C133.5 89.6 112 112 88 112c-18 0-34.3-12.2-38.8-30.5C42.5 54.4 64 32 88 32zM64 464c0 8.8-7.2 16-16 16s-16-7.2-16-16V343.2l32 27.4V464zm105.4-208l-59.7-59.7c-1.6-1.6-3.6-2.4-5.6-2.4-4.1 0-8.1 3.2-8.1 8v111.9l49.2 42.1c8.6 7.3 14.4 17.5 16.5 28.6l14.1 76.6c1.6 8.7-4.2 17-12.8 18.6-1 .2-2 .3-2.9.3-7.6 0-14.3-5.4-15.7-13.1l-14.1-76.5c-.7-3.9-2.8-7.5-5.8-10.1l-75.6-64.7C38.1 306.3 32 293.1 32 279v-87c0-25.6 20.3-47.2 45.9-48H80c15.4 0 27.8 5.1 36.7 14.1l65.9 65.9H224c8.8 0 16 7.2 16 16s-7.2 16-16 16h-54.6zm182.6 0h-82.9c1.8-5 2.9-10.4 2.9-16 0-26.5-21.5-48-48-48v-64h128v128z" class=""></path></svg>
        );
    }
}

export default PersonCarryIcon;