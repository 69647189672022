import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../../components/loader/loader';
import { request } from '../../../constants/constants';
import StoreSvg from '../../../public/svg/stores.svg';
import Swal from 'sweetalert2';
import Modal from "react-responsive-modal";
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import ReactTable from 'react-table';
import history from '../../../history';



class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            status: null,
            xero_invoice_id: null,
            statusOptions: [
                {
                    label: 'Pending Payment',
                    value: 'pending_payment',
                },
                {
                    label: 'Processing',
                    value: 'processing',
                },
                {
                    label: 'On Hold',
                    value: 'on_hold',
                },
                {
                    label: 'Partial Shipped',
                    value: 'partial_shipped',
                },
                {
                    label: 'Shipped',
                    value: 'shipped',
                },
                {
                    label: 'Completed',
                    value: 'completed',
                },
                {
                    label: 'Cancelled',
                    value: 'cancelled',
                },
            ],
            pageSize: 10,
            currentPage: 1,
            selectedAll: {},
			selected: {},
            ship_date: moment(),
            weight: 0,
            provider: 'fast_way',
            type: 'satchel',
            size: 'A5',
            provider_type: 'standard',
            length: 0,
            width: 0,
            height: 0,
            updatedItems: [],
            addedItems: []
        }
        //this.handleViewInXero = this.handleViewInXero.bind(this);

    }
    handleViewInXero = () =>  {
        
    //    window.open('https://invoicing.xero.com/view/' + 'f020625c-d46c-462d-b047-16f82d8df540');
    window.open('https://invoicing.xero.com/view/' + this.state.xero_invoice_id);

    }
    handleSendtoXero = () => {
        const { match: { params } } = this.props; 
    
        const data = {
            status: this.state.status.value
        }
    
        this.setState({
            isLoading: true
        })
    
        request('PUT', `/hq/orders/create-xero-invoice/${params.orderId}`, data, true).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
        }).catch(err => {
            console.log(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    
    }

    handleSave = () => {
        const { match: { params } } = this.props;

        const data = {
            status: this.state.status.value,
        }

        if(this.state.address_line_1) {
            data.address_line_1 = this.state.address_line_1
        }

        if(this.state.suburb) {
            data.suburb = this.state.suburb
        }

        if(this.state.state) {
            data.state = this.state.state
        }

        if(this.state.postal_code) {
            data.postal_code = this.state.postal_code
        }

        if(this.state.hasOrderItemsUpdate) {
            data.order_items = [...this.state.addedItems, ...this.state.updatedItems]
        }

        this.setState({
            isLoading: true
        })

        request('PUT', `/hq/orders/${params.orderId}`, data, true).then(res => {
            console.log(res);
            this.setState({
                isLoading: false,
                addedItems: [],
                updatedItems: []
            })
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
        }).catch(err => {
            console.log(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })

    }

    handleStatusChange = status => {
        this.setState({
            status: status
        })
    }

    getCustomFieldsTotalPrice(customFields){
        let price = 0 ;
        if(customFields) {
            for (let customField of customFields) {
                if(customField.additionalPriceChecked && customField.value && customField.value !== '') {
                    price += customField.price
                }
            }
        }
        return price;
    }

    getBundleItemsCustomFieldsTotalPrice(bundleItems){
        let price = 0;
        if(bundleItems) {
            for (const item of bundleItems) {
                price += this.getCustomFieldsTotalPrice(item.custom_fields)
            }
        }
        return price;
    }


    formatNumber(number) {
        return Number(number).toFixed(2);
    }

    getOrderDetails() {
        const { match: { params } } = this.props;
        return request('GET', `/hq/orders/${params.orderId}`, {}, true)
    }

    getStoreShippingFlatRate() {
        if(this.state.order && this.state.order.store) {
            return this.state.order.store.flat_rate
        }
    }

    getItemsSubtotal() {
        let total = 0;
        if(this.state.order && this.state.order.order_items) {
            for (const item of this.state.order.order_items) {
                total += (
                    item.product.product_type === 'bundle' ? (
                        (
                            item.product.sale_type === 'amount' ?  item.product.online_price -  item.product.sale
                            :  item.product.sale_type === 'percent_off' ? ( item.product.online_price - (( item.product.sale / 100) *  item.product.online_price))
                            :  item.product.online_price
                        ) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                    ) : (
                        item.product.sale_type === 'amount' ?  item.product.online_price -  item.product.sale
                        :  item.product.sale_type === 'percent_off' ? ( item.product.online_price - (( item.product.sale / 100) *  item.product.online_price))
                        :  item.product.online_price
                    ) + this.getCustomFieldsTotalPrice(item.custom_fields)
                ) * item.item_quantity
            }

            total = total / 1.1
        }
        console.log(total)
        return total.toFixed(2);
    }

    getTotal() {
        let total = 0;
        if(this.state.order.order_items && this.state.order.order_items.length > 0) {
            console.log(this.getItemsSubtotal(), this.getGst(), this.getStoreShippingFlatRate())
            return (parseFloat(this.getItemsSubtotal()) + parseFloat( this.getGst() ) + parseFloat(this.getStoreShippingFlatRate()))

        }
    }

    getGst() {
        if(this.state.order && this.state.order.order_items) {
            console.log('getGst')
            console.log(this.getItemsSubtotal(), (this.state.order.local_pickup ? 0 : this.getStoreShippingFlatRate()))
            console.log(this.getItemsSubtotal() + (this.state.order.local_pickup ? 0 : this.getStoreShippingFlatRate()));
            const gst_value = (parseFloat(this.getItemsSubtotal()) + (this.state.order.local_pickup ? 0 : parseFloat(this.getStoreShippingFlatRate()))) * 0.1;
            return parseFloat(gst_value).toFixed(2);
        }
    }

    handleShippingAddressChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    showCreateLabelModal = e => {
        const items = this.state.order.order_items.filter(element => !element.shipment_id);
        this.setState({
            modal: true,
            items: items,
            orderId: this.state.order.id
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleShipDateChange = date => {
        this.setState({
            ship_date: date
        })
    }

    toggleRow(item) {
		const itemId = item.id;
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[itemId] = !this.state.selected[itemId];
		this.setState({
			selected: newSelected
		}, () => {
            let totalWeightItems = 0;

            for (const key in this.state.selected) {
                if (this.state.selected[key]) {
                    const selectedItemIndex = this.state.order.order_items.findIndex(item => item.id === key);
                    if(selectedItemIndex > -1) {
                        if(this.state.order.order_items[selectedItemIndex].product.product_type == 'bundle') {
                            if(this.state.order.order_items[selectedItemIndex].product.products) {
                                this.state.order.order_items[selectedItemIndex].product.products.forEach(element => {
                                    totalWeightItems += element.item.item_weight;
                                });
                            }
                        } else {
                            totalWeightItems += this.state.order.order_items[selectedItemIndex].product.item.item_weight; 
                        }
                        
                    }
                }
            }
            this.setState({
            weight: totalWeightItems,
            })
        });
	}

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }

    getStore(storeId) {
        return request('GET', `/hq/stores/${storeId}`, {}, true);
    }

    onAddProductCloseModal = e => {
        this.setState({
            addProductModal: false
        })
    }

    showAddProductModal = e => {
        this.setState({
            addProductModal: true
        })
    }

    onProductChange = e => {
        const products = this.state.products;
        const i = products.findIndex(product => product.id == e.target.value);
        if(i > -1) {
            const product = products[i];
            this.setState({
                currentProduct: product
            })
        }
    }

    handleProductsSizeChange = (i) => e => {
        const products = JSON.parse(JSON.stringify(this.state.currentProduct.products));
        const product = products[i];
        product.selectedSize = e.target.value;

        this.setState({
            currentProduct: {
                ...this.state.currentProduct,
                products
            }
        })
    }


    handleProductsColorChange = (i) => e => {
        console.log(i);
        const products = JSON.parse(JSON.stringify(this.state.currentProduct.products));
        const product = products[i];
        product.selectedColour = {
            name: e.target.value
        };

        this.setState({
           currentProduct: {
               ...this.state.currentProduct,
               products
           }
        })
    }

    handleProductsCustomFieldChange = (productIndex, customFieldIndex) => e => {
        const products = JSON.parse(JSON.stringify(this.state.currentProduct.products));
        const product = products[productIndex];
        const customFields = product.custom_fields;
        const customField = customFields[customFieldIndex];
        if(!customField) {
            return Swal.fire(
                'Error',
                'Custom field cannot found',
                'error'
            )
        } else {
            customField.value = e.target.value;
        }
        this.setState({
            currentProduct: {
                ...this.state.currentProduct,
                products
            }
        })
    }

    handleCustomFieldChange = customField => e => {
        const customFields = JSON.parse(JSON.stringify(this.state.currentProduct.custom_fields));
        if(!customFields) {
            customFields = [];
        }
        const foundIndex = customFields.findIndex(customField => customField.name == customField.name);
        if(foundIndex <= -1) {
           customField.value = e.target.value;
           customFields.push(customField)
        } else {
            customFields[foundIndex].value = e.target.value
        }

        this.setState({
            currentProduct: {
                ...this.state.currentProduct,
                custom_fields: customFields
            }
        })
    }

    handleAddProduct = e => {
        const currentProduct = JSON.parse(JSON.stringify(this.state.currentProduct));

        const items = [...this.state.order.order_items];
        const item = {
            product: currentProduct,
            ...this.state.currentProduct,
            custom_fields: this.state.currentProduct.custom_fields.filter(customField => (customField.value && customField.value !== '')),
            id: null,
            item_quantity: this.state.item_quantity,
            colour: {name: this.state.selectedColour},
            size: this.state.selectedSize,
            // products: this.state.currentProduct.products
        };
        items.unshift(item);
        console.log('items: ', items);
        this.setState({
            order: {
                ...this.state.order,
                order_items:  [...items],
            },
            addProductModal: false,
            currentProduct: undefined,
            selectedColour: '',
            selectedSize: '',
            hasOrderItemsUpdate: true,
            addedItems: [...this.state.addedItems, item]
        })
    }


    handleEditItem = item => e => {
        console.log('item: ', item)
        const data = {
            currentProduct: item,
            editOrderItemModal: true,
            item_quantity: item.item_quantity
        }
        if(item.size) {
            data.selectedSize = item.size
        }
        if(item.colour) {
            data.selectedColour = item.colour.name
        }
        this.setState(data)
    }

    onEditOrderItemCloseModal = e => {
        this.setState({
            editOrderItemModal: false
        })
    }

    showEditOrderItemModal = e => {
        this.setState({
            editOrderItemModal: true
        })
    }

    handleUpdateItem = e => {
        const currentProduct = JSON.parse(JSON.stringify(this.state.currentProduct));
        const index = this.state.order.order_items.findIndex(element => element.id == this.state.currentProduct.id);
        console.log('index: ', index)
        if(index <= -1) {
            return Swal.fire(
                'Error',
                'Invalid order item',
                'error'
            )
        }
        const order = this.state.order;
        const item = {
            product: currentProduct,
            ...this.state.currentProduct,
            custom_fields: this.state.currentProduct.custom_fields.filter(customField => (customField.value && customField.value !== '')),
            item_quantity: this.state.item_quantity,
            // colour: {name: this.state.selectedColour},
            // size: this.state.selectedSize,
        }
        if(this.state.selectedSize) {
            item.size = this.state.selectedSize
        }

        if(this.state.selectedColour) {
            item.colour = {name: this.state.selectedColour}
        }

        order.order_items[index] = item;

        const updatedItems = this.state.updatedItems;
        const foundIndex = this.state.updatedItems.findIndex(updatedItem => updatedItem.id == item.id);
        if(foundIndex > -1) {
            updatedItems[foundIndex] = item;
        } else {
            updatedItems.push(item)
        }

        this.setState({
            order: order,
            editOrderItemModal: false,
            currentProduct: undefined,
            selectedColour: '',
            selectedSize: '',
            hasOrderItemsUpdate: true,
            updatedItems
        })
    }

    getCustomFieldValue(name) {
        if(this.state.currentProduct) {
            if(this.state.currentProduct.custom_fields) {
                const foundIndex = this.state.currentProduct.custom_fields.findIndex(customField => customField.name == name)
                if(foundIndex > -1) {
                    return this.state.currentProduct.custom_fields[foundIndex].value;
                }
            }
        }
        return null;
    }

    handleCreateLabel = e => {
        this.setState({
            isLoading: true
        })
        const selectedItems = [];
        let totalWeightItems;

        for (const key in this.state.selected) {
            if (this.state.selected[key]) {
                selectedItems.push(key);
                const selectedItemIndex = this.state.order.order_items.findIndex(item => item.id == key);
                if(selectedItemIndex > -1) {
                    // totalWeightItems += this.state.order.order_items[selectedItemIndex].product.item.item_weight; 
                }
            }
        }

        if(selectedItems.length <= 0) {
            this.setState({
                isLoading: false
            })
            return Swal.fire(
                'Error',
                'Please select item',
                'error'
            )
        }

        const data = {
            ship_provider: this.state.provider,
            ship_provider_type: this.state.provider_type,
            ship_date: this.state.ship_date.format('YYYY-MM-DD'),
            // shipment_type: Object.keys(this.state.selected).length == this.state.items.length ? 'full' : 'partial',
            length: this.state.length,
            width: this.state.width,
            height: this.state.height,
            weight: this.state.weight,
            items: selectedItems,
            type: this.state.type,
            size: this.state.size
        }
        console.log(data);
        request('POST', `/hq/orders/${this.state.order.id}/shipments`, data, true)
        .then(res => {
            this.setState({
                isLoading: false
            })
            console.log(res);
            // this.init();
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            ).then(() => {
                history.push(`/shipments/${res.data.data.id}/detail`)
            })
        }).catch(err => {
            console.error(err.response)
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getOrderDetails().then(res => {
            console.log(res);
            const order = res.data.data;
            this.state.xero_invoice_id = res.data.data.xero_invoice_id;
            //console.log(this.state.xero_invoice_id);
            const index = this.state.statusOptions.findIndex(status => status.value == order.status);
            this.setState({
                isLoading: false,
                order: order,
                status: this.state.statusOptions[index],
                address_line_1: order.address_line_1,
                state: order.state,
                suburb: order.suburb,
                postal_code: order.postal_code
            })
            this.getStore(order.store_id).then(res => {
                console.log('store: ', res);
                this.setState({
                    products: res.data.data.products
                })
            })
        })
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const itemColumns = [
            {
				Header: (header) => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						{/* <input
							type="checkbox"
							className="custom-control-input"
							id="selectAllCheckBox"
							checked={this.state.selectedAll[this.state.currentPage] || false}
							onChange={() => this.toggleSelectAll()}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.indeterminateSign;
								}
							}}
						/>
						<label className="custom-control-label" htmlFor="selectAllCheckBox"></label> */}
					</div>
				),
				width: 52,
				Cell: row => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input type="checkbox" className="custom-control-input" id={`item-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original)} />
						<label className="custom-control-label" htmlFor={`item-id-${row.original.id}`}></label>
					</div>
				),
				className: 'header-checkbox-toggle',
				headerStyle: { padding: '1.07143em 0' },
				style: { padding: '0.71429em 0' }
				
			},
            {
                Header: 'Name',
                Cell: row => {
                    return (
                        <div className="d-flex  justify-content-start">
                            <div className="">
                                <img src={row.original.product.image[0]} />
                            </div>
                            <div className="pl-2">
                                <p className="m-0">{row.original.product.product_name}</p>
                                <p className="m-0">SKU: {row.original.product.SKU}</p>
                                <p className="m-0">Size: {row.original.size}</p>
                            </div>
                        </div>
                    )
                },
                width: 230
            },
            {
                Header: 'Qty',
                Cell: row => {
                    return <span>{row.original.item_quantity}</span>
                },
                width: 80
            },
            {
                Header: 'Total',
                Cell: row => {
                    return <span>${row.original.total}</span>
                },
                width: 100
            },
        ];
        const format = 'YYYY-MM-DD';
        const now = moment();

        const calendar = (<Calendar
			style={{ zIndex: 1000 }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={false}
			showClear={true}
		/>);
        return (
            <div className="page-body">
                <Modal
                    open={this.state.addProductModal} 
                    onClose={this.onAddProductCloseModal}
                    center={true}
                    classNames={{
                        overlay: 'custom-overlay',
                        modal: 'custom-modal'
                    }}
                    >
                            <div className="">
                            <div className="modal-header">
                                <h5 className="modal-title f-w-600" id="exampleModalLabel">Add Product</h5>
                            </div>
                            <div className="modal-body">
                                <label className="col-form-label pt-0">Product: </label>
                                <select name="currentProduct" onChange={this.onProductChange} class="custom-select" id="inputGroupSelect01">
                                    <option style={{display: 'none'}} value="">Choose...</option>
                                    {
                                        this.state.products && this.state.products.map((product, i) => (
                                            <option key={i} value={product.id}>{product.product_name}</option>
                                        ))
                                    }
                                </select>

                                <label className="col-form-label pt-0">Quantity: </label>
                                <input onChange={this.handleChange} type="text" name="item_quantity" className="form-control" aria-label="Text input with segmented dropdown button" />

                                {
                                    this.state.currentProduct && this.state.currentProduct.colours && (
                                        <div className="mb-8">
                                                {
                                                  this.state.currentProduct.colours.length > 0 && (
                                                        <React.Fragment>
                                                            <label >
                                                                Colour: 
                                                            </label>
                                                            <select onChange={this.handleChange} value={this.state.selectedColour} name="selectedColour" class="custom-select" id="inputGroupSelect01">
                                                                <option style={{display: 'none'}} value="">Choose...</option>
                                                                {
                                                                    this.state.currentProduct.colours && this.state.currentProduct.colours.map((colour, i) => (
                                                                        <option key={i} value={colour.name}>{colour.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </React.Fragment>
                                                    )
                                                }

                                                {
                                                    this.state.currentProduct && this.state.currentProduct.sizes && 
                                                    <React.Fragment>
                                                        
                                                        <label className="">
                                                                Size: 
                                                        </label>

                                                        <div className="mb-5">
                                                            <select onChange={this.handleChange} name="selectedSize" value={this.state.selectedSize} class="custom-select" id="inputGroupSelect01">
                                                                <option style={{display: 'none'}} value="">Choose...</option>
                                                                {
                                                                    this.state.currentProduct.sizes && this.state.currentProduct.sizes.map((size, i) => (
                                                                        <option key={i} value={size.size}>{size.size}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                        </div>
                                    )
                                }

                                {
                                    this.state.currentProduct && this.state.currentProduct.product_type == 'bundle' && (
                                        <div className="">
                                            <label className="mt-2">Bundle items: </label>
                                            {
                                                this.state.currentProduct.products && this.state.currentProduct.products.map((product, productIndex) => (
                                                    <div className="mb-5 p-5 border-bottom" key={productIndex}>
                                                        {
                                                            product.product_name && <p className="m-0">Item: {product.product_name}</p>
                                                        }

                                                            {
                                                                product.colours.length > 0 && (
                                                                    <React.Fragment>
                                                                        <label >
                                                                            Colour: 
                                                                        </label>
                                                                        <select onChange={this.handleProductsColorChange(productIndex)} value={product.selectedColour && product.selectedColour.value} name="selectedColour" class="custom-select" id="inputGroupSelect01">
                                                                            <option style={{display: 'none'}} value="">Choose...</option>
                                                                            {
                                                                                product.colours && product.colours.map((colour, i) => (
                                                                                    <option key={i} value={colour.name}>{colour.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </React.Fragment>
                                                                )
                                                            }

                                                            {
                                                               product && product.sizes && 
                                                                <React.Fragment>
                                                                    
                                                                    <label className="">
                                                                            Size: 
                                                                    </label>

                                                                    <div className="mb-5">
                                                                        <select onChange={this.handleProductsSizeChange(productIndex)} name="selectedSize" value={product.selectedSize} class="custom-select" id="inputGroupSelect01">
                                                                            <option style={{display: 'none'}} value="">Choose...</option>
                                                                            {
                                                                                product.sizes && product.sizes.map((size, i) => (
                                                                                    <option key={i} value={size.size}>{size.size}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        
                                                        {
                                                            product && product.custom_fields && 
                                                            product.custom_fields.map((customField, i) => (
                                                            <div key={i} className="form-group">
                                                                <label>{customField.name} {customField.additionalPriceChecked && <strong>${customField.price}</strong>} {customField.requiredChecked && <span className="text-danger">(required)</span>}: </label>
                                                                <input onChange={this.handleProductsCustomFieldChange(productIndex, i)} name={customField.name} value={customField.value} required={customField.requiredChecked} className="form-control" type={customField.type} />
                                                            </div>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }

                                {
                                    this.state.currentProduct && this.state.currentProduct.custom_fields && 
                                    this.state.currentProduct.custom_fields.map((customField, i) => (
                                    <div key={i} className="form-group">
                                        <label>{customField.name} {customField.additionalPriceChecked && <strong>${customField.price}</strong>} {customField.requiredChecked && <span className="text-danger">(required)</span>}: </label>
                                        <input onChange={this.handleCustomFieldChange(customField)} name={customField.name} value={customField.value} required={customField.requiredChecked} className="form-control" type={customField.type} />
                                    </div>
                                    ))
                                }
                               
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.handleAddProduct} className="btn btn-default" type="button" data-dismiss="modal">Add</button>
                                <button onClick={this.onAddProductCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                </Modal>

                <Modal
                    open={this.state.editOrderItemModal} 
                    onClose={this.onEditOrderItemCloseModal}
                    center={true}
                    classNames={{
                        overlay: 'custom-overlay',
                        modal: 'custom-modal'
                    }}
                    >
                            <div className="">
                            <div className="modal-header">
                                <h5 className="modal-title f-w-600" id="exampleModalLabel">Edit Item</h5>
                            </div>
                            <div className="modal-body">
                                <label className="col-form-label pt-0">Product: </label>
                                <select value={this.state.currentProduct && this.state.currentProduct.product && this.state.currentProduct.product.id} name="currentProduct" onChange={this.onProductChange} class="custom-select" id="inputGroupSelect01">
                                    <option style={{display: 'none'}} value="">Choose...</option>
                                    {
                                        this.state.products && this.state.products.map((product, i) => (
                                            <option key={i} value={product.id}>{product.product_name}</option>
                                        ))
                                    }
                                </select>

                                <label className="col-form-label pt-0">Quantity: </label>
                                <input readOnly type="number" value={this.state.item_quantity} onChange={this.handleChange} type="text" name="item_quantity" className="form-control" aria-label="Text input with segmented dropdown button" />

                                {
                                    this.state.currentProduct && this.state.currentProduct.product && (
                                        <div className="mb-8">
                                                {
                                                 this.state.currentProduct.product.colours && this.state.currentProduct.product.colours.length > 0 && (
                                                        <React.Fragment>
                                                            <label >
                                                                Colour: 
                                                            </label>
                                                            <select onChange={this.handleChange} value={this.state.selectedColour} name="selectedColour" class="custom-select" id="inputGroupSelect01">
                                                                <option style={{display: 'none'}} value="">Choose...</option>
                                                                {
                                                                    this.state.currentProduct.product.colours && this.state.currentProduct.product.colours.map((colour, i) => (
                                                                        <option key={i} value={colour.name}>{colour.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </React.Fragment>
                                                    )
                                                }

                                                {
                                                    this.state.currentProduct.product.sizes && 
                                                    <React.Fragment>
                                                        <label className="">
                                                                Size: 
                                                        </label>

                                                        <div className="mb-5">
                                                            <select onChange={this.handleChange} name="selectedSize" value={this.state.selectedSize} class="custom-select" id="inputGroupSelect01">
                                                                <option style={{display: 'none'}} value="">Choose...</option>
                                                                {
                                                                    this.state.currentProduct.product.sizes && this.state.currentProduct.product.sizes.map((size, i) => (
                                                                        <option key={i} value={size.size}>{size.size}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                        </div>
                                    )
                                }

                                {
                                    this.state.currentProduct && this.state.currentProduct.product_type == 'bundle' && (
                                        <div className="">
                                            <label className="mt-2">Bundle items: </label>
                                            {
                                                this.state.currentProduct.products && this.state.currentProduct.products.map((product, productIndex) => (
                                                    <div className="mb-5 p-5 border-bottom" key={productIndex}>
                                                        {
                                                            product.product_name && <p className="m-0">Item: {product.product_name}</p>
                                                        }

                                                            {
                                                                product.colours.length > 0 && (
                                                                    <React.Fragment>
                                                                        <label >
                                                                            Colour: 
                                                                        </label>
                                                                        <select onChange={this.handleProductsColorChange(productIndex)} value={product.selectedColour && product.selectedColour.value} name="selectedColour" class="custom-select" id="inputGroupSelect01">
                                                                            <option style={{display: 'none'}} value="">Choose...</option>
                                                                            {
                                                                                product.colours && product.colours.map((colour, i) => (
                                                                                    <option key={i} value={colour.name}>{colour.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </React.Fragment>
                                                                )
                                                            }

                                                            {
                                                               product && product.sizes && 
                                                                <React.Fragment>
                                                                    
                                                                    <label className="">
                                                                            Size: 
                                                                    </label>

                                                                    <div className="mb-5">
                                                                        <select onChange={this.handleProductsSizeChange(productIndex)} name="selectedSize" value={product.selectedSize} class="custom-select" id="inputGroupSelect01">
                                                                            <option style={{display: 'none'}} value="">Choose...</option>
                                                                            {
                                                                                product.sizes && product.sizes.map((size, i) => (
                                                                                    <option key={i} value={size.size}>{size.size}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        
                                                        {
                                                            product && product.custom_fields && 
                                                            product.custom_fields.map((customField, i) => (
                                                            <div key={i} className="form-group">
                                                                <label>{customField.name} {customField.additionalPriceChecked && <strong>${customField.price}</strong>} {customField.requiredChecked && <span className="text-danger">(required)</span>}: </label>
                                                                <input onChange={this.handleProductsCustomFieldChange(productIndex, i)} name={customField.name} value={customField.value} required={customField.requiredChecked} className="form-control" type={customField.type} />
                                                            </div>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }

                                {
                                    this.state.currentProduct && this.state.currentProduct.product && this.state.currentProduct.product.custom_fields && 
                                    this.state.currentProduct.product.custom_fields.map((customField, i) => (
                                    <div key={i} className="form-group">
                                        <label>{customField.name} {customField.additionalPriceChecked && <strong>${customField.price}</strong>} {customField.requiredChecked && <span className="text-danger">(required)</span>}: </label>
                                        <input onChange={this.handleCustomFieldChange(customField)} name={customField.name} value={this.getCustomFieldValue(customField.name)} required={customField.requiredChecked} className="form-control" type={customField.type} />
                                    </div>
                                    ))
                                }
                               
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.handleUpdateItem} className="btn btn-default" type="button" data-dismiss="modal">Update</button>
                                <button onClick={this.onEditOrderItemCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                </Modal>
 
                <Modal
                        open={this.state.modal} 
                        onClose={this.onCloseModal}
                        center={true}
                        classNames={{
                        overlay: 'custom-overlay',
                        modal: 'custom-modal modal-lg'
                    }}
                >
                    <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="createLabel">Create Label</h5>
                    </div>
                    <div className="modal-body modal-lg">

                        <label className="mb-1">Select Items:</label>

                        <div className="table-responsive mb-4">
                        <ReactTable
                                // PaginationComponent={Pagination}
                                data={this.state.order && this.state.order.order_items}
                                minRows={1}
                                columns={itemColumns}
                                showPagination={false}
                                noDataText={this.state.isLoading ? '' : "No Orders Found."}
                                // defaultPageSize={this.state.pageSize}
                                // pageSizeOptions={[10, 25, 50, 100]}
                                // resizable={true}
                                // onPageChange={(index) => this.handlePageChange(index)}
                                // onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                // getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                            />
                        </div>

                        <div className="form-group">
                            <label className="mb-1">Ship Date :</label>
                            <div className="datepicker"  id="datepicker">

                            </div>

                            <DatePicker
                                animation="slide-up"
                                disabled={false}
                                calendar={calendar}
                                value={this.state.ship_date}
                                onChange={this.handleShipDateChange}
                                name="ship_date"
                                getCalendarContainer={() => document.getElementById('datepicker')}
                                className="custom-calendar"
                                showClear={true}
                            >
                                {
                                    ({ value }) => {
                                        return (
                                            <div className="input-group">
                                                <input
                                                    placeholder="Select Date"
                                                    disabled={false}
                                                    readOnly
                                                    tabIndex="-1"
                                                    className="ant-calendar-picker-input ant-input form-control"
                                                    value={value == null ? '' : moment(value).format(format)}
                                                />
                                            </div>
                                        );
                                    }
                                }
                            </DatePicker>
                        </div>
                        <div className="form-group">
                            <label className="mb-1">Weight (kg) :</label>
                            <input name="weight" readOnly value={this.state.weight} type="number" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label className="mb-1">Service :</label>
                            <select className="custom-select" onChange={this.handleChange} name="provider" value={this.state.provider}>
                                <option value="fast_way">Fast Way</option>
                                {/* <option value="au_post">Australia Post</option> */}
                            </select>
                        </div>

                        <div className="form-group">
                            <label className="mb-1">Type :</label>
                            <select className="custom-select" onChange={this.handleChange} name="type" value={this.state.type}>
                                <option value="parcel">Parcel</option>
                                <option value="satchel">Satchel</option>
                            </select>
                        </div>

                        {
                            this.state.type === 'satchel' && (
                                <div className="form-group">
                                    <label className="mb-1">Size :</label>
                                    <select className="custom-select" onChange={this.handleChange} name="size" value={this.state.size}>
                                        <option value="A5">A5</option>
                                        <option value="A4">A4</option>
                                        <option value="A3">A3</option>
                                        <option value="A2">A2</option>
                                    </select>
                                </div>
                            )
                        }

                        {
                            this.state.type === 'parcel' && (
                                <div className="form-group">
                                    <label className="mb-1">Size (cm) :</label>
                                    <div className="row">
                                        <div className="col input-group">
                                            <input onChange={this.handleChange} name="length" value={this.state.length} type="number" className="form-control" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">L</span>
                                            </div>
                                        </div>
                                        <div className="col input-group">
                                            <input onChange={this.handleChange} name="width" value={this.state.width} type="number" className="form-control" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">W</span>
                                            </div>
                                        </div>
                                        <div className="col input-group">
                                            <input onChange={this.handleChange} name="height" value={this.state.height} type="number" className="form-control" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">H</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <div className="form-group">
                            <label className="mb-1">Service Type:</label>
                            <select className="custom-select" onChange={this.handleChange} name="provider_type" value={this.state.provider_type}>
                                <option value="standard">Standard</option>
                                {/* <option value="leave_at_door">Leave at door</option> */}
                            </select>
                        </div>
                    
                    </div>
                    <div className="modal-footer">
                        <button disabled={this.state.isLoading} onClick={this.handleCreateLabel} className="btn btn-secondary" type="button">
                        {
                            this.state.isLoading ? (
                                <div>
                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                    Loading...
                                </div>
                            ) : 'Save'
                        }
                        </button>
                        <button onClick={this.onCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                    </div>

                </Modal>

                {this.state.isLoading && <Loader />}
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3> Order #{this.state.order && this.state.order.order_number}
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to="/orders">Orders</Link></li>
                                    <li className="breadcrumb-item active">#{this.state.order && this.state.order.order_number}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5> General </h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Status</label>
                                        <Select
                                            value={this.state.status}
                                            onChange={this.handleStatusChange}
                                            options={this.state.statusOptions}
                                        />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Customer</label>
                                        <p>
                                            {this.state.order && this.state.order.first_name} {this.state.order && this.state.order.last_name}
                                        </p>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Store</label>
                                        <Link to={`/stores/${this.state.order && this.state.order.store.id}/detail`}>
                                            <p>{this.state.order && this.state.order.store.store_name}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5> Billing </h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <p className="mb-0">{this.state.order && this.state.order.first_name} {this.state.order && this.state.order.last_name}</p>
                                        <p className="mb-0">{this.state.order && this.state.order.address_line_1}</p>
                                        <p className="mb-0">{this.state.order && this.state.order.address_line_2} {this.state.order && this.state.order.suburb} {this.state.order && this.state.order.state} {this.state.order && this.state.order.country} {this.state.order && this.state.order.postal_code}</p>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Email</label>
                                        <p>
                                            {this.state.order && this.state.order.email}
                                        </p>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Phone</label>
                                        <p>
                                            {this.state.order && this.state.order.phone}
                                        </p>
                                    </div>
                                    {this.state.xero_invoice_id ?
                                    
                                        <div className="form-group mb-4">
                                            <label className="col-form-label pt-0">XERO</label>
                                            <p><button onClick={this.handleViewInXero.bind(this)} className="btn btn-primary">View In XERO</button></p>
                                        </div>
                                        :
                                        <div className="form-group mb-4">
                                            <label className="col-form-label pt-0">XERO</label>
                                            <p><button onClick={this.handleSendtoXero.bind(this)}className="btn btn-primary">Send to XERO</button></p>
                                        </div>
                                    }


                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5> Shipping </h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        {/* <p className="mb-0">{this.state.order && this.state.order.first_name} {this.state.order && this.state.order.last_name}</p> */}
                                        Address line 1: <input onChange={this.handleShippingAddressChange} className="mb-0 form-control" type="text" name="address_line_1" value={this.state.order && this.state.address_line_1} />
                                        Suburb: <input onChange={this.handleShippingAddressChange} name="suburb" className="mb-0 form-control" value={this.state.order && this.state.suburb} />
                                        State: <input onChange={this.handleShippingAddressChange} name="state" className="mb-0 form-control" value={this.state.order && this.state.state} />
                                        Country: <input readOnly className="mb-0 form-control" value={this.state.order && this.state.order.country} />
                                        Postal code: <input onChange={this.handleShippingAddressChange} name="postal_code" className="mb-0 form-control" value={this.state.order && this.state.postal_code} />
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Shipments</label>
                                        {
                                            this.state.order && (this.state.order.shipments ? (

                                                this.state.order && this.state.order.shipments && this.state.order.shipments.map((shipment, i) => {
                                                    return (
                                                        <div key={i} className="mb-2 card p-2">
                                                            <Link to={`/shipments/${shipment.id}/detail`} className="mb-2 border-bottom">Shipment #{i + 1}</Link>
                                                            <p className="mb-0">Ship provider: {shipment.ship_provider === 'fast_way' && 'Fast Way'}</p>
                                                            {/* <p className="mb-0">Shipment id: <Link to="/shipments">{shipment.id}</Link></p> */}
                                                            <p className="mb-0">Ship date: {moment(shipment.ship_date).format('MMMM DD, YYYY')}</p>
                                                            {/* <p className="mb-0">Tracking no: { shipment.tracking_no }</p> */}
                                                            {
                                                                shipment.fast_way && (
                                                                    <React.Fragment>
                                                                        <p className="mb-0">Consignment Id: {shipment.fast_way.consignment_id}</p>
                                                                        <p className="mb-0">Label: {shipment.fast_way.labels[0]}</p>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })

                                            ) : (
                                                <div className="">
                                                    <button onClick={this.showCreateLabelModal} className="btn btn-primary">Create Label</button>
                                                </div>
                                            ) )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='d-flex justify-content-between'>
                                            <h5> Item(s) </h5>
                                            <button onClick={this.showAddProductModal} className='btn btn-secondary'>ADD ITEM</button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-borderless border">
                                                <thead className="bg-light text-dark">
                                                    <tr>
                                                        <th colSpan="2" scope="col">Item</th>
                                                        <th scope="col">Cost</th>
                                                        <th scope="col">Qty</th>
                                                        <th scope="col">Total</th>
                                                        <th scope='col'>Action</th>
                                                        {/* <th scope="col">GST</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {
                                                        this.state.order && this.state.order.order_items.map((item, i) => (
                                                            <tr key={i} className="border-bottom">
                                                                {
                                                                    item.product.product_type === 'bundle' ? (
                                                                        <td colSpan="2">
                                                                            {item.product.product_name}
                                                                            <p className="my-2">Items: </p>
                                                                            {
                                                                                item.products && item.products.map(product => (
                                                                                    <div className="mb-2 pl-4">
                                                                                        <img className="mr-2" width="50" height="50" alt="" src={product.image[0]} />

                                                                                        {product.product_name}
                                                                                        {
                                                                                            product.selectedSize && <p className="m-0">Size:
                                                                                                {/* <input className="form-control" value={item.size} /> */}
                                                                                                <span>{product.selectedSize}</span>
                                                                                            </p>
                                                                                        }
                                                                                        {product.selectedColour && <p className="mb-0">Colour: {product.selectedColour.name}</p>}

                                                                                        {
                                                                                            product.custom_fields && product.custom_fields.map((customField, i) => (
                                                                                                <p className="mb-0" key={i}>{customField.name}: {customField.value}</p>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </td>

                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <th scope="row" style={{ width: 50 }}>
                                                                                <img width="50" height="50" src={item.product.image[0]} />
                                                                            </th>
                                                                            <td>
                                                                                {item.product.product_name}
                                                                                {
                                                                                    item.size && <p className="m-0">Size:
                                                                                        {/* <input className="form-control" value={item.size} /> */}
                                                                                        <span>{item.size}</span>
                                                                                    </p>
                                                                                }
                                                                                {item.colour && <p className="mb-0">Colour: {item.colour.name}</p>}

                                                                                {
                                                                                    item.custom_fields && item.custom_fields.map((customField, i) => (
                                                                                        <p className="mb-0" key={i}>{customField.name}: {customField.value}</p>
                                                                                    ))
                                                                                }

                                                                            </td>

                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                <td>
                                                                    $ {
                                                                        this.formatNumber( item.product.product_type === 'bundle' ? (
                                                                            (
                                                                                item.product.sale_type === 'amount' ? item.product.online_price - item.product.sale
                                                                                : item.product.sale_type === 'percent_off' ? (item.product.online_price - ((item.product.sale / 100) * item.product.online_price))
                                                                                : item.product.online_price
                                                                            ) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                                                                        ) : (
                                                                            item.product.sale_type === 'amount' ? item.product.online_price - item.product.sale
                                                                            : item.product.sale_type === 'percent_off' ? (item.product.online_price - ((item.product.sale / 100) * item.product.online_price))
                                                                            : item.product.online_price
                                                                        ) + this.getCustomFieldsTotalPrice(item.custom_fields))
                                                                    }
                                                                </td>
                                                                <td>x {item.item_quantity}</td>
                                                                <td>${ this.formatNumber( 
                                                                    (item.product.product_type === 'bundle' ? (
                                                                        (
                                                                            item.product.sale_type === 'amount' ? item.product.online_price - item.product.sale
                                                                            : item.product.sale_type === 'percent_off' ? (item.product.online_price - ((item.product.sale / 100) * item.product.online_price))
                                                                            : item.product.online_price
                                                                        ) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                                                                    ) : (
                                                                        item.product.sale_type === 'amount' ? item.product.online_price - item.product.sale
                                                                        : item.product.sale_type === 'percent_off' ? (item.product.online_price - ((item.product.sale / 100) * item.product.online_price))
                                                                        : item.product.online_price
                                                                    ) + this.getCustomFieldsTotalPrice(item.custom_fields)) * item.item_quantity
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <button onClick={this.handleEditItem(item)} className='btn btn-secondary'>Edit</button>
                                                                </td>
                                                                {/* <td>${this.formatNumber(item.gst)}</td> */}
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>

                                            </table>
                                            <table className="table table-borderless border">
                                                <thead className="bg-light text-dark">
                                                    <tr>
                                                        <th colSpan="2" scope="col"> &nbsp;</th>
                                                        <th scope="col"> &nbsp;</th>
                                                        <th scope="col"> &nbsp;</th>
                                                        <th scope="col">Total</th>
                                                        <th scope="col">GST</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="border-bottom">
                                                        <th scope="row" style={{ width: 50 }}>
                                                            <img width="50" height="50" src={StoreSvg} />
                                                        </th>
                                                        <td>
                                                            <p className="m-0">Shipping</p>
                                                            <p className="m-0">{this.state.order && this.state.order.local_pickup ? 'Local Pickup' : 'Flat Rate'}</p>
                                                            {this.state.order && this.state.order.local_pickup && (
                                                                <p className="m-0">Location: {(this.state.order.local_pickup_location && this.state.order.local_pickup_location.location_name) ? this.state.order.local_pickup_location.location_name : 'Local Pickup'}</p>
                                                            )}
                                                        </td>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                        <td>&nbsp;</td>
                                                        <td>${this.state.order && this.state.order.shipping_total}</td>
                                                        <td>${this.state.order && this.state.order.shipping_gst}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-7 align-self-end">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5>Publish</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group mb-0">
                                                            <div className="product-buttons">
                                                                <button disabled={this.state.isLoading} onClick={this.handleSave} className="btn btn-primary">
                                                                    {
                                                                        this.state.isLoading ? (
                                                                            <div>
                                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                                                Loading...
                                                                            </div>
                                                                        ) : 'Save'
                                                                    }
                                                                </button>
                                                                <Link to="/orders"> <button type="button" className="btn btn-light">Cancel</button></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-5">
                                                <ul className="list-group list-group-sm list-group-flush-y list-group-flush-x">
                                                    <li className="list-group-item d-flex">
                                                        <span>Items Subtotal</span>
                                                        <span className="ml-auto font-size-sm">${this.state.order && this.getItemsSubtotal()}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex"><span>Shipping</span>  <span className="ml-auto font-size-sm">${this.state.order &&  this.state.order.local_pickup ? 0 : this.getStoreShippingFlatRate()}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex"><span>GST</span>  <span className="ml-auto font-size-sm">${this.state.order && this.getGst()}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex font-size-lg font-weight-bold"><span>Total</span>  <span className="ml-auto">${this.state.order && this.getTotal()}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default OrderDetails;