import React, { Component } from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../../components/pagination/pagination';
import Loader from '../../../../components/loader/loader';
import Modal from 'react-responsive-modal';
import { request } from '../../../../constants/constants';
import Swal from 'sweetalert2';

class Shipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            localPickUp: false,
            modal: false,
            location_name: '',
            location_address: '',
            flat_rate: undefined
        }
    }

    handleThProps(state, rowInfo, column, instance) {
        return {
            onClick: (e) => {
                if (column.sortable !== false) {
                    if (column.className !== 'header-checkbox-toggle') {
                        instance.sortColumn(column)
                    }
                }
            }
        }
    }

    handlePageChange(data) {
        this.setState({ currentPage: data + 1 });
    }

    handlePageSizeChange(data) {
        if (this.state.pageSize < data) {
            this.setState({ pageSize: data }, () => {
                // if (this.state.selectedAll[this.state.currentPage] === true) {
                // 	this.setState({
                // 		indeterminateSign: true
                // 	})
                // }
            });
        } else {
            this.setState({ pageSize: data }, () => {
                // const start = (this.state.currentPage - 1) * this.state.pageSize;
                // const end = start + this.state.pageSize;
                // let newSelected = {};
                // let newSelectPage;
                // if (this.state.selectedAll[this.state.currentPage] === true) {
                // 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
                // 	this.state.teams.forEach(data => {
                // 		newSelected[data.id] = false;
                // 	});
                // 	this.state.teams.slice(start, end).forEach(data => {
                // 		newSelected[data.id] = true;
                // 	});
                // 	let newObj = Object.assign(this.state.selected, newSelected);
                // 	const selectedList = Object.keys(newObj).filter(obj => {
                // 		return newObj[obj] === true;
                // 	});
                // 	// this.props.selectedList(selectedList);
                // 	this.setState({
                // 		selected: newObj,
                // 		selectedAll: newSelectPage,
                // 		indeterminateSign: false
                // 	});
                // }
            });
        }
    }

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }

    openModal = () => {
        this.setState({
            modal: true
        })
    }
    

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChange = e => {
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value === 'true' ? true : false
        })
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        const locations = this.state.locations;
        locations.splice(index,1);
        this.setState({
            locations
        })
    }
    

    handleAddLocation = e => {
        const locations = this.state.locations;
        locations.push({
            location_name: this.state.location_name,
            location_address: this.state.location_address,
        })
        this.setState({
            locations
        })
        this.onCloseModal();
    }

    hanldeSubmit = e => {
        e.preventDefault();
        e.target.classList.add('was-validated');
        console.log(e.target.checkValidity())
        if(e.target.checkValidity()) {
            this.setState({
                isLoading: true
            });
            const data = {
                local_pickup_locations: this.state.locations,
                local_pickup: this.state.localPickUp,
                flat_rate: this.state.flat_rate
            }
            request('PUT', `/hq/stores/${this.props.match.params.storeId}`, data, true)
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading: false
                })
                this.props.refresh();
                Swal.fire(
                    'Success',
                    res.data.message,
                    'success'
                )
            }).catch(err => {
                console.error(err);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Error!',
                    err.response.data.message,
                    'error'
                )
            })
        }
    }

    componentDidMount() {
        console.log(this.props.store)
        this.setState({
            flat_rate: this.props.store.flat_rate,
            localPickUp: this.props.store.local_pickup,
            locations: this.props.store.local_pickup_locations ? this.props.store.local_pickup_locations : []
        })
    }

    render() {
        const data = this.state.locations;
        const columns = [
            {
                Header: 'Name',
                accessor: 'location_name'
            },
            {
                Header: 'Address',
                Cell: row => (
                    <span>{row.original.location_address}</span>
                ),
            },
            {
                Header: 'Action',
                Cell: row => (
                    <React.Fragment>
                        <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
                            {
                                row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Remove'
                            }
                        </button>
                    </React.Fragment>
                ),
            },
        ];
        return (
            <React.Fragment>
            <Modal
                    open={this.state.modal} 
                    onClose={this.onCloseModal}
                    center={true}
                    classNames={{
                    overlay: 'custom-overlay',
                    modal: 'custom-modal'
                }}
            >
                    <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="exampleModalLabel">Add Location</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="mb-1">Location Name :</label>
                            <input onChange={this.handleChange} name="location_name" value={this.state.location_name || ''} type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label className="mb-1">Location Address :</label>
                            <input onChange={this.handleChange} name="location_address" value={this.state.location_address || ''} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button onClick={this.handleAddLocation} className="btn btn-secondary" type="button">ADD</button>
                        <button onClick={this.onCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                    </div>
                    </div>
            </Modal>
            <form onSubmit={this.hanldeSubmit} className="needs-validation user-add" noValidate>
                {/* <h4>Shipping</h4> */}
                <div className="form-group row">
                    <label htmlFor="validationCustom0" className="col-xl-3 col-md-4"><span>*</span>Flat Rate</label>
                    <input onChange={this.handleChange} name="flat_rate" value={this.state.flat_rate || ''} className="form-control col-xl-8 col-md-7" id="validationCustom0" type="number" required />
                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-4">
                        <label>Local Pickup</label>
                    </div>
                    <div className="col-xl-8 col-md-7">
                        <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label className="d-block" htmlFor="edo-ani9">
                                <input checked={this.state.localPickUp} onChange={this.onChange} className="radio_animated" value="true" id="edo-ani9" type="radio" name="localPickUp" />
                        Enable
                    </label>
                            <label className="d-block" htmlFor="edo-ani10">
                                <input checked={!this.state.localPickUp} onChange={this.onChange} className="radio_animated" value="false" id="edo-ani10" type="radio" name="localPickUp"  />
                        Disable
                    </label>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-4">
                    </div>
                    <div className="table-responsive  col-xl-8 col-md-7">
                       
                        {
                          this.state.localPickUp && (
                            this.state.isLoading ? <Loader /> : (
                               <React.Fragment>
                                <div className="page-header pt-0">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-6 col-md-">
                                            <div className="page-header-left">
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <button type="button" onClick={this.openModal} className="btn btn-secondary pull-right">Add Location</button>
                                        </div>
                                    </div>
                                </div>
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Locations Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    // resizable={true}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                />
                               </React.Fragment>
                            )
                          )
                        }

                        <div className="row mt-5">
                            <div className="col">
                                <button type="submit" className="btn btn-primary pull-right">Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </React.Fragment>
        );
    }
}

export default Shipping;