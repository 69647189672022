import React, { Component } from 'react';
import Loader from '../../../components/loader/loader';
import { Link } from 'react-router-dom';
import { request } from '../../../constants/constants';
import Swal from 'sweetalert2';
import history from '../../../history';

class DecorationSupplierDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            designs: [],
            decoration_name: '',
            decoration_code: '',
            email: '',
            address: '',
            contact_person: '',
            website: '',
            design_id: undefined,
            customDataChecked: false
        }
    }

    handleChange = e => {
        let value = e.target.value;

        if(e.target.type === 'checkbox') {
            value = e.target.checked
        }

        this.setState({
            [e.target.name]: value
        })
    }

    handleCancel = e => {
        history.push('/decoration-suppliers');
    }

    handleSubmit = e => {
   
        e.preventDefault();
        e.target.classList.add('was-validated');
        console.log(e.target.checkValidity());
        if(e.target.checkValidity()) {
            this.setState({
                isLoading: true
            })
            const data = {
                decoration_name: this.state.decoration_name,
                decoration_code: this.state.decoration_code,
                email: this.state.email,
                address: this.state.address,
                contact_person: this.state.contact_person,
                website: this.state.website,
                design_id: this.state.design_id,
                custom_data_checked: this.state.customDataChecked
            }
            request('PUT', `/hq/decoration-suppliers/${this.props.match.params.decorationSupplierId}`, data, true).then(res => {
                console.log(res);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Success!',
                    res.data.message,
                    'success'
                );
                history.push('/decoration-suppliers');
            }).catch(err => {
                console.error(err.response);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Error!',
                    err.response.data.message,
                    'error'
                );
            })
        }
    }

    getAlldesigns() {
        return request('GET', '/hq/designs', {}, true);
    }

    getSupplier() {
        return request('GET', `/hq/decoration-suppliers/${this.props.match.params.decorationSupplierId}`, {}, true);
    }

    async init() {
        this.setState({
            isLoading: true
        });
        try {
            const decorationSupplierData = await this.getSupplier();
            // const designsData = await this.getAlldesigns();
            const decorationSupplier = decorationSupplierData.data.data;

            this.setState({
                isLoading: false,
                // designs: designsData.data.data,
                decorationSupplier: decorationSupplier,
                decoration_name: decorationSupplier.decoration_name,
                decoration_code: decorationSupplier.decoration_code,
                email: decorationSupplier.email,
                address: decorationSupplier.address,
                contact_person: decorationSupplier.contact_person,
                website: decorationSupplier.website,
                customDataChecked: decorationSupplier.custom_data_checked
                // design_id: decorationSupplier.design_id
            })
        } catch (err) {
            console.error(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            ).then(() => {
                history.push('/decoration-suppliers');
            })
        }
    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        return (
            <div className="page-body">
            {this.state.isLoading && <Loader />}
         
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="page-header-left">
                                <h3> {this.state.decorationSupplier && this.state.decorationSupplier.decoration_name}
          {/* <small>Multikart Admin panel</small> */}
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ol className="breadcrumb pull-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item"><Link to="/decoration-suppliers">Suppliers</Link></li>
                                {/* <li className="breadcrumb-item"><Link to={`/decoration-suppliers/${this.props.match.params.decorationSupplierId}`}>{this.state.design && this.state.design.design_name}</Link></li> */}
                                <li className="breadcrumb-item active">{this.state.decorationSupplier && this.state.decorationSupplier.decoration_name}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                <div className="row product-adding add-product-form ">
                    
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h5>General</h5>
                            </div>
                            <div className="card-body">
                                <div className="digital-add needs-validation">
                                    <div className="form-group">
                                        <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Name</label>
                                        <input onChange={this.handleChange} name="decoration_name" value={this.state.decoration_name} className="form-control" id="validationCustom01" type="text" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span className="text-primary">*</span> Code</label>
                                        <input   onChange={this.handleChange} name="decoration_code" value={this.state.decoration_code} className="form-control" id="validationCustomtitle" type="text" required />
                                    </div>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input onChange={this.handleChange} checked={this.state.customDataChecked} name="customDataChecked" type="checkbox" className="custom-control-input" id="customDataChecked" />
                                            <label className="custom-control-label" htmlFor="customDataChecked">Custom Data ?</label>
                                        </div>
                                    </div>                                  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                    <div className="card">
                            <div className="card-header">
                                <h5>Other Details</h5>
                            </div>
                            <div className="card-body">
                                <div className="digital-add needs-validation">
                                    {/* <div className="form-group">
                                        <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Name</label>
                                        <select onChange={this.handleChange} name="design_id" value={this.state.design_id} className="form-control">
                                            <option style={{display: 'none'}} value="">Select Design</option>
                                            {
                                                this.state.designs.map( (design, i) =>  <option key={i} value={design.id}>{design.design_name}</option>)
                                            }
                                        </select>

                                    </div> */}

                                    <div className="form-group">
                                        <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Email</label>
                                        <input  onChange={this.handleChange} name="email" value={this.state.email} className="form-control" id="validationCustom02" type="email" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Address</label>
                                        <input  onChange={this.handleChange} name="address" value={this.state.address} className="form-control" id="validationCustom02" type="text" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Contact Person</label>
                                        <input  onChange={this.handleChange} name="contact_person" value={this.state.contact_person} className="form-control" id="validationCustom02" type="text" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Website</label>
                                        <input  onChange={this.handleChange} name="website" value={this.state.website} className="form-control" id="validationCustom02" type="url" required />
                                    </div>

                                    <div className="form-group mt-5">
                                        <div className="product-buttons">
                                            <button disabled={this.state.isLoading} type="submit" className="btn btn-primary">
                                                {
                                                    this.state.isLoading ? (
                                                        <div>
                                                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                            Loading...
                                                        </div>
                                                    ) : 'SAVE'
                                                }
                                            </button>
                                            <button onClick={this.handleCancel} type="button" className="btn btn-light">Discard</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
        );
    }
}

export default DecorationSupplierDetails;