import React, { Component } from 'react';
import LogoImg from '../../public/images/athleadhq-logo-white.png';
import emailSvg from '../../public/svg/email.svg';
import './reset_password.scss';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import history from '../../history';

class ForgetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
        }
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            username: this.state.username,
        }
        request('post', `/auth/reset-password`, data, false).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success',
                'Verification Code sent to email!',
                'success'
            ).then(() => {
                history.push('/confirm-resetpassword');
            })
            //window.location.href = "/confirm-resetpassword";
        }).catch(err => {
            // if(err.response.data.error === 'UserNotConfirmedException') {
            //     return history.push(`/confirm?uuid=${err.response.data.user.id}`)
            // }
            // this.setState({
            //     isLoading: false
            // })
            console.error(err.response);
            Swal.fire(
                'Error',
                err.response,
                'error'
            ).then(() => {
                history.push('/forgot-password');
                // window.location.href = "/forgot-password";
            })
        })
    }

    render() {
        return ( 
            
            <div className="login-container text-center">
                <div className="logo-container">
                    <img src={LogoImg} />
                </div>
                <div className="form-container p-5">
                    <h2 className="mb-4">RESET PASSWORD</h2>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-email"><img src={emailSvg} className="imgsvg" alt="username" /></span>
                                    </span>
                                </div>
                                <input onChange={this.handleChange} name="username" type="text" className="form-control" placeholder="Enter your registered email!" />
                            </div>
                        </div>
                        

                        <div className="forgot-keepme">
                            <div className="row no-gutters">
                                <div className="col-5">
                                    <div className="forgot-link">
                                        <Link to="/login">Back to Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action">
                            <button disabled={this.state.isLoading} type="submit" name="forgetpassword" className="btn btn-block btn-primary">
                                        {
                                            this.state.isLoading ? (
                                                <div>
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                    Loading...
                                                </div>
                                            ) : 'Reset Password'
                                        }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ForgetPassword;