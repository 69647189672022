import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../constants/constants';
import Swal from 'sweetalert2';
import Loader from '../../../components/loader/loader';


class EditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [
                {
                    label: 'Admin',
                    value: 'admin'
                },
                {
                    label: 'Super admin',
                    value: 'super_admin'
                },
                {
                    label: 'Customer',
                    value: 'customer'
                }
            ],
            selectedRoles: []
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        e.target.classList.add('was-validated');
        const {params} = this.props.match;
        if( e.target.checkValidity()) {
            this.setState({
                isLoading: true
            })
            const data = {
                email: this.state.email,
                username: this.state.username,
                password: this.state.password,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                roles: this.state.selectedRoles
            }
            request('PUT', `/hq/users/${params.userId}`, data, true).then(res => {
                console.log(res);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Success',
                    res.data.message,
                    'success'
                )
            }).catch(err => {
                console.error(err);
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })
        }
    }

    isRoleSelected (role) {
        const i = this.state.selectedRoles.findIndex(element => element === role);
        return i > -1;
    }

    onRoleCheckedChange = role => e => {
        const selectedRoles = this.state.selectedRoles;
        if(e.target.checked) {
            selectedRoles.push(role)
        } else {
            const i = selectedRoles.findIndex(element => element === role);
            selectedRoles.splice(i, 1)
        }

        this.setState({
            selectedRoles
        })
    }

    async init () {
        this.setState({
            isLoading: true
        })
        try {
            const {params} = this.props.match;
            const res = await request('GET', `/hq/users/${params.userId}`, {}, true)
            console.log(res);
            this.setState({
                username: res.data.data.username,
                first_name: res.data.data.first_name,
                last_name: res.data.data.last_name,
                email: res.data.data.email,
                selectedRoles: res.data.data.roles
            })
        } catch (error) {
            console.error(error);
            Swal.fire(
                'Error',
                error.response.data.message,
                'error'
            )
        }
        this.setState({
            isLoading: false
        })
    }

    componentDidMount() {
        this.init()
    }

    render() {
        return (
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3>Edit User
                            {/* <small></small> */}
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                                    <li className="breadcrumb-item"><Link to="/users">Users</Link></li>
                                    <li className="breadcrumb-item active">Edit user</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading && <Loader />
                }
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Edit User</h5>
                                </div>
                                <div className="card-body">
                                    
                                    <div className="row product-adding">
                                        <div className="col-xl-6">
                                                <div className="row">
                                                    <div className="col-xl-9 xl-50 col-sm-6 col-9">
                                                        <form onSubmit={this.handleSubmit} className="needs-validation add-product-form" noValidate>
                                                            <div className="form">
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="validationCustom01" className="col-xl-3 col-sm-4 mb-0">First Name :</label>
                                                                    <input value={this.state.first_name} name="first_name" onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom01" type="text" required />
                                                                    <div className="valid-feedback">Looks good!</div>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="validationCustom01" className="col-xl-3 col-sm-4 mb-0">Last Name :</label>
                                                                    <input value={this.state.last_name} name="last_name" onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom01" type="text" required />
                                                                    <div className="valid-feedback">Looks good!</div>
                                                                </div>
                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Email :</label>
                                                                    <input value={this.state.email} name="email" type="email" onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom02" required />
                                                                    <div className="valid-feedback">Looks good!</div>
                                                                </div>

                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Username :</label>
                                                                    <input readOnly value={this.state.username} name="username" type="text" onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom02" required />
                                                                    <div className="valid-feedback">Looks good!</div>
                                                                </div>

                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Password :</label>
                                                                    <input value={this.state.password} name="password" type="password" onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom02" required />
                                                                    <div className="valid-feedback">Looks good!</div>
                                                                </div>

                                                                <div className="form-group mb-3 row">
                                                                    <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Roles :</label>
                                                                    {
                                                                        this.state.roles && this.state.roles.map((role, i) => (
                                                                            <div key={i} className="custom-control custom-checkbox mr-2">
                                                                                <input onChange={this.onRoleCheckedChange(role.value)} checked={this.isRoleSelected(role.value)} type="checkbox" className="custom-control-input" id={`${role.value}_role`} />
                                                                                <label className="custom-control-label" htmlFor={`${role.value}_role`} >{role.label}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>

                                                                


                                                                {/* <div className="form-group mb-3 row">
                                                                    <label htmlFor="validationCustom02" className="col-xl-3 col-sm-4 mb-0">Description :</label>
                                                                    <textarea name="description" onChange={this.handleChange} className="form-control col-xl-8 col-sm-7" id="validationCustom02" type="text" />
                                                                    <div className="valid-feedback">Looks good!</div>
                                                                    <div className="invalid-feedback offset-sm-4 offset-xl-3">Please choose Valid Code.</div>
                                                                </div> */}

                                                            </div>
                                                            <div className="mt-4">
                                                                <button type="submit" className="btn btn-primary">
                                                                    {this.state.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'SAVE'}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                        </div>
                                        {/* <div className="col-xl-7">

                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditUser;