import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormError from '../../components/form-error/form-error';
import Loader from '../../components/loader/loader';
import AlertError from '../../components/alert/error';
import AlertSuccess from '../../components/alert/success';
import './reset_password.scss';
import LogoImg from '../../public/images/athleadhq-logo-white.png';
import emailSvg from '../../public/svg/email.svg';
import passwordSvg from '../../public/svg/password.svg';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import history from '../../history';

class ConfirmPasswordReset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username:'',
            verification_code: '',
            password: '',
            confirm_password:''
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            verification_code: this.state.verification_code,
            password: this.state.password,
            username:this.state.username,
            confirm_password:this.state.confirm_password
        }
        request('post', `/auth/confirm-reset-password`, data, false).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success',
                'Password Reset Done,Please Log In Back!',
                'success'
            ).then(() => {
                history.push('/login');
            })
        }).catch(error => {
            // if(error.response.data.error === 'UserNotConfirmedException') {
            //     return history.push(`/confirm?uuid=${error.response.data.user.id}`)
            // }
            this.setState({
                isLoading: false
            })
            console.error(error.response);
            Swal.fire(
                'Error',
                error.response.data.message,
                'error'
            ).then(() => {
                history.push('/confirm-resetpassword');
                // window.location.href = "/confirm-resetpassword";
            })
        })
    }

    render() {
        return (
            <div className="login-container text-center">
                <div className="logo-container">
                    <img src={LogoImg} />
                </div>
                <div className="form-container p-5">
                    <h2 className="mb-4">CONFIRM RESET PASSWORD</h2>
                    <form onSubmit={this.handleSubmit}>

                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-code"><img src={emailSvg} className="imgsvg" alt="verification_code" /></span>
                                    </span>
                                </div>
                                <input name="verification_code" onChange={this.handleChange} type="text" className="form-control" placeholder="Verification Code here!" required/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-email"><img src={emailSvg} className="imgsvg" alt="username" /></span>
                                    </span>
                                </div>
                                <input onChange={this.handleChange} name="username" type="text" className="form-control" placeholder="Registered Email here!" required/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-password"><img src={passwordSvg} className="imgsvg" alt="password" /></span>
                                    </span>
                                </div>
                                <input name="password" onChange={this.handleChange} type="password" className="form-control" placeholder="Enter new password here!" required />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="icon icon-password"><img src={passwordSvg} className="imgsvg" alt="confirm_password" /></span>
                                    </span>
                                </div>
                                <input name="confirm_password" onChange={this.handleChange} type="password" className="form-control" placeholder="Confirm your passowrd here!" required />
                            </div>
                        </div>


                        <div className="forgot-keepme">
                            <div className="row no-gutters">
                                <div className="col-5">
                                    <div className="forgot-link">
                                        <Link to="/login">Back to Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action">
                            <button disabled={this.state.isLoading} type="submit" name="forgetpassword" className="btn btn-block btn-primary">
                                {
                                    this.state.isLoading ? (
                                        <div>
                                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                            Loading...
                                        </div>
                                    ) : 'Confirm Reset Password'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ConfirmPasswordReset;