import React, { Component } from 'react';
import ReactTable from 'react-table';
import '../../../node_modules/react-table/react-table.css';
import { Link } from 'react-router-dom';
import Pagination from '../../components/pagination/pagination';
import { request } from '../../constants/constants';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loader from '../../components/loader/loader';
import ReactPaginate from 'react-paginate';
import Modal from 'react-responsive-modal';


class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            pages: [1],
            pageSize: 10,
            currentPage: 1,
            filters: {

            },
            statusOptions: [
                {
                    label: 'Pending Payment',
                    value: 'pending_payment',
                },
                {
                    label: 'Processing',
                    value: 'processing',
                }, 
                {
                    label: 'On Hold',
                    value: 'on_hold',
                },
                {
                    label: 'Completed',
                    value: 'completed',
                }, 
                {
                    label: 'Shipped',
                    value: 'shipped',
                },
                {
                    label: 'Cancelled',
                    value: 'cancelled',
                },
            ]
        }
    }

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    formatNumber(number) {
        return Number(number).toFixed(2);
    }

    getOrders(lastEvaluatedKey, lastEvaluatedOrderNumber, filters) {
        return request('GET', `/hq/orders${lastEvaluatedKey ? `?lastEvaluatedKey=${lastEvaluatedKey}&lastEvaluatedOrderNumber=${lastEvaluatedOrderNumber}` : ''}`, filters, true);
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const orders = this.state.orders;
                const product = orders[index];
                product.isLoading = true;
                this.setState({
                    orders: orders
                })
                request('DELETE', `/hq/orders/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    product.isLoading = false;
                    this.setState({
                        orders: orders
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    product.isLoading = false;
                    this.setState({
                        orders: orders
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
   
    }

    handleNextClick = async e => {
        const currentPage = this.state.currentPage + 1;

       if(this.state.lastEvaluatedKey && this.state.pages.indexOf(currentPage) <= -1) {
            this.setState({
                isLoading: true
            })
            const pages = this.state.pages;
            console.log('currentPage: ', currentPage);
            if(this.state.pages.indexOf(currentPage) <= -1) {
                pages.push(currentPage);
            }
            this.setState({
                currentPage,
                pages,
            })
            let data = [], lastEvaluatedKey = this.state.lastEvaluatedKey;

            while(data.length < 10 && lastEvaluatedKey) {
                const res = await this.getOrders(lastEvaluatedKey && lastEvaluatedKey != true ? lastEvaluatedKey.id.S : null, lastEvaluatedKey && lastEvaluatedKey != true ? lastEvaluatedKey.order_number.N : null, this.state.filters).catch(err => {
                    console.error(err);
                    Swal.fire(
                        'Error',
                        err.response.data.message,
                        'error'
                    )
                })
    
                console.log(res);
                lastEvaluatedKey = res.data.lastEvaluatedKey;
                data = [...data, ...res.data.data]
                // const endOffset = parseInt(currentPage + '0');
                // const newItemOffset = endOffset - 10
                // this.setState({
                //     // currentPage,
                //     // pages,
                //     // lastEvaluatedKey: res.data.lastEvaluatedKey,
                //     // isLoading: false,
                //     // orders: newOrders,
                // })
            }
            
            const newOrders = [...this.state.orders, ...data];

            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                endOffset,
                itemOffset: newItemOffset,
                orders: newOrders,
                currentPage,
                pages,
                isLoading: false,
                lastEvaluatedKey
            });

       } else {
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                currentPage,
                endOffset,
                itemOffset: newItemOffset
            })
       }
    }

    handlePreviousClick = e => {
        if(this.state.currentPage !==1 ) {
            const currentPage = this.state.currentPage - 1;
            const endOffset = parseInt(currentPage + '0');
            const newItemOffset = endOffset - 10
            this.setState({
                currentPage,
                itemOffset: newItemOffset,
                endOffset: endOffset
            })
        }
    }

    handlePageClick = page => e => {
        const endOffset = parseInt(page + '0');
        const newItemOffset = endOffset - 10

        this.setState({
            currentPage: page,
            itemOffset: newItemOffset,
            endOffset
        })
    }

    onFiltersCloseModal = () => {
        this.setState({
            filtersModal: false
        })
    }

    showFiltersModal = () => {
        this.setState({
            filtersModal: true
        })
    }

    resetFilter = e => {
        this.setState({
            filters: {},
            filtersModal: false,
            pages: [1],
            currentPage: 1,
            lastEvaluatedKey: null,
            itemOffset: 0,
            endOffset: 10
        });
        this.init();
    }

    applyFilter = async e => {
        this.setState({
            isLoading: true,
            filtersModal: false,
        })
        console.log('filters: ', this.state.filters)

        let data = [], lastEvaluatedKey = true;

        while(data.length < 10 && lastEvaluatedKey) {
            const res = await this.getOrders(lastEvaluatedKey && lastEvaluatedKey != true ? lastEvaluatedKey.id.S : null, lastEvaluatedKey && lastEvaluatedKey != true ? lastEvaluatedKey.order_number.N : null, this.state.filters).catch(err => {
                console.error(err);
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })

            console.log(res);
            lastEvaluatedKey = res.data.lastEvaluatedKey;
            data = [...data, ...res.data.data]
            // const endOffset = parseInt(currentPage + '0');
            // const newItemOffset = endOffset - 10
            // this.setState({
            //     // currentPage,
            //     // pages,
            //     // lastEvaluatedKey: res.data.lastEvaluatedKey,
            //     // isLoading: false,
            //     // orders: newOrders,
            // })
        }
        
        console.log('data: ', data)
        this.setState({
            orders: data,
            pages: [1],
            currentPage: 1,
            itemOffset: 0,
            endOffset: 10,
            isLoading: false,
            lastEvaluatedKey
        });


        // this.getOrders(null, null, this.state.filters).then(res => {
        //     console.log(res);
        //     this.setState({
        //         orders: res.data.data,
        //         isLoading: false,
        //         lastEvaluatedKey: res.data.lastEvaluatedKey,
        //         pages: [1],
        //         currentPage: 1,
        //         itemOffset: 0,
        //         endOffset: 10
        //     })
        // }).catch(err => {
        //     console.error(err);
        //     Swal.fire(
        //         'Error',
        //         err.response.data.message,
        //         'error'
        //     )
        // })
    }

    handleFiltersChange = e => {
        console.log(e.target.name, e.target.value)
        this.setState({
            filters: {
                ...this.state.filters,
                [e.target.name]: e.target.value
            }
        })
    }

    getAllStores() {
        return request('GET', `/hq/stores`, {}, true);
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getAllStores().then(res => {
            console.log(res);
            this.setState({
                stores: res.data.data
            })
        })
        this.getOrders().then(res => {
            console.log(res);
            this.setState({
                orders: res.data.data,
                isLoading: false,
                lastEvaluatedKey: res.data.lastEvaluatedKey
            })
        })
    }

    componentDidMount() {
        this.init();
    }
    
    render() {
        const data = this.state.orders && this.state.orders.slice(this.state.itemOffset, this.state.endOffset);
        // const data = this.state.orders && this.state.orders.slice(this.state.itemOffset, this.state.endOffset).sort((a, b) => b.order_number - a.order_number);
		
        const pages = [];
        for (let index = 0; index < parseInt(this.state.orders.length / 10); index++) {
            pages.push((<li onClick={this.handlePageClick(index + 1)} key={index} className={`cursor-pointer page-item ${this.state.currentPage === index+1 ? 'active' : ''}`}><span rel="canonical" role="button" className="page-link" aria-current="page">{index+1}</span></li>))
        }
        
        const columns = [
            {
                Header: 'Name',
                Cell: row => {
                    return (
                    <div className="match">
                        <span className="highlight">Order #{row.original.order_number}</span>
                        <div className="actions">
                            <ul className="list-inline">
                                <li>
                                    <Link to={`/orders/${row.original.id}/detail`}><button className="btn-link btn font-primary p-0 pr-2">Edit</button></Link>
                                </li>
                                <li><span className="seprator">|</span></li>
                                <li>
                                    <Link to={`/orders/${row.original.id}/detail`}><button className="btn-link btn font-primary p-0 pl-2">View</button></Link>
                                </li>
                                {/* <li><span className="seprator">|</span></li> */}
                                {/* <li>
                                    <button onClick={() => this.handleDelete(row.original.id)} className="link">Delete</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    )
                }
            },
            {
                Header: 'Product',
                Cell: row => {
                    return <React.Fragment>
                    {
                        row.original.order_items.map(item => (
                            <img className="mr-2" key={item.id} src={item.product.image[0]} />
                        ))
                    }
                    </React.Fragment>
                     
                    
                }
            },
            {
                Header: 'Customer',
                Cell: row => (
                <span>{row.original.first_name} {row.original.last_name}</span>
                ),
            },
            {
                Header: 'Date',
                Cell: row => (
                    <span>{moment(row.original.createdAt).format('MMMM DD, YYYY')}</span>
                ),
            },
         
            {
                Header: 'Status',
               Cell: row => (
                <span className={`text-white p-2 badge badge-${row.original.status == 'pending_payment' ? 'secondary' : row.original.status == 'processing' ? 'warning' : row.original.status == 'completed' ? 'success' : 'info' }` }>{row.original.status}</span>
               )
            },
            {
                Header: 'Total',
                Cell: row => <span>${this.formatNumber(row.original.total)}</span>
            },
            // {
            //     Header: 'Action',
            //     Cell: row => (
            //         <React.Fragment>
            //         <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
            //         {
            //             row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
            //         }
            //         </button>
            //         </React.Fragment>
            //     ),
            // },
        ];
        return (
           <div className="page-body">
                <Modal
                    open={this.state.filtersModal} 
                    onClose={this.onFiltersCloseModal}
                    center={true}
                    classNames={{
                        overlay: 'custom-overlay',
                        modal: 'custom-modal'
                    }}
                    >
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600">Filters</h5>
                    </div>
                    
                    <div className="p-4">
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 col-form-label">Status: </label>
                            <div className="col-sm-8">
                            <select value={this.state.filters.status} onChange={this.handleFiltersChange} name="status" class="custom-select" id="inputGroupSelect03">
                                <option value="">All</option>
                                <option value="pending_payment">Pending Payment</option>
                                <option value="processing">Processing</option>
                                <option value="on_hold">On Hold</option>
                                <option value="partial_shipped">Partial Shipped</option>
                                <option value="shipped">Shipped</option>
                                <option value="completed">Completed</option>
                                <option value="cancelled">Cancelled</option>
                            </select>
                            </div>
                        </div>

                        <div className="form-group row mb-2">
                            <label className="col-sm-4 col-form-label">Store: </label>
                            <div className="col-sm-8">
                            <select value={this.state.filters.store_id} onChange={this.handleFiltersChange} name="store_id" class="custom-select" id="inputGroupSelect03">
                                <option value="">All</option>
                                {
                                    this.state.stores && this.state.stores.map((store, i) => (
                                        <option key={i} value={store.id}>{store.store_name}</option>
                                    ))
                                }
                            </select>
                            </div>
                        </div>

                        <div className="form-group row mb-2">
                            <label className="col-sm-4 col-form-label">Order #: </label>
                            <div className="col-sm-8">
                                <input value={this.state.filters.order_number} onChange={this.handleFiltersChange} name="order_number" type="number" className="form-control" placeholder="Order #" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">First name: </label>
                            <div className="col-sm-8">
                                <input value={this.state.filters.first_name} onChange={this.handleFiltersChange} name="first_name" type="text" className="form-control" placeholder="First name" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Last name: </label>
                            <div className="col-sm-8">
                                <input value={this.state.filters.last_name} onChange={this.handleFiltersChange} name="last_name" type="text" className="form-control" placeholder="Last nane" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Email: </label>
                            <div className="col-sm-8">
                                <input value={this.state.filters.email} onChange={this.handleFiltersChange} name="email" type="text" className="form-control" placeholder="Email" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Phone: </label>
                            <div className="col-sm-8">
                                <input value={this.state.filters.phone} onChange={this.handleFiltersChange} name="phobe" type="text" className="form-control" placeholder="Phone" />
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer justify-content-between">
                        <div>
                            <button onClick={this.resetFilter} className="btn btn-secondary" type="button" data-dismiss="modal">Reset</button>
                        </div>
                        <div>
                            <button onClick={this.applyFilter} className="btn btn-secondary" type="button" data-dismiss="modal">Apply</button>
                            <button onClick={this.onFiltersCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </Modal>
                {
                    this.state.isLoading && <Loader />
                }
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Orders
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><a href="index.html"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></a></li>
                        <li className="breadcrumb-item active">Orders</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Manage Orders</h5>
                    </div>
                    <div className="card-body order-datatable">
                        <div className="d-flex justify-content-between mb-4">
                            <div className="">
                                <button onClick={this.showFiltersModal} className="btn btn-secondary">Filters</button>
                            </div>
                            <Link to="/orders/new">  <button type="button" className="btn btn-secondary">Add Order</button> </Link>
                        </div>

                       <div className="table-responsive">
                       <ReactTable
                            // PaginationComponent={Pagination}
                            showPagination={false}
                            data={data}
                            minRows={5}
                            columns={columns}
                            noDataText={this.state.isLoading ? '' : "No Orders Found."}
                            defaultPageSize={this.state.pageSize}
                            pageSizeOptions={[10, 25, 50, 100]}
                            // resizable={true}
                            onPageChange={(index) => this.handlePageChange(index)}
                            onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                            getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                        />
                       </div>

                        <ul className="mt-3">
                            <li role="button" onClick={this.handlePreviousClick} className={`cursor-pointer page-item ${this.state.currentPage == 1 ? 'disabled' : ''}`}><span className="page-link " tabIndex={-1} role="button" aria-disabled="true" aria-label="Previous page" rel="prev">Previous</span></li>
                            {
                                // this.state.pages && this.state.pages.map((page, i) => (
                                //     <li onClick={this.handlePageClick(page)} key={i} className={`cursor-pointer page-item ${this.state.currentPage === page ? 'active' : ''}`}><span rel="canonical" role="button" className="page-link" aria-current="page">{page}</span></li>
                                // ))
                                pages
                            }
                            {
                                (this.state.lastEvaluatedKey) && (
                                    <li className="cursor-pointer page-item"><span className="page-link" role="button" >...</span></li>
                                )
                            }
                            <li onClick={this.handleNextClick} className={`cursor-pointer page-item ${(!this.state.lastEvaluatedKey) ? 'disabled' : ''}`}><span className="page-link" tabIndex={0} role="button" aria-disabled="false" aria-label="Next page" rel="next">Next</span></li>
                        </ul>


                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }

}

export default Orders;