import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2';
import './itemDetails.scss';
import { Link } from 'react-router-dom';
import { BlockPicker, ChromePicker  } from 'react-color';
import Modal from 'react-responsive-modal';
import { request } from '../../../../constants/constants';
import Loader from '../../../../components/loader/loader';

class ItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colours: [],
            sizes: [],
            currentSize: '',
            currentCode: '',
            currentPrice: '',
            modal: false,
            sizeModal: false,
            currentColor: '#000000',
            colour_name: '',
            currentImage: '',
            sizeGuide: '',
            item_name: '',
            item_code: '',
            item_lead_time: '',
            cost: '',
            item_weight: '',
            item_image: '',
            item_colours: '',
            item: undefined,
            pricings: [{
                startQuantity: 1,
                endQuantity: 10,
            }],
        }
    }

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }

    onCloseSizeModal = () => {
        this.setState({
            sizeModal: false
        })
    }

    openModal = () => {
        this.setState({
            modal: true
        })
    }

    openSizeModal = () => {
        this.setState({
            sizeModal: true
        })
    }

    handleChangeComplete = (color) => {
        this.setState({ currentColor: color.hex });
    };

    handleAdd = e => {
        const colour = {
            name: this.state.colour_name,
            value: this.state.currentColor
        }
        this.state.colours.push(colour);
        this.setState({
            colours: this.state.colours,
            modal: false
        })
    }

    // handleAddSize = e => {
    //     const sizes = this.state.sizes;
    //     sizes.push(this.state.currentSize);
    //     this.setState({
    //         sizes
    //     });
    // }

    // onDrop = files => {
    //     console.log(files)
    //     const reader = new FileReader();
    //     reader.readAsDataURL(files[0]);
    //     reader.onload = () => {
    //         this.setState({
    //             currentImage: reader.result
    //         })
    //     }
    //     reader.onerror = error => {
    //         console.error(error);
    //     };
    // }

    onDrop = (type) => files => {
		if(files.length <= 0) {
			Swal.fire(
				'Error',
				'Please upload an image file',
				'error'
			)
		} else {
			// this.setState({files})
			const file = files[0]; 
            const extension = file.name.split('.')[1];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.setState({
                    [type]: reader.result
                })
            }
            reader.onerror = error => {
                console.error(error);
            };
        }
    
    }

    handleUpdateItem = e => {
        this.setState({
            isLoading: true
        })
        // let sizes = '';
        // this.state.sizes.forEach(size => sizes += `${size},`);
        // sizes = sizes.substring(0, sizes.length-1);
        const { match: { params } } = this.props;
        const data = {
            item_name: this.state.item_name,
            item_code: this.state.item_code,
            item_lead_time: this.state.item_lead_time,
            item_weight: this.state.item_weight,
            cost: this.state.cost,
            item_colours: this.state.colours,
            item_sizes: this.state.sizes,
        }
        // if(!this.state.currentImage) {
        //     this.setState({
        //         isLoading: false
        //     })
        //     return Swal.fire(
        //         'Error!',
        //         'Image is required',
        //         'error'
        //     );
        // }

        if(this.state.currentImage) {
            if(this.state.currentImage.indexOf('base64') >- 1) data.item_image = this.state.currentImage
        }

        if(this.state.sizeGuide || this.state.sizeGuide === 'none') {
            data.item_size_guide = this.state.sizeGuide
        }
        
        console.log(data);
        request('PUT', `/hq/catalogs/${params.catalogId}/items/${params.itemId}`, data, true)
        .then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success!',
                res.data.message,
                'success'
            );
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            );
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeOnSize = i => e => {
        const sizes = [...this.state.sizes];
        const size = sizes[i];
        size[e.target.name] = e.target.value;
        this.setState({
            sizes: [...sizes]
        })
    }

    handlePricingChange = i => e => {
        const pricings = [...this.state.pricings];
        const pricing = pricings[i];
        pricing[e.target.name] = e.target.value;
        this.setState({
            pricings: [...pricings]
        })
    }

    handleChangeOnSizePricing = (sizeIndex, pricingIndex) => e => {
        const sizes = JSON.parse(JSON.stringify(this.state.sizes));
        const size = sizes[sizeIndex];
        const pricings = size.pricings;
        const pricing = pricings[pricingIndex];
        console.log('pricing', pricing);

        pricing[e.target.name] = e.target.value;
        console.log('sizes', sizes);
        this.setState({
            sizes: [...sizes]
        });
    }

    handleAddPricing = e => {
        const pricings = [...this.state.pricings];
        const lastPricing = pricings[this.state.pricings.length - 1];
        const pricing = {
            startQuantity: parseInt(lastPricing.endQuantity) + 1,
            endQuantity: parseInt(lastPricing.endQuantity) + 10,
            cost: parseInt(lastPricing.cost),
        }
        pricings.unshift(pricing);
        this.setState({
            pricings: [...pricings]
        })
        
    }

    handleAddSize = e => {
        const sizes = [...this.state.sizes];
        const newSize = {
            size: this.state.currentSize,
            code: this.state.currentCode,
            price: this.state.currentPrice,
            pricings: [...this.state.pricings]
        }
        sizes.push(newSize);
        this.setState({
            sizes: [...sizes],
            currentSize: '',
            currentCode: '',
            currentPrice: '',
            sizeModal: false,
        });
    }

    handleDeleteColour = i => e => {
        const colours = this.state.colours;
        colours.splice(i,1);
        this.setState({
            colours: colours
        })
    }

    handleRemoveSize = i => e => {
        const sizes = this.state.sizes;
        const size = sizes[i];
        console.log(size);
        sizes.splice(i, 1);
        this.setState({
            sizes
        })
    }

    hanldeClearSizeGuide = e => {
        this.setState({
            sizeGuide: 'none',
        })
    }


    async init() {
        this.setState({
            isLoading: true
        })
        const { match: { params } } = this.props;
        request('GET', `/hq/catalogs/${params.catalogId}/items/${params.itemId}`, {}, true).then(res => {
            const item = res.data.data;
            console.log(item)
            this.setState({
                isLoading: false,
                item: item,
                item_name: item.item_name,
                item_code: item.item_code,
                item_lead_time: item.item_lead_time,
                item_weight: item.item_weight,
                cost: item.cost,
                currentImage: item.item_image,
                sizeGuide: item.item_size_guide,
                colours: item.item_colours,
                sizes: item.item_sizes
            })
        })
    }

    componentDidMount() {
        this.init();
    }

    render() {
        return (
            <div className="page-body item-details-page">
            {this.state.isLoading && <Loader />}
            <Modal
               open={this.state.modal} 
               onClose={this.onCloseModal}
               center={true}
               classNames={{
                overlay: 'custom-overlay',
                modal: 'custom-modal'
               }}
            >
                    <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="exampleModalLabel">Add Color</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span className="text-primary">*</span> Colour name</label>
                            <input onChange={this.handleChange} name="colour_name" value={this.state.colour_name} className="form-control" id="validationCustomtitle" type="text" required />
                        </div>
                        <ChromePicker  
                            color={this.state.currentColor}
                            onChangeComplete={this.handleChangeComplete}
                            width='100%'
                        />

                    </div>
                    <div className="modal-footer">
                        <button onClick={this.handleAdd} className="btn btn-secondary" type="button">ADD</button>
                        <button onClick={this.onCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                    </div>
                    </div>
            </Modal>

            <Modal
               open={this.state.sizeModal} 
               onClose={this.onCloseSizeModal}
               center={true}
               classNames={{
                overlay: 'custom-overlay',
                modal: 'custom-modal'
               }}
            >
                    <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="exampleModalLabel">Add Size</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span className="text-primary">*</span>Size</label>
                            <input onChange={this.handleChange} name="currentSize" value={this.state.currentSize} className="form-control" id="validationCustomtitle" type="text" required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span className="text-primary">*</span>Code</label>
                            <input onChange={this.handleChange} name="currentCode" value={this.state.currentCode} className="form-control" id="validationCustomtitle" type="text" required />
                        </div>

                        <div className="border p-2">
                            <div className="d-flex justify-content-between mb-4">
                                <label htmlFor="validationCustom02" className="col-form-label">Pricing</label>
                                <button onClick={this.handleAddPricing} className="btn btn-link">Add Pricing</button>
                            </div>
                            {
                                this.state.pricings.sort((a, b) => a.startQuantity - b.startQuantity).map((pricing, i) => (
                                    <div className="border-bottom py-2">
                                        <p ><span className="text-primary">*</span>Range: <input value={pricing.startQuantity} onChange={this.handlePricingChange(i)} name="startQuantity" style={{width: 100}} className="form-control d-inline" type="number" /> <strong> - </strong><input value={pricing.endQuantity} onChange={this.handlePricingChange(i)} name="endQuantity" style={{width: 100}} className="form-control d-inline" type="number" /> qty</p>
                                        <p ><span className="text-primary">*</span>Cost: <input style={{width: 100}} onChange={this.handlePricingChange(i)} name="cost" value={pricing.cost} className="form-control d-inline" id="validationCustomtitle" type="number" required /></p>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button onClick={this.handleAddSize} className="btn btn-secondary" type="button">ADD</button>
                        <button onClick={this.onCloseSizeModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                    </div>
                    </div>
            </Modal>
                

            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="page-header-left">
                                <h3> {this.state.item && this.state.item.item_name}
          {/* <small>Multikart Admin panel</small> */}
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ol className="breadcrumb pull-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item"><Link to="/catalogs">Catalogs</Link></li>
                                <li className="breadcrumb-item"> <Link to={`/catalogs/${this.props.match.params.catalogId}`}>{this.state.item && this.state.item.catalog && this.state.item.catalog.catalog_name}</Link> </li>
                                <li className="breadcrumb-item active">{ this.state.item && this.state.item.item_name }</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row product-adding add-product-form ">
                <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h5><span className="text-primary">*</span>  Image </h5>
                            </div>
                            <div className="card-body">
                                <div className="digital-add needs-validation">
                                        <Dropzone 
                                               className="drop-area d-flex align-items-center"
                                               multiple={false}
                                               accept="image/*"
                                               onDrop={this.onDrop('currentImage')}
                                           >
                                                {({getRootProps, getInputProps}) => (
                                                <section className="text-center">
                                                    {this.state.currentImage && <img src={this.state.currentImage} />}
                                                    <div className="dropzone">
                                                    <div className="inner-text">
                                                        <p>
                                                            <span className="icon icon-upload">
                                                                <svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                                    <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
                                                                </svg>
                                                            </span>
                                                        </p>
                                                        <p>Drop files here or click to upload.</p>
                                                    </div>
                                                    </div>
                                                    <aside>
                                                    </aside>
                                                </section>
                                                )}
                                        </Dropzone>
                                        <div className="form-group mt-5">
                                            <div className="d-flex justify-content-between">
                                                <label className="col-form-label">Size Guide</label>
                                                <button onClick={this.hanldeClearSizeGuide} className="btn btn-link text-danger">Clear</button>
                                            </div>
                                            <Dropzone 
                                                className="drop-area d-flex align-items-center"
                                                multiple={false}
                                                accept="image/*"
                                                onDrop={this.onDrop('sizeGuide')}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                <section >
                                                    {this.state.sizeGuide && this.state.sizeGuide !== 'none' && <img className="img-fluid" src={this.state.sizeGuide} />}
                                                    <div className="dropzone">
                                                    <div className={"inner-text " + (this.state.sizeGuide && "has-image")}>
                                                        <p>
                                                            <span className="icon icon-upload">
                                                                <svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                                    <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
                                                                </svg>
                                                            </span>
                                                        </p>
                                                        <p>Drop files here or click to upload.</p>
                                                    </div>
                                                    </div>
                                                    <aside>
                                                    {/* <h4>Files</h4> */}
                                                    </aside>
                                                </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h5>General</h5>
                            </div>
                            <div className="card-body">
                                <div className="digital-add needs-validation">
                                    <div className="form-group">
                                        <label htmlFor="validationCustom01" className="col-form-label pt-0"><span className="text-primary">*</span> Name</label>
                                        <input onChange={this.handleChange} name="item_name" value={this.state.item_name} className="form-control" id="validationCustom01" type="text" required />
                                    </div>
                                        {/* <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Size Guide</label>
                                            <Dropzone 
                                                className="drop-area d-flex align-items-center"
                                                multiple={false}
                                                accept="image/*"
                                                onDrop={this.onDrop('sizeGuide')}
                                            >
													{({getRootProps, getInputProps}) => (
                                                    <section >
                                                        {this.state.size && <img className="img-fluid" src={this.state.size} />}
                                                        <div className="dropzone">
                                                        <div className={"inner-text " + (this.state.size && "has-image")}>
                                                            <p>
                                                                <span className="icon icon-upload">
                                                                    <svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                                        <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
                                                                    </svg>
                                                                </span>
                                                            </p>
                                                            <p>Drop files here or click to upload.</p>
                                                        </div>
                                                        </div>
                                                        <aside>
                                                        </aside>
                                                    </section>
													)}
                                            </Dropzone>
                                        </div> */}

                                    <div className="form-group">
                                        <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span className="text-primary">*</span> Code</label>
                                        <input onChange={this.handleChange} name="item_code" value={this.state.item_code} className="form-control" id="validationCustomtitle" type="text" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustomtitle" className="col-form-label pt-0"><span className="text-primary">*</span>Lead Time</label>
                                        <input  onChange={this.handleChange} name="item_lead_time" value={this.state.item_lead_time} className="form-control" id="validationCustomtitle" type="number" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Cost</label>
                                        <input onChange={this.handleChange} name="cost" value={this.state.cost} className="form-control" id="validationCustom02" type="text" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Weight (kg)</label>
                                        <input onChange={this.handleChange} name="item_weight" value={this.state.item_weight} className="form-control" id="validationCustom02" type="text" required />
                                    </div>

                                    {/* <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Sizes</label>
                                                <button onClick={this.openSizeModal} className="btn btn-link">Add Size</button>
                                            </div>  
                                            {
                                                this.state.sizes.length <= 0 ? (
                                                    <div className="text-center">No Sizes yet</div>
                                                ) :
                                                this.state.sizes.map( (size,i) => (
                                                    <div key={i} className="p-0 custom-control custom-control-inline custom-control-size mb-2">
                                                        <label className="custom-control-label" htmlFor={`size-${size.id}`}>{size.size}</label>
                                                    </div>
                                                ))
                                            }
                                    </div> */}

                                    <div className="form-group colours">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="validationCustom02" className="col-form-label"><span className="text-primary">*</span>Colours</label>
                                            <button onClick={this.openModal} className="btn btn-link">Add Color</button>
                                        </div>                                            
                                        { this.state.colours.length <= 0 ? (
                                            <div className="text-center">No Colours yet</div>
                                        ) : this.state.colours.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0).map( (colour,i) =>  
                                            <div className="item-container d-flex justify-content-between" key={i} >
                                                <div className="">
                                                    <div className="item" style={{background:colour.value}}> </div>
                                                    <span>{colour.name}</span>
                                                </div>

                                                <div>
                                                    <button onClick={this.handleDeleteColour(i)} className="btn btn-link">Delete</button>
                                                </div>
                                            </div>
                                        )  }
                                        
                                    </div>

                            
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col col-12">
                              <div className="card">
                                <div className="card-body">
                                <div className="form-group">
                                            <div className="d-flex justify-content-between mb-4">
                                                <label htmlFor="validationCustom02" className="col-form-label">Sizes</label>
                                                <button onClick={this.openSizeModal} className="btn btn-link">Add Size</button>
                                            </div>  
                                            {
                                                this.state.sizes.length <= 0 ? (
                                                    <div className="text-center">No Sizes yet</div>
                                                ) :
                                                (
                                                    <div className="border p-3">
                                                        <div className="row mb-2">
                                                            <div className="col col-xl-1">
                                                                <label>Size</label>
                                                            </div>
                                                            <div className="col col-xl-1">
                                                                <label>Code</label>
                                                            </div>
                                                            <div className="col col-xl-9">
                                                                <label>Pricing</label>
                                                            </div>
                                                            <div className="col col-xl-1">
                                                            </div>
                                                        </div>
                                                        
                                                        {
                                                            this.state.sizes.map( (size,i) => (
                                                                <div key={i} className="row mb-2 border-bottom">
                                                                    <div className="col-sm-2 col-md-2 col-xl-1">
                                                                        <input value={size.size} name="size" onChange={this.handleChangeOnSize(i)} type="text" className="form-control"></input>
                                                                    </div>
                                                                    <div className="col-sm-2 col-md-2 col-xl-1">
                                                                        <input value={size.code} name="code" onChange={this.handleChangeOnSize(i)} type="text" className="form-control"></input>
                                                                    </div>
                                                                    <div className="col-sm-7 col-md-7 col-xl-9">
                                                                        <div className="row">
                                                                            {
                                                                                size.pricings.map((pricing, pricingIndex) => (
                                                                                <div key={pricingIndex} className="col-12 col-xl-3 mb-2 border p-2">
                                                                                    <p className="">Range: <input name="startQuantity" value={pricing.startQuantity} onChange={this.handleChangeOnSizePricing(i,pricingIndex)} style={{width:100}} className="form-control d-inline" /> <strong>-</strong> <input name="endQuantity" onChange={this.handleChangeOnSizePricing(i,pricingIndex)} value={pricing.endQuantity} style={{width:100}} className="form-control d-inline" /> qty</p>
                                                                                    <p className="">Cost: <input name="cost" value={pricing.cost} onChange={this.handleChangeOnSizePricing(i,pricingIndex)} style={{width:100}} className="form-control d-inline"></input></p>       
                                                                                </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                      
                                                                    </div>

                                                                    <div className="col-sm-1 col-md-1 col-xl-1">
                                                                       <button onClick={this.handleRemoveSize(i)} className="btn btn-link text-danger">Remove</button>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="form-group mt-5">
                                            <div className="product-buttons">
                                                <button disabled={this.state.isLoading} onClick={this.handleUpdateItem} type="button" className="btn btn-primary">
                                                    {
                                                        this.state.isLoading ? (
                                                            <div>
                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                                Loading...
                                                            </div>
                                                        ) : 'SAVE'
                                                    }
                                                </button>
                                                <button type="button" className="btn btn-light">Discard</button>
                                            </div>
                                        </div>
                                </div>
                              </div>

                        </div>
                                                
                </div>
            </div>
        </div>
        );
    }
}

export default ItemDetails;