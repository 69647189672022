import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { request } from '../../../../constants/constants';

class PreviewProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            store_name: '',
            store_logo: '',
            slug: '',
            products: [],
            categories: [],
            pageSize: 10,
            pageNumber: 1,
            totalPage: 0
        }

    }

    paginate() {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return this.props.products.slice((this.state.pageNumber - 1) * this.state.pageSize, this.state.pageNumber * this.state.pageSize);
    }

    getProducts() {
        return request('GET', `/shop/stores/${this.props.match.params.storeSlug}/products`, {}, false);
    }
    
    // async init() {
    //    try{
    //     const productsData = await this.getProducts();
    //     console.log(productsData);
    //     const product = productsData.data.data;
    //     this.setState({
    //         store_name: product.store.store_name,
    //         store_logo: product.store.store_logo,
    //         slug: product.store.slug,
    //         products: product.products,
    //         categories: product.store.categories
    //     })
    //    } catch(err){
    //        console.error(err);
    //    }
    // }

    componentWillMount() {

        console.log('mounted')
    }

    // getSnapshotBeforeUpdate() {
    //     return true;
    // }

    // componentDidUpdate(prevProps) {
    //     console.log('componentDidUpdate', prevProps)
    // }

    componentWillReceiveProps(prevProps) {
        console.log('componentWillReceiveProps', prevProps)
    }


    componentDidMount() {
        // this.init();
    }

    render() {
        
        const data = this.paginate();
        console.log(data)
        const totalPage = Math.ceil(data.length/this.state.pageSize);
        const pages = [];
        console.log(totalPage)
        for (let index = 1; index <= totalPage; index++) {
            console.log(index)
            pages.push(<li key={index} className={'page-item ' + (this.state.pageNumber == index && 'active')}><button className="page-link">{index}</button></li>)
        }
        console.log(pages)
        return (
            <React.Fragment>
            <div className="row">
                            {data.map( (product, i) => (
                                <div key={i} className="col-6 col-md-4">
                                    {/* Card */}
                                    <div className="card mb-7">
                                    {/* Badge */}
                                    {
                                        product.sale ? (
                                        <div className="badge badge-dark card-badge card-badge-left text-uppercase">
                                            Sale
                                        </div>
                                        ) : (
                                        <div className="badge badge-white card-badge card-badge-left text-uppercase">
                                            New
                                        </div>  
                                        )
                                    }
                                    {/* Image */}
                                    <div className="card-img">
                                        {/* Image */}
                                        <a >
                                            <img className="card-img-top card-img-front" src={product.image[0]} alt="..." />
                                        </a>
                                        {/* Actions */}
                                        <div className="card-actions">
                                        <span className="card-action">
                                            <button className="btn btn-xs btn-circle btn-white-primary" data-toggle="modal" data-target="#modalProduct">
                                            <i className="fe fe-eye" />
                                            </button>
                                        </span>
                                        <span className="card-action">
                                            <button className="btn btn-xs btn-circle btn-white-primary" data-toggle="button">
                                            <i className="fe fe-shopping-cart" />
                                            </button>
                                        </span>
                                        <span className="card-action">
                                            <button className="btn btn-xs btn-circle btn-white-primary" data-toggle="button">
                                            <i className="fe fe-heart" />
                                            </button>
                                        </span>
                                        </div>
                                    </div>
                                    {/* Body */}
                                    <div className="card-body px-0">
                                        {/* Category */}
                                        {/* <div className="font-size-xs">                                   
                                            {product.category.map((category,i) => (
                                                 <a key={i} className="text-muted" href="shop.html">{category.category_name}&nbsp;&nbsp;</a>
                                            ))}
                                        </div> */}
                                        {/* Title */}
                                        <div className="font-weight-bold">
                                        <a className="text-body" href="product.html">
                                          {product.product_name}
                                        </a>
                                        </div>
                                        {/* Price */}
                                        {product.sale_type == 'amount' ? ( 
                                            <div className="font-weight-bold">
                                                <span className="font-size-xs text-gray-350 text-decoration-line-through mr-2">${product.online_price}</span>
                                                <span className="text-primary">${product.online_price - product.sale}</span>
                                            </div>
                                        ) : product.sale_type == 'percent_off' ? (
                                            <div className="font-weight-bold">
                                                <span className="font-size-xs text-gray-350 text-decoration-line-through mr-2">${product.online_price}</span>
                                                <span className="text-primary"> ${product.online_price - ((product.sale/100)*product.online_price)} </span>
                                            </div>
                                        ) : (
                                            <div className="font-weight-bold text-muted">
                                                ${product.online_price}
                                            </div>
                                        )} 
                                    
                                    </div>
                                    </div>
                                </div>
                            ))}
                       
                        </div>
                       
                        {/* Pagination */}
                        <nav className="d-flex justify-content-center justify-content-md-end">
                        <ul className="pagination pagination-sm text-gray-400">
                            <li className="page-item">
                            <button disabled={this.state.pageNumber == 1} className="page-link page-link-arrow">
                                <i className="fa fa-angle-left" />
                            </button>
                            </li>
                            {
                                pages
                            }
                            <li className="page-item">
                            <button disabled={this.state.pageNumber == totalPage} className="page-link page-link-arrow">
                                <i className="fa fa-angle-right" />
                            </button>
                            </li>
                        </ul>
                        </nav>
            </React.Fragment>
        );
    }
}

export default PreviewProducts;