import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../../components/loader/loader';
import { request } from '../../../constants/constants';
import StoreSvg from '../../../public/svg/stores.svg';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import history from '../../../history';

class AddOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            status: null,
            states: ['ACT', 'NSW', 'Northen Territory', 'Queensland', 'South Australia', 'Western Australia', 'Tasmania', 'Victoria'],
            shipping: {
                first_name: '',
                last_name: '',
                email: '',
                company_name: '',
                country: 'Australia',
                address_line_1: '',
                address_line_2: '',
                suburb: '',
                state: '',
                postal_code: '',
                phone: '',
                notes: '',
                local_pickup: false,
                local_pickup_location: '',
                error: undefined,
                pay_option: 'credit_card',
            },
            items: [],
            statusOptions: [
                {
                    label: 'Pending Payment',
                    value: 'pending_payment',
                },
                {
                    label: 'Processing',
                    value: 'processing',
                },
                {
                    label: 'On Hold',
                    value: 'on_hold',
                },
                {
                    label: 'Partial Shipped',
                    value: 'partial_shipped',
                },
                {
                    label: 'Shipped',
                    value: 'shipped',
                },
                {
                    label: 'Completed',
                    value: 'completed',
                },
                {
                    label: 'Cancelled',
                    value: 'cancelled',
                },
            ],
            customer_name: ''
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleProductsSizeChange = (i) => e => {
        const products = [...this.state.currentProduct.products];
        const product = products[i];
        product.selectedSize = e.target.value;

        this.setState({
            currentProduct: {
                ...this.state.currentProduct,
                products
            }
        })
    }


    handleProductsColorChange = (i) => e => {
        console.log(i);
        const products = [...this.state.currentProduct.products];
        const product = products[i];
        product.selectedColour = e.target.value;

        this.setState({
           currentProduct: {
               ...this.state.currentProduct,
               products
           }
        })
    }

    handleProductsCustomFieldChange = (productIndex, customFieldIndex) => e => {
        const products = [...this.state.currentProduct.products];
        const product = products[productIndex];
        const customFields = product.custom_fields;
        const customField = customFields[customFieldIndex];
        if(!customField) {
            return Swal.fire(
                'Error',
                'Custom field cannot found',
                'error'
            )
        }
        customField.value = e.target.value;
        this.setState({
            currentProduct: {
                ...this.state.currentProduct,
                products
            }
        })
    }

    handleSave = () => {
        const { match: { params } } = this.props; 

        const data = {
            products: this.state.items,
            shipping: this.state.shipping,
            status: this.state.status.value,
            store_id: this.state.store_id,
            customer_id: this.state.customer_id
        }

        this.setState({
            isLoading: true
        })

        request('POST', `/hq/orders`, data, true).then(res => {
            console.log(res);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Success',
                res.data.message,
                'success'
            )
            history.push(`/orders/${res.data.data.id}/detail`)
        }).catch(err => {
            console.log(err);
            this.setState({
                isLoading: false
            })
            Swal.fire(
                'Error',
                err.response.data.message,
                'error'
            )
        })

    }

    handleStatusChange = status => {
        this.setState({
            status: status
        })
    }

    handleShippingChange = e => {
        this.setState({
            shipping: {
                ...this.state.shipping,
                [e.target.name]: e.target.value
            }
        })
    }

    formatNumber(number) {
        return Number(number).toFixed(2);
    }


    getAllCustomers(name) {
        return request('GET', `/hq/customers${(name ? `?search=${name}` : '')}`, {}, true);
    }

    getAllStores() {
        return request('GET', `/hq/stores`, {}, true);
    }
    
    getStore(storeId) {
        return request('GET', `/hq/stores/${storeId}`, {}, true);
    }

    handleStoreChange = e => {
        this.setState({
            store_id: e.target.value
        })
        this.getStore(e.target.value).then(res => {
            console.log(res)
            this.setState({
                products: res.data.data.products
            })
        })
    }
    onAddProductCloseModal = e => {
        this.setState({
            addProductModal: false
        })
    }

    handleAddProduct = e => {
        const items = [...this.state.items];
        items.unshift({
            ...this.state.currentProduct,
            item_quantity: this.state.item_quantity,
            selectedColour: this.state.selectedColour,
            selectedSize: this.state.selectedSize
        });
        this.setState({
            items: [...items],
            addProductModal: false,
            currentProduct: undefined,
            selectedColour: '',
            selectedSize: ''
        })
    }

    showAddProductModal = e => {
        this.setState({
            addProductModal: true
        })
    }

    onProductChange = e => {
        const products = this.state.products;
        const i = products.findIndex(product => product.id == e.target.value);
        if(i > -1) {
            const product = products[i];
            this.setState({
                currentProduct: product
            })
        }
    }

    handleCustomFieldChange = i => e => {
        const customFields = JSON.parse(JSON.stringify(this.state.currentProduct.custom_fields));
        const customField = customFields[i];
        if(!customField) {
            return Swal.fire(
                'Error',
                'Custom field cannot found',
                'error'
            )
        }

        customField.value = e.target.value;
        this.setState({
            currentProduct: {
                ...this.state.currentProduct,
                custom_fields: customFields
            }
        })
    }

    getCustomFieldsTotalPrice(customFields){
        let price = 0 ;
        if(customFields) {
            for (let customField of customFields) {
                if(customField.additionalPriceChecked && customField.value && customField.value !== '') {
                    price += customField.price
                }
            }
        }
        return price;
    }

    getBundleItemsCustomFieldsTotalPrice(bundleItems){
        let price = 0;
        if(bundleItems) {
            for (const item of bundleItems) {
                price += this.getCustomFieldsTotalPrice(item.custom_fields)
            }
        }
        return price;
    }

    getStoreShippingFlatRate() {
        if(this.state.store_id) {
            let store;
            const index = this.state.stores.findIndex(store => store.id === this.state.store_id);
            if (index > -1) {
                store = this.state.stores[index];
                return store.flat_rate
            }
        }
    }

    getItemsSubtotal() {
        let total = 0;
        if(this.state.items) {
            for (const item of this.state.items) {
                total += (
                    item.product_type === 'bundle' ? (
                        (
                            item.sale_type === 'amount' ? item.online_price - item.sale
                            : item.sale_type === 'percent_off' ? (item.online_price - ((item.sale / 100) * item.online_price))
                            : item.online_price
                        ) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                    ) : (
                        item.sale_type === 'amount' ? item.online_price - item.sale
                        : item.sale_type === 'percent_off' ? (item.online_price - ((item.sale / 100) * item.online_price))
                        : item.online_price
                    ) + this.getCustomFieldsTotalPrice(item.custom_fields)
                ) * item.item_quantity
            }

            total = total / 1.1
        }
        return total.toFixed(2);
    }

    getTotal() {
        let total = 0;
        if(this.state.items && this.state.items.length > 0) {
            console.log(this.getItemsSubtotal(), this.getGst(), this.getStoreShippingFlatRate())
            return (parseFloat(this.getItemsSubtotal()) + parseFloat( this.getGst() ) + parseFloat(this.getStoreShippingFlatRate()))

        }
    }

    getGst() {
        if(this.state.items) {
            console.log(this.getItemsSubtotal(), (this.state.shipping.local_pickup ? 0 : this.getStoreShippingFlatRate()))
            console.log(this.getItemsSubtotal() + (this.state.shipping.local_pickup ? 0 : this.getStoreShippingFlatRate()));
            const gst_value = (parseFloat(this.getItemsSubtotal()) + (this.state.shipping.local_pickup ? 0 : parseFloat(this.getStoreShippingFlatRate()))) * 0.1;
            return parseFloat(gst_value).toFixed(2);
        }
    }

    handleSearchCustomer = e => {
        console.log(e.target.value);
        this.setState({
            customer_name: e.target.value
        })
        if(e.target.value && e.target.value !== '') {
            this.setState({
                searchCustomerLoading: true,
                showCustomerList: true
            })

            this.getAllCustomers(e.target.value).then(res => {
                console.log(res);
                this.setState({
                    customers: res.data.data,
                    searchCustomerLoading: false
                })
            }).catch(err => {
                console.error(err);
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
                this.setState({
                    searchCustomerLoading: false
                })
            })
        }
    }

    handleSelectCustomer = customer => e => {
        this.setState({
            customer_id: customer.id,
            customer_name: `${customer.first_name} ${customer.last_name}`,
            showCustomerList: false
        })
    }

    init() {
        this.getAllCustomers().then(res => {
            console.log(res);
            this.setState({
                customers: res.data.data
            })
        })

        this.getAllStores().then(res => {
            console.log(res);
            this.setState({
                stores: res.data.data
            })
        })

    }

    componentDidMount() {
        this.init();
    }

    
    render() {
        return (
            <div className="page-body">
                {this.state.isLoading && <Loader />}

                <Modal
                    open={this.state.addProductModal} 
                    onClose={this.onAddProductCloseModal}
                    center={true}
                    classNames={{
                        overlay: 'custom-overlay',
                        modal: 'custom-modal'
                    }}
                    >
                            <div className="">
                            <div className="modal-header">
                                <h5 className="modal-title f-w-600" id="exampleModalLabel">Custom Items</h5>
                            </div>
                            <div className="modal-body">
                                <label className="col-form-label pt-0">Product: </label>
                                <select name="currentProduct" onChange={this.onProductChange} class="custom-select" id="inputGroupSelect01">
                                    <option style={{display: 'none'}} value="">Choose...</option>
                                    {
                                        this.state.products && this.state.products.map((product, i) => (
                                            <option key={i} value={product.id}>{product.product_name}</option>
                                        ))
                                    }
                                </select>

                                <label className="col-form-label pt-0">Quantity: </label>
                                <input onChange={this.handleChange} type="text" name="item_quantity" className="form-control" aria-label="Text input with segmented dropdown button" />

                                {
                                    this.state.currentProduct && this.state.currentProduct.colours && (
                                        <div className="mb-8">
                                                {
                                                  this.state.currentProduct.colours.length > 0 && (
                                                        <React.Fragment>
                                                            <label >
                                                                Colour: 
                                                            </label>
                                                            <select onChange={this.handleChange} value={this.state.selectedColour} name="selectedColour" class="custom-select" id="inputGroupSelect01">
                                                                <option style={{display: 'none'}} value="">Choose...</option>
                                                                {
                                                                    this.state.currentProduct.colours && this.state.currentProduct.colours.map((colour, i) => (
                                                                        <option key={i} value={colour.name}>{colour.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </React.Fragment>
                                                    )
                                                }

                                                {
                                                    this.state.currentProduct && this.state.currentProduct.sizes && 
                                                    <React.Fragment>
                                                        
                                                        <label className="">
                                                                Size: 
                                                        </label>

                                                        <div className="mb-5">
                                                            <select onChange={this.handleChange} name="selectedSize" value={this.state.selectedSize} class="custom-select" id="inputGroupSelect01">
                                                                <option style={{display: 'none'}} value="">Choose...</option>
                                                                {
                                                                    this.state.currentProduct.sizes && this.state.currentProduct.sizes.map((size, i) => (
                                                                        <option key={i} value={size.size}>{size.size}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                        </div>
                                    )
                                }

                                {
                                    this.state.currentProduct && this.state.currentProduct.product_type == 'bundle' && (
                                        <div className="">
                                            <label className="mt-2">Bundle items: </label>
                                            {
                                                this.state.currentProduct.products && this.state.currentProduct.products.map((product, productIndex) => (
                                                    <div className="mb-5 p-5 border-bottom" key={productIndex}>
                                                        {
                                                            product.product_name && <p className="m-0">Item: {product.product_name}</p>
                                                        }

                                                            {
                                                                product.colours.length > 0 && (
                                                                    <React.Fragment>
                                                                        <label >
                                                                            Colour: 
                                                                        </label>
                                                                        <select onChange={this.handleProductsColorChange(productIndex)} value={product.selectedColour} name="selectedColour" class="custom-select" id="inputGroupSelect01">
                                                                            <option style={{display: 'none'}} value="">Choose...</option>
                                                                            {
                                                                                product.colours && product.colours.map((colour, i) => (
                                                                                    <option key={i} value={colour.name}>{colour.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </React.Fragment>
                                                                )
                                                            }

                                                            {
                                                               product && product.sizes && 
                                                                <React.Fragment>
                                                                    
                                                                    <label className="">
                                                                            Size: 
                                                                    </label>

                                                                    <div className="mb-5">
                                                                        <select onChange={this.handleProductsSizeChange(productIndex)} name="selectedSize" value={product.selectedSize} class="custom-select" id="inputGroupSelect01">
                                                                            <option style={{display: 'none'}} value="">Choose...</option>
                                                                            {
                                                                                product.sizes && product.sizes.map((size, i) => (
                                                                                    <option key={i} value={size.size}>{size.size}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        
                                                        {
                                                            product && product.custom_fields && 
                                                            product.custom_fields.map((customField, i) => (
                                                            <div key={i} className="form-group">
                                                                <label>{customField.name} {customField.additionalPriceChecked && <strong>${customField.price}</strong>} {customField.requiredChecked && <span className="text-danger">(required)</span>}: </label>
                                                                <input onChange={this.handleProductsCustomFieldChange(productIndex, i)} name={customField.name} value={customField.value} required={customField.requiredChecked} className="form-control" type={customField.type} />
                                                            </div>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }

                                {
                                    this.state.currentProduct && this.state.currentProduct.custom_fields && 
                                    this.state.currentProduct.custom_fields.map((customField, i) => (
                                    <div key={i} className="form-group">
                                        <label>{customField.name} {customField.additionalPriceChecked && <strong>${customField.price}</strong>} {customField.requiredChecked && <span className="text-danger">(required)</span>}: </label>
                                        <input onChange={this.handleCustomFieldChange(i)} name={customField.name} value={customField.value} required={customField.requiredChecked} className="form-control" type={customField.type} />
                                    </div>
                                    ))
                                }
                               
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.handleAddProduct} className="btn btn-default" type="button" data-dismiss="modal">Add</button>
                                <button onClick={this.onAddProductCloseModal} className="btn btn-default" type="button" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                </Modal>


                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                   
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to="/orders">Orders</Link></li>
                                    <li className="breadcrumb-item active">Add Order</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5> General </h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Status</label>
                                        <Select
                                            value={this.state.status}
                                            onChange={this.handleStatusChange}
                                            options={this.state.statusOptions}
                                        />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Customer</label>
                                        <input className="form-control" value={this.state.customer_name} type="text" onChange={this.handleSearchCustomer} />

                                        {
                                            this.state.showCustomerList && (
                                                <div className='card p-4 '>
                                                    {
                                                        this.state.customers && this.state.customers.map((customer, i) => (
                                                           <div onClick={this.handleSelectCustomer(customer)} key={i} className={`${i !== 0 ? 'border-top' : ''} py-2`}>
                                                                <p className='cursor-pointer' >{customer.first_name} {customer.last_name}</p>
                                                           </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }

                                        {/* <select name="customer_id" onChange={this.handleChange} class="custom-select" id="inputGroupSelect01">
                                            <option style={{display: 'none'}} value="">Choose...</option>
                                            {
                                                this.state.customers && this.state.customers.map((customer, i) => (
                                                    <option key={i} value={customer.id}>{customer.first_name} {customer.last_name}</option>
                                                ))
                                            }
                                        </select> */}
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="col-form-label pt-0">Store</label>
                                        <select onChange={this.handleStoreChange} class="custom-select" id="inputGroupSelect01">
                                            <option style={{display: 'none'}} value="">Choose...</option>
                                            {
                                                this.state.stores && this.state.stores.map((store, i) => (
                                                    <option key={i} value={store.id}>{store.store_name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-header">
                                    <h5> Shipping </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-9">
                                        <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingFirstName">First Name *</label>
                                            <input className="form-control form-control-sm" onChange={this.handleShippingChange} value={this.state.shipping.first_name} name="first_name" id="checkoutBillingFirstName" type="text" placeholder="First Name" required />
                                        </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingLastName">Last Name *</label>
                                            <input className="form-control form-control-sm" onChange={this.handleShippingChange} value={this.state.shipping.last_name} name="last_name" id="checkoutBillingLastName" type="text" placeholder="Last Name" required />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingEmail">Email *</label>
                                            <input className="form-control form-control-sm" onChange={this.handleShippingChange} value={this.state.shipping.email} name="email" id="checkoutBillingEmail" type="email" placeholder="Email" required />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingCompany">Company name</label>
                                            <input onChange={this.handleShippingChange} value={this.handleShippingChange.company_name} name="company_name" className="form-control form-control-sm" id="checkoutBillingCompany" type="text" placeholder="Company name (optional)" />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingCountry">Country *</label>
                                            <input className="form-control form-control-sm" value={this.state.shipping.country} readOnly id="checkoutBillingCountry" type="text" placeholder="Country" required />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingAddress">Address Line 1 *</label>
                                            <input onChange={this.handleShippingChange} value={this.state.shipping.address_line_1} name="address_line_1" className="form-control form-control-sm" type="text" placeholder="Address Line 1" required />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingAddressTwo">Address Line 2</label>
                                            <input onChange={this.handleShippingChange} value={this.state.shipping.address_line_2} name="address_line_2" className="form-control form-control-sm" id="checkoutBillingAddressTwo" type="text" placeholder="Address Line 2 (optional)" />
                                        </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingSuburb">Suburb *</label>
                                            <input onChange={this.handleShippingChange} value={this.state.shipping.suburb} name="suburb" className="form-control form-control-sm" id="checkoutBillingSuburb" type="text" placeholder="Suburb" required />
                                        </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingState">State *</label>
                                            <select onChange={this.handleShippingChange} value={this.state.shipping.state} name="state" className="custom-select">
                                            <option value="">Select State</option>
                                            {this.state.states.map((state, i) => (
                                                <option key={i} value={state}>{state}</option>
                                            ))}
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="checkoutBillingZIP">ZIP / Postcode *</label>
                                            <input onChange={this.handleShippingChange} value={this.state.shipping.postal_code} name="postal_code" className="form-control form-control-sm" id="checkoutBillingZIP" type="text" placeholder="ZIP / Postcode" required />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="form-group mb-0">
                                            <label htmlFor="checkoutBillingPhone">Mobile Phone *</label>
                                            <input onChange={this.handleShippingChange} value={this.state.shipping.phone} name="phone" className="form-control form-control-sm" id="checkoutBillingPhone" type="tel" placeholder="Mobile Phone" required />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <h5> Item(s) </h5>
                                            <button onClick={this.showAddProductModal} type="button" className="btn btn-secondary">Add Product</button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-borderless border">
                                                <thead className="bg-light text-dark">
                                                    <tr>
                                                        <th colSpan="2" scope="col">Item</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Qty</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                <React.Fragment>
                                                   
                                                        {
                                                            this.state.items && this.state.items.map((item, i) => (
                                                                <tr key={i} className="border-bottom">
                                                                     <th scope="row" style={{ width: 50 }}>
                                                                        <img width="50" height="50" src={item.image && item.image[0] && item.image[0]} />
                                                                    </th>
                                                                    <td>
                                                                        {item.product_name}
                                                                        {
                                                                            item.selectedSize && <p className="m-0">Size: 
                                                                                {/* <input className="form-control" value={item.size} /> */}
                                                                                <span>{item.selectedSize}</span>
                                                                            </p>
                                                                        }
                                                                        {item.selectedColour && <p className="mb-0">Colour: {item.selectedColour}</p>}

                                                                        {
                                                                            item.custom_fields && item.custom_fields.map((customField, i) => (
                                                                                <p className="mb-0" key={i}>{customField.name}: {customField.value}</p>
                                                                            ))
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        $
                                                                        {
                                                                            item.product_type === 'bundle' ? (
                                                                                (
                                                                                    item.sale_type === 'amount' ? item.online_price - item.sale
                                                                                    : item.sale_type === 'percent_off' ? (item.online_price - ((item.sale / 100) * item.online_price))
                                                                                    : item.online_price
                                                                                ) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                                                                            ) : (
                                                                                item.sale_type === 'amount' ? item.online_price - item.sale
                                                                                : item.sale_type === 'percent_off' ? (item.online_price - ((item.sale / 100) * item.online_price))
                                                                                : item.online_price
                                                                            ) + this.getCustomFieldsTotalPrice(item.custom_fields)
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        x{item.item_quantity}
                                                                    </td>
                                                                    <td>
                                                                        ${
                                                                            (
                                                                                item.product_type === 'bundle' ? (
                                                                                    (
                                                                                        item.sale_type === 'amount' ? item.online_price - item.sale
                                                                                        : item.sale_type === 'percent_off' ? (item.online_price - ((item.sale / 100) * item.online_price))
                                                                                        : item.online_price
                                                                                    ) + this.getCustomFieldsTotalPrice(item.custom_fields) + this.getBundleItemsCustomFieldsTotalPrice(item.products)
                                                                                ) : (
                                                                                    item.sale_type === 'amount' ? item.online_price - item.sale
                                                                                    : item.sale_type === 'percent_off' ? (item.online_price - ((item.sale / 100) * item.online_price))
                                                                                    : item.online_price
                                                                                ) + this.getCustomFieldsTotalPrice(item.custom_fields)
                                                                            ) * item.item_quantity
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        
                                                    
                                                </React.Fragment>
                                                </tbody>

                                            </table>
                                            {/* <table className="table table-borderless border">
                                                <thead className="bg-light text-dark">
                                                    <tr>
                                                        <th colSpan="2" scope="col"> &nbsp;</th>
                                                        <th scope="col"> &nbsp;</th>
                                                        <th scope="col"> &nbsp;</th>
                                                        <th scope="col">Total</th>
                                                        <th scope="col">GST</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="border-bottom">
                                                        <th scope="row" style={{ width: 50 }}>
                                                            <img width="50" height="50" src={StoreSvg} />
                                                        </th>
                                                        <td>
                                                            <p className="m-0">Shipping</p>
                                                            <p className="m-0">{this.state.order && this.state.order.local_pickup ? 'Local Pickup' : 'Flat Rate'}</p>
                                                            
                                                        </td>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                        <td>&nbsp;</td>
                                                        <td>$</td>
                                                        <td>$</td>
                                                    </tr>
                                                </tbody>
                                            </table> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-7 align-self-end">
                                            <div className="card">
                                <div className="card-header">
                                    <h5>Publish</h5>
                                </div>
                                <div className="card-body">
                                <div className="form-group mb-0">
                                <div className="product-buttons">
                                    <button disabled={this.state.isLoading} onClick={this.handleSave} className="btn btn-primary">
                                        {
                                            this.state.isLoading ? (
                                                <div>
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                    Loading...
                                                </div>
                                            ) : 'Save'
                                        }
                                    </button>
                                   <Link to="/orders"> <button type="button" className="btn btn-light">Cancel</button></Link>
                                </div>
                                </div>
                                </div>
                            </div>
                                            </div>
                                            <div className="col-xl-5">
                                                <ul className="list-group list-group-sm list-group-flush-y list-group-flush-x">
                                                    <li className="list-group-item d-flex">
                                                        <span>Items Subtotal</span>
                                                        <span className="ml-auto font-size-sm">${this.getItemsSubtotal()}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex"><span>Shipping</span>  <span className="ml-auto font-size-sm">${
                                                        this.state.shipping.local_pickup ? 0 : this.getStoreShippingFlatRate()
                                                    }</span>
                                                    </li>
                                                    <li className="list-group-item d-flex"><span>GST</span>  <span className="ml-auto font-size-sm">${this.getGst()}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex font-size-lg font-weight-bold"><span>Total</span>  <span className="ml-auto">${this.getTotal()}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default AddOrder;