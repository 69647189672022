import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Login from '../pages/login/login';
import ForgetPassword from '../pages/forget-password/forget_password';
import ConfirmResetPasswordForm from '../pages/forget-password/confirm-resetpassword';
import NewStore from '../pages/stores/new-store/newStore';
import EditStore from '../pages/stores/edit-store/editStore';
import Stores from '../pages/stores/stores';
import Products from '../pages/products/products';
import AddProduct from '../pages/products/add-product/addProduct';
import ProtectedRoute from '../components/protected-route/protectedRoute';
import Catalogs from '../pages/catalogs/catalogs';
import Items from '../pages/catalogs/items/items';
import AddItem from '../pages/catalogs/items/add-item/addItem';
import ItemDetails from '../pages/catalogs/items/item-details/itemDetails';
import Suppliers from '../pages/suppliers/suppliers';
import AddSupplier from '../pages/suppliers/add-supplier/addSuplier';
import SupplierDetails from '../pages/suppliers/supplier-details/supplierDetails';
import DecorationSuppliers from '../pages/decoration-suppliers/decorationSuppliers';
import AddDecorationSupplier from '../pages/decoration-suppliers/add-decoration-supplier/addDecorationSuplier';
import DecorationSupplierDetails from '../pages/decoration-suppliers/decoration-supplier-details/decorationSupplierDetails';
import Designs from '../pages/designs/designs';
import AddDesign from '../pages/designs/add-design/addDesign';
import DesignDetails from '../pages/designs/design-details/deisgnDetails';
import EditProduct from '../pages/products/edit-product/editProduct';
import StoreDetails from '../pages/stores/store-details/store-details';
import Orders from '../pages/orders/orders';
import OrderDetails from '../pages/orders/order-details/order-details';
import Dashboard from '../pages/dashboard/dashboard';
import GeneratePO from '../pages/generateP0/generateP0';
import PurchaseOrders from '../pages/purchase-orders/purchaseOrders';
import PurchaseOrderDetails from '../pages/purchase-orders/purchase-order-details/purchaseOrderDetails';
import Inventory from '../pages/inventory/inventory';
import AwaitingShipment from '../pages/shipping/awaiting-shipment/awaitingShipment';
import FulllShipments from '../pages/shipping/full-shipments/fullShipments';
import PartialShipments from '../pages/shipping/partial-shipments/partialShipments';
import InviteUser from '../pages/stores/invite-user/inviteUser';
import ShipmentDetails from '../pages/shipping/shipment-details/shipmentDetails';
import Confirm from '../pages/confirm/confirm';
import AddCatalog from '../pages/catalogs/add-catalog/addCatalog';
import EditCatalog from '../pages/catalogs/edit-catalog/editCatalog';
import Users from '../pages/users/users';
import SuperAdminProtectedRoute from '../components/protected-route/superAdminRoute';
import EditUser from '../pages/users/edit-user/editUser';
import AddOrder from '../pages/orders/add-order/add-order';

function Routes() {
        return (
            <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/forgot-password" exact component ={ForgetPassword}/>
                <Route path="/confirm-resetpassword" exact component= {ConfirmResetPasswordForm}/>
                <Route path="/confirm" exact component={Confirm} />
                <ProtectedRoute exact path="/"  component={GeneratePO} />
                <ProtectedRoute exact path="/stores"  component={Stores} />
                <ProtectedRoute path="/stores/new" exact component={NewStore} />
                <ProtectedRoute path="/stores/:storeId/detail" component={StoreDetails} />
                <ProtectedRoute path="/stores/edit/:storeId"  component={EditStore} />
                <ProtectedRoute path="/decoration-suppliers" exact component={DecorationSuppliers} />
                <ProtectedRoute path="/decoration-suppliers/:decorationSupplierId/detail" exact component={DecorationSupplierDetails} />
                <ProtectedRoute path="/decoration-suppliers/new" exact component={AddDecorationSupplier} />
                <ProtectedRoute path="/designs" exact component={Designs} />
                <ProtectedRoute path="/designs/new" exact component={AddDesign} />
                <ProtectedRoute path="/designs/:designId/detail" exact component={DesignDetails} />
                <ProtectedRoute path="/suppliers" exact component={Suppliers} />
                <ProtectedRoute path="/suppliers/:supplierId/detail" exact component={SupplierDetails} />
                <ProtectedRoute path="/suppliers/new" exact component={AddSupplier} />
                <ProtectedRoute path="/catalogs" exact component={Catalogs} />
                <ProtectedRoute path="/catalogs/new" exact component={AddCatalog} />
                <ProtectedRoute path="/catalogs/:catalogId" exact component={Items} />
                <ProtectedRoute path="/catalogs/:catalogId/details" exact component={EditCatalog} />
                <ProtectedRoute path="/catalogs/:catalogId/items/new" component={AddItem} />
                <ProtectedRoute path="/catalogs/:catalogId/items/:itemId" component={ItemDetails} />
                <ProtectedRoute exact path="/products"  component={Products} />
                <ProtectedRoute path="/products/new" exact component={AddProduct} />
                <ProtectedRoute path="/products/edit/:productId" exact component={EditProduct} />
                <ProtectedRoute exact path="/orders"  component={Orders} />
                <ProtectedRoute exact path="/orders/:orderId/detail"  component={OrderDetails} />
                <ProtectedRoute exact path="/orders/new"  component={AddOrder} />
                <ProtectedRoute exact path="/purchase-orders/new"  component={GeneratePO} />
                <ProtectedRoute exact path="/purchase-orders"  component={PurchaseOrders} />
                <ProtectedRoute exact path="/purchase-orders/:purchaseOrderId/detail" component={PurchaseOrderDetails} />
                <ProtectedRoute exact path="/inventory"  component={Inventory} />
                <ProtectedRoute exact path="/awaiting-shipments"  component={AwaitingShipment} />
                <ProtectedRoute exact path="/shipments" component={FulllShipments} />
                <ProtectedRoute exact path="/shipments/:shipmentId/detail" component={ShipmentDetails} />
                {/* <ProtectedRoute exact path="/shipments/partial" exact component={PartialShipments} /> */}
                <ProtectedRoute exact path="/user/invite" component={InviteUser} />
                <SuperAdminProtectedRoute exact path="/users" component={Users} />
                <SuperAdminProtectedRoute exact path="/users/edit/:userId" component={EditUser} />
            </Switch>
        );
}

export default Routes;