import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Loader from '../../components/loader/loader';
import Pagination from '../../components/pagination/pagination';
import { request } from '../../constants/constants';
import Swal from 'sweetalert2';
import history from '../../history';

class DecorationSuppliers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decorationSuppliers: [],
            pageSize: 10,
            currentPage: 1,
        }
    }

    handleTrProps(row) {
        return {
            onClick: (e, t) => {
                history.push(`/decoration-suppliers/${row.original.id}/detail`);
            },
        }
    }

    handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const decorationSuppliers = this.state.decorationSuppliers;
                const decorationSupplier = this.state.decorationSuppliers[index];
                decorationSupplier.isLoading = true;
                this.setState({
                    decorationSuppliers: decorationSuppliers
                });
                request('DELETE', `/hq/decoration-suppliers/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    decorationSupplier.isLoading = false;
                    this.setState({
                        decorationSuppliers: decorationSuppliers
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    decorationSupplier.isLoading = false;
                    this.setState({
                        decorationSuppliers: decorationSuppliers
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        });
    }

    getAllSuppliers() {
        return request('GET', '/hq/decoration-suppliers', {}, true);
    }
    

    init() {
        this.setState({
            isLoading: true
        })
        this.getAllSuppliers().then(res => {
            console.log(res);
            this.setState({
                decorationSuppliers: res.data.data,
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const data = this.state.decorationSuppliers;
        const columns = [
            {
                Header: 'Name',
                Cell: row => {
                    return (
                        <h5 className="highlight">{row.original.decoration_name}</h5>
                    )
                }
            },
            {
                Header: 'Code',
                accessor: 'decoration_code'
            },
            // {
            //     Header: 'Design',
            //     Cell: row => <span>{row.original.design && row.original.design.design_name}</span>,
            // },
            {
                Header: 'Action',
                Cell: row => (
                    <React.Fragment>
                    <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
                    {
                        row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
                    }
                    </button>
                    </React.Fragment>
                ),
            },
        ];
        return (
            <div className="page-body">
            <div className="container-fluid">
                <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="page-header-left">
                        <h3>Decoration Suppliers
                        {/* <small>Multikart Admin panel</small> */}
                        </h3>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <ol className="breadcrumb pull-right">
                        <li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></Link></li>
                        <li className="breadcrumb-item active">Decoration Suppliers</li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-header">
                        <h5>Manage Decoration Suppliers</h5>
                    </div>
                    <div className="card-body order-datatable">
                        <div className="btn-popup pull-right">
                          <Link to="/decoration-suppliers/new"><button type="button" className="btn btn-secondary">Add Decoration Supplier</button></Link>
                        </div>
                        <div className="table-responsive">
                           {
                               this.state.isLoading ? <Loader /> : (
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Suppliers Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    // resizable={true}
                                    getTrProps={(state, rowInfo, column, instance) => this.handleTrProps(rowInfo)}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    // getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                 />
                               )
                           }
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}

export default DecorationSuppliers;