import React, { Component } from 'react';
import { request } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Loader from '../../../../components/loader/loader';
import Pagination from '../../../../components/pagination/pagination';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';

class StoreProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            pageSize: 10,
			currentPage: 1,
        }
    }

    
    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	this.setState({
				// 		indeterminateSign: true
				// 	})
				// }
			});
		} else {
			this.setState({ pageSize: data }, () => {
				// const start = (this.state.currentPage - 1) * this.state.pageSize;
				// const end = start + this.state.pageSize;
				// let newSelected = {};
				// let newSelectPage;
				// if (this.state.selectedAll[this.state.currentPage] === true) {
				// 	newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
				// 	this.state.teams.forEach(data => {
				// 		newSelected[data.id] = false;
				// 	});
				// 	this.state.teams.slice(start, end).forEach(data => {
				// 		newSelected[data.id] = true;
				// 	});
				// 	let newObj = Object.assign(this.state.selected, newSelected);
				// 	const selectedList = Object.keys(newObj).filter(obj => {
				// 		return newObj[obj] === true;
				// 	});
				// 	// this.props.selectedList(selectedList);
				// 	this.setState({
				// 		selected: newObj,
				// 		selectedAll: newSelectPage,
				// 		indeterminateSign: false
				// 	});
				// }
			});
        }
    }

    getProducts() {
        return request('GET', `/hq/stores/${this.props.match.params.storeId}/products`, {}, true);
    }

    formatStores(data = []) {
        let stores = '';
        data.forEach( store => {
            stores += `${store.store_name},`;
        })
        return stores.substring(0, stores.length-1);
    }

    handleDelete = (id, index) => e => {
        e.stopPropagation();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able ro revert this.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it'
        }).then(res => {
            if(res.value) {
                const products = this.state.products;
                const product = products[index];
                product.isLoading = true;
                this.setState({
                    products: products
                })
                request('DELETE', `/hq/products/${id}`, {}, true).then(res => {
                    console.log(res);
                    this.init();
                    product.isLoading = false;
                    this.setState({
                        products: products
                    })
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }).catch(err => {
                    console.error(err);
                    product.isLoading = false;
                    this.setState({
                        products: products
                    })
                    Swal.fire(
                        'Error!',
                        err.response.data.message,
                        'error'
                    )
                })
            }
        })
   
    }

    init() {
        this.setState({
            isLoading: true
        })
        this.getProducts().then(res => {
            console.log(res);
            this.setState({
                products: res.data.data,
                isLoading: false
            })
        })
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const data = this.state.products;
		const columns = [
            {
                Header: 'Name',
                Cell: row => {
                    return (
                    <div className="match">
                        <h4 className="highlight">{row.original.product_name}</h4>
                        <div className="actions">
                            <ul className="list-inline">
                                <li>
                                    <Link to={`/products/edit/${row.original.id}`}><button className="btn-link btn font-primary p-0 pr-2">Edit</button></Link>
                                </li>
                                <li><span className="seprator">|</span></li>
                                <li>
                                    <Link to={`/products/edit/${row.original.id}`}><button className="btn-link btn font-primary p-0 pl-2">View</button></Link>
                                </li>
                                {/* <li><span className="seprator">|</span></li> */}
                                {/* <li>
                                    <button onClick={() => this.handleDelete(row.original.id)} className="link">Delete</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    )
                }
            },
            {
                Header: 'Image',
                Cell: row => {
                    return (
                        <img src={row.original.image[0]} />
                    )
                }
            },
            {
                Header: 'Price',
                Cell: row => (
                    <span>${row.original.online_price}</span>
                ),
            },
            {
                Header: 'SKU',
                accessor: 'SKU'
            },
            {
                Header: 'Action',
                Cell: row => (
                    <React.Fragment>
                    <button onClick={this.handleDelete(row.original.id, row.index)} className="btn btn-danger">
                    {
                        row.original.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'
                    }
                    </button>
                    </React.Fragment>
                ),
            },
        ];
        return (
            <div>
                <div className="container-fluid">
                <div className="page-header pt-0">
                    <div className="row justify-content-between">
                    <div className="col-lg-6 col-md-">
                        <div className="page-header-left">
                        <h4> Products</h4>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <Link to="/products/new"><button className="btn btn-secondary pull-right">Add Product</button></Link>
                    </div>
                    </div>
                </div>
                </div>

                <div className="table-responsive">
                           {
                               this.state.isLoading ? <Loader /> : (
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Products Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    // resizable={true}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                />
                               )
                           }
                </div>
            </div>
        );
    }
}

export default StoreProducts;